import React, { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip';
import i18n from "./../../../translations/i18n";
import translations from "./InputMonthSelector.json";
import MonthNameGiver from "../MonthNameGiver";
import "./InputMonthSelector.css";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const InputMonthSelector = ({fieldConfig, vals, callback} ) => {
    const month_numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const [values, setValues] = useState(month_numbers);

    useEffect(() => {
        if (Array.isArray(vals)) {
            if (vals.length === 12) {
                setValues(month_numbers);
            } else if (values.length === 0) {
                setValues([]);
            } else {
                setValues(vals);
            }
        }
    }, []);

    const handleCheckbox = (e) => {
        const index = parseInt(e.target.getAttribute('data-value'));
        if(values.includes(index)) {
            setValues(values.filter(value => value !== index));
        } else {
            setValues([...values, index]);
        }
    }

    useEffect(() => {
        if(fieldConfig.key) {
            callback({name: fieldConfig.key, value: values});
        }
    }, [values]);

    return (
        <div className="mb-3">
            {fieldConfig.label && (
                <label
                    className={`form-label d-block ${fieldConfig.required ? 'fw-bold' : ''}`}
                    htmlFor="month_selector">{i18n.t(fieldConfig.label)}{fieldConfig.required ?
                    <span className='fw-bold'>*</span> : ''}</label>
            )}
            {fieldConfig.tooltip && (
                <div id="month_selector">
                    <Tooltip id={`tooltip-${fieldConfig.key}`} place="right" effect="solid">
                        {i18n.t(fieldConfig.tooltip)}
                    </Tooltip>
                </div>
            )}
            <input
                type="checkbox"
                className="btn-check all-selector"
                id={`button-select-all`}
                onClick={() => values.length === 12 ? setValues([]) : setValues(month_numbers)}
                autoComplete="off"/>
            <label
                className={`badge rounded-pill all-selector-label m-1 ${values.length === 12 ? "bg-info-subtle" : "bg-success"}`}
                style={{cursor: "pointer"}}
                htmlFor={`button-select-all`}>
                {values.length === 12 ? i18n.t('deselect-all') : i18n.t('select-all')}
            </label>
            {month_numbers.map((month, index) => (
                <>
                    <input
                        type="checkbox"
                        className="btn-check month-selector"
                        data-value={month} id={`button-${month}-${index}`}
                        checked={values.includes(month)}
                        onClick={(e) => handleCheckbox(e)}
                        required={fieldConfig.required && values.length === 0}
                        autoComplete="off"
                    />
                    <label
                        className={`badge rounded-pill month-selector-label m-1 ${values.includes(month) ? "bg-success" : "bg-info-subtle"}`}
                        style={{cursor: "pointer"}}
                        htmlFor={`button-${month}-${index}`}>
                        <MonthNameGiver month={month}/>
                    </label>
                </>
            ))}
        </div>
    )
}

export default InputMonthSelector;
