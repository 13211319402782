import React, { useState, useEffect } from 'react';
import { getAllData } from "../../services/indexedDB";
import { Tooltip } from 'react-tooltip';
import EncryptionService from "../../services/encryptionService";

function InputDatabaseDropdown({ apiEndpoint, value, valueLabel, listFilter, listFilterCriteria, valueLabelAddedInfo, callback, name, label, disabled, tooltip }) {
    const [retrievedItems, setRetrievedItems] = useState([]);
    const getCategories = async () => {
        try {
            const data = await getAllData(apiEndpoint);
            var filteredList = data.values;
            if(listFilter && listFilterCriteria) {
                filteredList = filteredList.filter(item => item[listFilter] === listFilterCriteria);
            }
            setRetrievedItems(filteredList);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    if (retrievedItems.length === 0) {
        return (
            <div className="mb-3">
                <label htmlFor={`${name}Selector`} className="form-label">{label}</label>
                <input type="text" className="form-control" id={`${name}Selector`} disabled={true} value={`You need to create a ${label} before you can choose it here.`} />
            </div>
        )
    }

    return (
        <div className="mb-3">
            <label htmlFor={`${name}Selector`} className="form-label">{label}</label>
            <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${name}`}></i>
            <Tooltip id={`tooltip-${name}`} place="top" effect="solid">
                {tooltip}
            </Tooltip>
            <select onChange={(e) => callback({name: name, value: e.target.value})} id={`${name}Selector`} className="form-control"
                    value={value}
                    name={`${name}-selector`}
                    disabled={disabled}>
                <option value="">{`Choose ${label}`}</option>
                {retrievedItems.map((item, index) => (
                    <option key={index} value={item.id}>
                        {EncryptionService.decrypt(item[valueLabel], item.iv)} {item[valueLabelAddedInfo] ? `(${EncryptionService.decrypt(item[valueLabelAddedInfo], item.iv)})` : null}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default InputDatabaseDropdown;