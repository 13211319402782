import { openDB } from 'idb';

const DB_NAME = 'ecBudget';
const DB_VERSION = 6;

export const initDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db, oldVersion, newVersion, transaction) {
            if (oldVersion < 1) {
                if (!db.objectStoreNames.contains('financial-positions')) {
                    db.createObjectStore('financial-positions', {keyPath: 'id', autoIncrement: true});
                }
                if (!db.objectStoreNames.contains('accounts')) {
                    db.createObjectStore('accounts', {keyPath: 'id', autoIncrement: true});
                }
                if (!db.objectStoreNames.contains('user')) {
                    db.createObjectStore('user', {keyPath: 'id', autoIncrement: true});
                }
                if (!db.objectStoreNames.contains('transactions')) {
                    const transactionsTable = db.createObjectStore('transactions', {
                        keyPath: 'id',
                        autoIncrement: true
                    });
                    transactionsTable.createIndex('budget_check_id', 'budget_check_id', {unique: false});
                    transactionsTable.createIndex('finPosId', 'finPosId', {unique: false});
                }
                if (!db.objectStoreNames.contains('daily-transactions')) {
                    db.createObjectStore('daily-transactions', {keyPath: 'id', autoIncrement: true});
                }
                if (!db.objectStoreNames.contains('budget-checks')) {
                    db.createObjectStore('budget-checks', {keyPath: 'id', autoIncrement: true});
                }
                if (!db.objectStoreNames.contains('irregular-transactions')) {
                    db.createObjectStore('irregular-transactions', {keyPath: 'id', autoIncrement: true});
                }
            }
            if(oldVersion < 5) {
                //get table with name "transactions"
                const transactionsTable = transaction.objectStore('transactions');
                //create index for the table
                if(!transactionsTable.indexNames.contains('year')) {
                    transactionsTable.createIndex('year', 'year', {unique: false});
                }
                //if index finPosId does not exist, create it
                if(!transactionsTable.indexNames.contains('finPosId')) {
                    transactionsTable.createIndex('finPosId', 'finPosId', {unique: false});
                }
            }
            if(oldVersion < 6) {
                //get table with name "budgetChecks"
                const budgetCheckTable = transaction.objectStore('budget-checks');
                //create index for the years
                if(!budgetCheckTable.indexNames.contains('year')) {
                    budgetCheckTable.createIndex('year', 'year', {unique: false});
                }
                //create index for the months
                if(!budgetCheckTable.indexNames.contains('month')) {
                    budgetCheckTable.createIndex('month', 'month', {unique: false});
                }
            }
        },
    });
};

export const clearAllData = async () => {
    const db = await initDB();
    const tx = db.transaction(db.objectStoreNames, 'readwrite');
    try {
        for (const storeName of db.objectStoreNames) {
            const store = tx.objectStore(storeName);
            await store.clear();
        }
        await tx.done;
    } catch (error) {
        console.error('Error clearing all data:', error);
        tx.abort();
    }
};

export const addData = async ({ key, data }) => {
    const db = await initDB();
    const tx = db.transaction(key, 'readwrite');
    const store = tx.objectStore(key);
    try {
        // Clear the existing data in the object store
        await store.clear();

        // Add new data
        for (const item of data) {
            await store.add(item);
        }
        await tx.done;
    } catch (error) {
        console.error(`Error adding data to ${key}:`, error);
        tx.abort();
    }
};

export const getAllData = async (endpoint) => {
    try {
        const db = await initDB();
        const values = await db.getAll(endpoint);
        return { values };
    } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
        throw error;
    }
};

export const getDataById = async (key, id) => {
    try {
        const db = await initDB();
        return await db.get(key, id);
    } catch (error) {
        console.error(`Error fetching data by id from ${key}:`, error);
        throw error;
    }
};

export const getDataByKey = async (storeName, indexName, key) => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const index = store.index(indexName);
    const result = await index.getAll(key);
    await tx.done;
    return result;
};

export const deleteDataById = async (key, id) => {
    const db = await initDB();
    const tx = db.transaction(key, 'readwrite');
    const store = tx.objectStore(key);
    await store.delete(id);
    await tx.done;
};

export const deleteDataByKey = async (storeName, indexName, key) => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const index = store.index(indexName);
    const items = await index.getAllKeys(key);

    for (const itemKey of items) {
        await store.delete(itemKey);
    }

    await tx.done;
};

export const updateDataById = async (key, id, updatedData) => {
    const db = await initDB();
    const tx = db.transaction(key, 'readwrite');
    const store = tx.objectStore(key);
    const data = await store.get(id);
    if (data) {
        const updatedRecord = { ...data, ...updatedData };
        await store.put(updatedRecord);
    }
    await tx.done;
};

export const addDataByKey = async (key, data) => {
    const db = await initDB();
    const tx = db.transaction(key, 'readwrite');
    const store = tx.objectStore(key);
    const result = await store.add(data);
    await tx.done;
    return result;
};

export const isIndexedDBPresent = async () =>{
    if (!window.indexedDB || !indexedDB.databases) {
        throw new Error("IndexedDB is not supported in this browser.");
    }

    try {
        const user = await getDataById('user', 0);
        return !!user.username;
    } catch (error) {
        console.error("Error checking IndexedDB presence:", error);
        return false;
    }
}

export const fetchAllDataFromIndexedDB = async () => {
    const dbName = 'ecBudget'; // Replace with your database name
    const dbVersion = DB_VERSION; // Replace with your database version

    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, dbVersion);

        request.onerror = (event) => {
            console.error('Database error:', event.target.errorCode);
            reject(event.target.errorCode);
        };

        request.onsuccess = (event) => {
            const db = event.target.result;
            const exportData = {};

            const transaction = db.transaction(db.objectStoreNames, 'readonly');

            let storeCount = db.objectStoreNames.length;
            for (const storeName of db.objectStoreNames) {
                const objectStore = transaction.objectStore(storeName);
                const getAllRequest = objectStore.getAll();

                getAllRequest.onsuccess = (event) => {
                    exportData[storeName] = event.target.result;
                    storeCount--;
                    if (storeCount === 0) {
                        resolve(exportData);
                    }
                };

                getAllRequest.onerror = (event) => {
                    console.error(`Error fetching data from ${storeName}:`, event.target.errorCode);
                    reject(event.target.errorCode);
                };
            }
        };
    });
}
