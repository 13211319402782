import {addDataByKey, getAllData} from "./indexedDB";
import budgetCheck from "./../core-components/budget-checks/budgetCheckConfig.json";
import EncryptionService from "./encryptionService";

const budgetCheckFieldConfig = budgetCheck.budgetCheckDefaultValues;
const budgetCheckTransactionRecordsConfig = budgetCheck.budgetCheckTransactionRecords;

// Get the currently budgeted positions
async function fetchFinPosData() {
    const finPosData = await getAllData('financial-positions');
    return finPosData.values;
}

function categoricalPositions(finPosData, category, month) {
    switch(category) {
        case 'monthly-bills':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length === 12);
        case 'monthly-expenses':
            return finPosData.filter(item => item.monthly_spending_check === true);
        case 'provisions':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length !== 12 && item.payable.length < 12);
        case 'planned-bills':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length !== 12 && item.payable.includes(month));
        case 'savings':
            return finPosData.filter(item => item.type === 'savings' && item.payable.includes(month));
        case 'income':
            return finPosData.filter(item => item.type === "income" && item.payable.includes(month));
    }
}

function decryptDataFromOriginalValues(finPosData) {
    const decryptedData = finPosData.map(item => {
        item.title = EncryptionService.decrypt(item.title, item.iv);
        item.monthly_position_size = EncryptionService.decrypt(item.monthly_position_size, item.iv);
        item.annual_position_size = EncryptionService.decrypt(item.annual_position_size, item.iv);
        return item;
    });
    return decryptedData;
}

function buildSnapShotDataArray(finPosData, transactionClassification, bcId, month, year) {

    const snapshotData = finPosData.map(item => {
        let calculatedAmount = 0;

        //First case is planned bills, where the amount is divided by the number of months the bill is payable
        if(transactionClassification === 'planned-bills') {
            calculatedAmount = item.annual_position_size / item.payable.length
        }
        //Next is the case of a one time income, like a bonus or a tax return or a one time expense like a car repair
        else if(item.monthly_position_size === 0 && item.annual_position_size !== 0) {
            calculatedAmount = item.annual_position_size;
        }
        //For every other case (default), monthly_position_size applies
        else {
            calculatedAmount = item.monthly_position_size;
        }

        return {
            ...budgetCheckTransactionRecordsConfig,
            finPosId: item.id,
            budget_check_id: bcId,
            icon: item.icon,
            title: item.title,
            isChecked: transactionClassification === 'monthly-expenses' ? false : item.pre_logged || false,
            classification: transactionClassification,
            payable: month,
            year: year,
            type: item.type,
            amount_paid: calculatedAmount,
            amount_planned: calculatedAmount,
            iv: item.iv
        }
    });
    return snapshotData;
}

function encryptDataForStorage(transactionData) {
    if(EncryptionService.checkIfKeyExists() && transactionData.length > 0 && transactionData) {
        console.log(transactionData)
        const encryptedData = transactionData.map(item => {
            const iv = EncryptionService.generateIv();
            item.title = EncryptionService.encrypt(item.title, iv);
            item.amount_paid = EncryptionService.encrypt(item.amount_paid.toString(), iv);
            item.amount_planned = EncryptionService.encrypt(item.amount_planned.toString(), iv);
            item.iv = iv;
            return item;
        });
        return encryptedData;
    } else {
        return transactionData;
    }
}

async function createDataBaseEntries(snapshotData) {
    for(const record of snapshotData) {
        await addDataByKey('transactions', record);
    }
}

function processSnapshotPerCategory(finPosData, transactionClassification, month, bcId, year) {
    const positions = categoricalPositions(finPosData, transactionClassification, month);
    const decryptedPositions = decryptDataFromOriginalValues(positions);
    const snapshotData = buildSnapShotDataArray(decryptedPositions, transactionClassification, bcId, month, year);
    const encryptedData = encryptDataForStorage(snapshotData);
    return encryptedData;
}


async function createBudgetCheckRecords(bcId, year, month){
    const finPosData = await fetchFinPosData();

    const monthlyExpenses = processSnapshotPerCategory(finPosData, 'monthly-expenses', month, bcId, year);
    const income = processSnapshotPerCategory(finPosData, 'income', month, bcId, year);
    const monthlyBills = processSnapshotPerCategory(finPosData, 'monthly-bills', month, bcId, year);
    const provisions = processSnapshotPerCategory(finPosData, 'provisions', month, bcId, year);
    const plannedBills = processSnapshotPerCategory(finPosData, 'planned-bills', month, bcId, year);
    const savings = processSnapshotPerCategory(finPosData, 'savings', month, bcId, year);

    // Merge the regular and irregular data arrays
    const mergedRecords = [
        ...monthlyExpenses,
        ...income,
        ...monthlyBills,
        ...provisions,
        ...plannedBills,
        ...savings
    ];

    await createDataBaseEntries(mergedRecords);
}

export async function createBudgetCheckSnapshot(year, month) {
    const data = {
        ...budgetCheckFieldConfig,
        year: parseInt(year),
        month: parseInt(month),
        creation_date: new Date().toISOString(),
    }
    await addDataByKey('budget-checks', data).then(
        (response) => createBudgetCheckRecords(response, year, month)
    )
}