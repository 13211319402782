import React, { useEffect, useState } from 'react';
import { getAllData } from "../../services/indexedDB";
import { Tooltip } from "react-tooltip";
import EncryptionService from "../../services/encryptionService";
import i18n from "../../translations/i18n";

/**
 * InputDataList component renders an input field with a datalist of selectable items.
 * It fetches data from an API endpoint, decrypts the data, and populates the datalist.
 *
 * @param {Object} props - The component props.
 * @param {string} props.apiEndpoint - The API endpoint to fetch data from.
 * @param {string} props.selectableValue - The key of the value to be decrypted and displayed in the datalist.
 * @param {string} props.value - The current value of the input field.
 * @param {string} props.label - The label for the input field.
 * @param {Function} props.callback - The callback function to handle input changes.
 * @param {string} props.fieldKey - The key for the input field.
 * @param {string} props.tooltip - The tooltip text to be displayed.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} props.required - Whether the input field is required.
 * @param {boolean} props.disabled - Whether the input field is disabled.
 * @returns {JSX.Element} The rendered InputDataList component.
 */
function InputDataList({ fieldConfig, vals, callback }) {

    const [retrievedItems, setRetrievedItems] = useState([]);
    const [selectableItems, setSelectableItems] = useState([]);
    const { t } = i18n;

    /**
     * Fetches all data from the API endpoint and sets the retrieved items.
     */
    const getCategories = async () => {
        const allItems = await getAllData(fieldConfig.apiEndpoint);
        setRetrievedItems(allItems.values);
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (retrievedItems && retrievedItems.length > 0) {
            const uniqueItems = new Set(retrievedItems.map(item => EncryptionService.decrypt(item[fieldConfig.selectableValue], item.iv)));
            setSelectableItems([...uniqueItems]);
        }
    }, [retrievedItems, fieldConfig.selectableValue]);

    return (
        <div className="mb-3">
            {fieldConfig.label && (
                <label htmlFor="categorySelection" className={`form-label ${fieldConfig.required ? 'fw-bold' : ''}`}>{fieldConfig.label}{fieldConfig.required ? <span className='fw-bold'>*</span> : ''}</label>
            )}
            {fieldConfig.tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldConfig.key}`}></i>
                    <Tooltip id={`tooltip-${fieldConfig.key}`} place="rght" effect="solid">
                        {t(fieldConfig.tooltip)}
                    </Tooltip>
                </>
            )}
            <input className="form-control"
                   list="datalistOptions"
                   name={fieldConfig.key}
                   id={fieldConfig.key}
                   placeholder={t(fieldConfig.placeholder)}
                   value={t(vals)}
                   required={fieldConfig.required}
                   onChange={(e) => callback({name: fieldConfig.key, value: e.target.value})}
                   disabled={fieldConfig.disabled}
            />
            <datalist id="datalistOptions">
                {selectableItems.map((item, index) => (
                    <option key={index} value={item}/>
                ))}
            </datalist>
        </div>
    );
}

export default InputDataList;