/**
 * FormatDate component formats a given date string into a localized date string.
 *
 * @param {Object} props - The component props.
 * @param {string} props.dateString - The date string to be formatted.
 * @returns {string} The formatted date string in 'de-CH' locale.
 */
function FormatDate({dateString}) {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-CH', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
}

export default FormatDate;