import React, {useEffect, useState} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {fetchAllDataFromIndexedDB, getDataById} from "../../../services/indexedDB";
import i18n from '../../../translations/i18n';
import navConfig from './navConfig.json'
import './Navigation.css';
import EncryptionService from "../../../services/encryptionService";

/**
 * Navigation component provides the navigation bar for the application.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.change - Indicates if there are unsaved changes.
 * @param {Function} props.setChange - Function to update the change state.
 * @returns {JSX.Element} The rendered navigation component.
 */
function Navigation ({change, setChange}) {

    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname);
    const [username, setUsername] = useState(null);
    const [dateTimeString, setDateTimeString] = useState("");
    const { t } = i18n;
    const navigate = useNavigate();

    /**
     * Fetches the user data from IndexedDB and sets the username state.
     */
    const getUser = async () => {
        const user = await getDataById('user', 0);
        if(user) {
            setUsername(EncryptionService.decrypt(user.username, user.iv));
        }
    }

    /**
     * Creates a timestamp string in the format YYYY-MM-DD HH:MM and sets the dateTimeString state.
     */
    const createTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        setDateTimeString( `${year}-${month}-${day} ${hours}:${minutes}`);
    }

    useEffect(() => {
        getUser();
        createTimestamp();
    }, []);

    /**
     * Handles navigation click events and updates the active path.
     *
     * @param {string} path - The path to navigate to.
     */
    const handleNavClick = (path) => {
        if(path === '/login') {
            localStorage.removeItem('userSession');
        } else {
            setActivePath(path);
        }
    };

    /**
     * Exports data to a JSON file and triggers a download.
     *
     * @param {Object} data - The data to export.
     * @param {string} fileName - The name of the file to save.
     */
    const exportDataToJsonFile = (data, fileName) => {
        const jsonString = JSON.stringify(data, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    /**
     * Handles the export button click event, exports data if there are changes, and navigates to the appropriate path.
     */
    const handleExportClick = async () => {
        if(change) {
            try {
                const data = await fetchAllDataFromIndexedDB();
                exportDataToJsonFile(data, `${dateTimeString} - ${username}'s budget.json`);
                setChange(false);
                navigate(activePath);
            } catch (error) {
                console.error('Error exporting data:', error);
            }
        } else {
            navigate('/dashboard');
        }

    };

  return (

      <nav className="navbar sticky-top bg-dark border-bottom border-body" data-bs-theme="dark">
           <div className="container align-middle">
                  <Link
                      className="navbar-brand"
                      to={change ? location : "/dashboard"}
                  >
                      ecBudget{change ? <span className="exclamation-mark">!</span> : null}

                  </Link>

                  <button className="navbar-toggler p-0 border-0" type="button" data-toggle="collapse"
                          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                          aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav ms-1 me-auto mb-2 mb-lg-0">
                          {Object.keys(navConfig).map((key) => {
                              return (
                                  <li key={`/navigation-${key}`}
                                      className={`nav-item ${activePath === `/${key}` ? 'active' : ''}`}>
                                      <Link className="nav-link"
                                            to={`/${key}`}
                                            onClick={() => handleNavClick(`/${key}`)}
                                      >
                                          <i className={`bi ${navConfig[key].navIcon}`}/><span
                                          className="nav-label ms-2"> {t(key)}</span>
                                      </Link>
                                  </li>
                              );
                          })}
                      </ul>
                      <ul className="navbar-nav ms-lg-auto ms-1 mb-2 mb-lg-0">
                          <li className="text-white">
                              <hr/>
                          </li>
                          <li className={"nav-item"}>
                              <Link to={location} className={change ? "nav-link text-warning" : "nav-link"}
                                    onClick={() => handleExportClick()}>
                                  <i className="bi bi-cloud-download" data-tooltip-id="save_tip"></i>
                                  {change ? <span> {t('unsaved-changes')}</span> :
                                      <span className="disabled  ms-2"> {t('no-changes')}</span>}
                              </Link>
                          </li>
                          <li className={`nav-item ${activePath === '/user-settings' ? 'active' : ''}`}>
                              <Link className="nav-link" to="/user-settings"
                                    onClick={() => handleNavClick('/user-settings')}>
                                  <i className="bi bi-person"/>
                                  <span className="nav-label  ms-2">  {t('profile')}</span>
                              </Link>
                          </li>
                          <li className="text-white">
                              <hr/>
                          </li>
                          <li className={`nav-item ${activePath === '/user-settings' ? 'active' : ''}`}>
                              <Link className="nav-link" to="/login"
                                    onClick={() => handleNavClick('/login')}>
                                  <i className="bi bi-box-arrow-left"/>
                                  <span className="nav-label ms-2">  {t('logout')}</span>
                              </Link>
                          </li>
                      </ul>
                  </div>
           </div>
      </nav>
  )
}

export default Navigation