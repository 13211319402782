import EncryptionService from "./encryptionService";

function calculatePaidSum(transactions) {
    return transactions.reduce((acc, transaction) => acc + parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv)), 0);
}

function calculatePlannedSum(transactions) {
    return transactions.reduce((acc, transaction) => acc + parseFloat(EncryptionService.decrypt(transaction.amount_planned, transaction.iv)), 0);
}

function filterForClassification(transactions, classification) {
    return transactions.filter(transaction => transaction.classification === classification);
}

function getTransactionsOfClassification(transactions, classification) {
    const transactionsOfClassification = filterForClassification(transactions, classification);
    const paidSum = calculatePaidSum(transactionsOfClassification);
    const plannedSum = calculatePlannedSum(transactionsOfClassification);

    return {
        title: classification,
        key: classification,
        transactions: transactionsOfClassification,
        paidSum: paidSum,
        plannedSum: plannedSum
    }
}

function orderAndGroupData(transactionPages, section) {

    if(section === 'monthly-expenses') {
        const lookBackTransactionPages = transactionPages.filter(page => page.title === 'monthly-expenses');
        return {
            title: 'Look Back',
            pages: lookBackTransactionPages,
        }
    }

    if(section === 'regular-transactions') {
        const regularTransactions = transactionPages.filter(page => page.title === 'income' || page.title === 'monthly-bills' || page.title === 'provisions' || page.title === 'savings');
        return {
            title: 'Regular Transactions',
            pages: regularTransactions
        }
    }

    if(section === 'planned-bills') {
        const plannedBills = transactionPages.filter(page => page.title === 'planned-bills');
        return {
            title: 'Planned Bills',
            pages: plannedBills
        }
    }


    return {
    };
}

export function createBudgetCheckData(transactions) {
    const classifications = ['monthly-expenses', 'income', 'monthly-bills', 'savings', 'provisions', 'planned-bills'];
    const sections = ['monthly-expenses', 'regular-transactions', 'planned-bills'];

    const budgetCheckData = classifications.map(classification => getTransactionsOfClassification(transactions, classification));

    return sections.map(section => orderAndGroupData(budgetCheckData, section));


}