import React, { useEffect, useState } from 'react';
import TableViewDataRow from './table-row/TableViewDataRow';
import FormattedAmount from '../../../components/logical-ui-elements/FormattedAmount';
import './TableView.css';
import { useTranslation } from 'react-i18next';
import EncryptionService from "../../../services/encryptionService";

function TableView({ listViewConfig, listViewCustomOrder, listViews, contentList, setViewModeActive, viewModeActive, setFormData, location, showMonthlyAmounts, monthlyRemainingIncome, annualRemainingIncome }) {
    const { t } = useTranslation();
    const [displayedPositions, setDisplayedPositions] = useState(contentList);
    const [columnSum, setColumnSum] = useState(0);
    const [separator, setSeparator] = useState(listViews.byType || []);

    useEffect(() => {
        const orderedList = [...contentList].sort((a, b) => {
            const aIndex = listViewCustomOrder.indexOf(a.type);
            const bIndex = listViewCustomOrder.indexOf(b.type);
            if (aIndex < bIndex) {
                return -1;
            }
            if (aIndex > bIndex) {
                return 1;
            }
            return 0;
        });
        setDisplayedPositions(orderedList);
    }, [contentList]);

    const getDistinctCategories = (displayedPositions) => {
        const categories = displayedPositions.map(position => EncryptionService.decrypt(position.category, position.iv));
        return [...new Set(categories)];
    }

    useEffect(() => {
        if(listViewConfig) {
            const distinctSeparators = getDistinctCategories(displayedPositions);
            setSeparator(distinctSeparators)
        }
    }, [listViewConfig, displayedPositions]);

    const calculateSum = (field_key, category) => {
        let sum = 0;
        displayedPositions.forEach(position => {
            if (EncryptionService.decrypt(position.category, position.iv) === category) {
                sum += parseFloat(EncryptionService.decrypt(position[field_key], position.iv)) || 0;
            }
        });

        setColumnSum(prevState => ({
            ...prevState,
            [`${field_key}_${category}`]: sum
        }));
    };

    useEffect(() => {
        if (listViewConfig && displayedPositions) {
            const listViewSumByHeader = listViewConfig.filter(header => header.isSumBy);
            if (listViewSumByHeader) {
                // Reset columnSum before recalculating
                setColumnSum({});
                separator.forEach(category => {
                    listViewSumByHeader.forEach(sumColumn => {
                        calculateSum(sumColumn.key, category);
                    });
                });
            }
        }
    }, [displayedPositions]);

    return (
        <div className="TableViewComponent">
            {separator.length === 0 ? (
                <div className="section table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr key="initial_row">
                            {listViewConfig.map((header, index) => (
                                <th
                                    key={"separater_zero_table_" + index}
                                    className={`${header.listPresentationType} ${index === 0 ? 'first-column' : ''}`}
                                >
                                    <span className="text-end">{header.label}</span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="table-group-divider">
                        {displayedPositions.map((position, posIndex) => (
                            <TableViewDataRow
                                key={`separator_zero_position_${posIndex}`}
                                listViewConfig={listViewConfig}
                                position={position}
                                showMonthlyAmounts={showMonthlyAmounts}
                                setFormData={setFormData}
                                setViewModeActive={setViewModeActive}
                                viewModeActive={viewModeActive}
                                location={location}
                                monthlyRemainingIncome={monthlyRemainingIncome}
                                annualRemainingIncome={annualRemainingIncome}
                            />
                        ))}
                        </tbody>
                        <tfoot className="table-group-divider">
                        <tr key="separator_zero_table_sum">
                            {listViewConfig.map((header, colIndex) => (
                                header.isSumBy ? (
                                    <td key={colIndex}><strong><FormattedAmount
                                        amount={columnSum[`${header.key}_all`]}/></strong></td>
                                ) : colIndex === 0 ? (
                                    <td key={colIndex} colSpan={1}><strong>{t("sum")}</strong></td>
                                ) : (
                                    <td key={colIndex}></td>
                                )
                            ))}
                        </tr>
                        </tfoot>
                    </table>
                </div>
            ) : (
                separator.map((category, index) => {
                    const filteredPositions = displayedPositions.filter(position => EncryptionService.decrypt(position.category, position.iv) === category);
                    if (filteredPositions.length === 0) {
                        return null;
                    }
                    return (
                        <div key={"separator_long_" + index} className="section">
                            <h2 className="text-center mb-0 mt-5">{category}</h2>
                            <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                <tr key="separator_long_initial_row">
                                    {listViewConfig.map((header, index) => (
                                        <th
                                            key={index}
                                            className={`${header.listPresentationType} ${index === 0 ? 'first-column' : header.listPresentationType === "currency" ? 'text-end' : ''}`}
                                        >
                                            <span>{header.label}</span>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                {displayedPositions.map((position, posIndex) => (
                                    EncryptionService.decrypt(position.category, position.iv) === category && (
                                        <TableViewDataRow
                                            key={`separator_long_position_${posIndex}`}
                                            listViewConfig={listViewConfig}
                                            position={position}
                                            showMonthlyAmounts={showMonthlyAmounts}
                                            setFormData={setFormData}
                                            setViewModeActive={setViewModeActive}
                                            viewModeActive={viewModeActive}
                                            location={location}
                                            monthlyRemainingIncome={monthlyRemainingIncome}
                                            annualRemainingIncome={annualRemainingIncome}
                                        />
                                    )
                                ))}
                                </tbody>
                                <tfoot className="table-group-divider">
                                <tr key={`separator_long_sum_${index}`}>
                                    {listViewConfig.map((header, colIndex) => (
                                        header.isSumBy ? (
                                            <td key={colIndex} className="currency text-end"><strong><FormattedAmount
                                                amount={columnSum[`${header.key}_${category}`]}/></strong></td>
                                        ) : colIndex === 0 ? (
                                            <td key={colIndex} colSpan={1}><strong>{t("sum")} {t(category)}</strong></td>
                                        ) : (
                                            <td key={colIndex}></td>
                                        )
                                    ))}
                                </tr>
                                </tfoot>
                            </table>
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default TableView;