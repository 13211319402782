import { useCallback } from 'react';
import { getDataById } from './../services/indexedDB';
import EncryptionService from '../services/encryptionService';

const useEncryptFormValues = (formFields) => {
  const encryptFormValues = useCallback(async (formValues) => {
    const user = await getDataById('user', 0);
    const encryptedFormValues = {};

    if (user.encryptionMode) {
        const iv = await EncryptionService.generateIv();
        formFields.forEach(field => {
        try {
          if (!Array.isArray(formValues[field.key])) {
            if (field.isEncrypted && formValues[field.key]) {
              encryptedFormValues[field.key] = EncryptionService.encrypt(formValues[field.key].toString(), iv);
            } else {
              encryptedFormValues[field.key] = formValues[field.key];
            }
          } else {
            if (field.isEncrypted && formValues[field.key].length) {
              encryptedFormValues[field.key] = formValues[field.key].map(value => EncryptionService.encrypt(value.toString(), iv));
            } else {
              encryptedFormValues[field.key] = formValues[field.key];
            }
          }
        } catch (error) {
          console.error(`Error processing field ${field.key}:`, error);
        }
      });
      encryptedFormValues.iv = iv;
      return encryptedFormValues;
    } else {
      return formValues;
    }
  }, [formFields]);

  return { encryptFormValues };
};

export default useEncryptFormValues;