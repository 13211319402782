import {useState, useEffect} from "react";
import {getDataById} from "../../services/indexedDB";

/**
 * FormattedAmount component formats a given amount into a localized currency string.
 *
 * @param {Object} props - The component props.
 * @param {number} props.amount - The amount to be formatted.
 * @returns {JSX.Element} The formatted amount as a currency string.
 */
const FormattedAmount = ( {amount = 0} ) => {

    const [currency, setCurrency] = useState('CHF');
    const [posAmount, setPosAmount] = useState( 0);

    /**
     * Fetches the user's currency from the indexedDB and sets it to the state.
     */
    const getUserCurrency = async () => {
        const user = await getDataById("user", 0);
        if(user) {
            setCurrency(user.currency);
        }
    }

    useEffect(() => {
        if(amount < 0) {
            setPosAmount(parseFloat(amount) * -1 || 0);
        } else {
            setPosAmount(parseFloat(amount) || 0);
        }
        getUserCurrency();
    }, [amount]);


    const formatter = new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formattedAmount = formatter.format(posAmount);

    return (
        <span className="formatted-amount">{formattedAmount}</span>
    )

}

export default FormattedAmount;