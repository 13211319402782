import React from "react";
import "./Footer.css";

/**
 * Footer component displays the footer section of the application.
 *
 * @returns {JSX.Element} The rendered footer component.
 */
function Footer() {
  const currentYear = new Date().getFullYear();
  const companyName = "App by Dom";
  const appVersion = "0.7.4";

  return (
    <footer className="FooterComponent text-center mt-5">
      <p className="fst-italic">&copy; {currentYear} {companyName}. All rights reserved. Version: {appVersion}</p>
    </footer>
  );
}

export default Footer;