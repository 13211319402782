import EncryptionService from "./encryptionService";

function calculatePlannedVsRecordedExpenses(budgetSection) {

    const planned = budgetSection.pages[0].transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv)), 0);
    const recorded = budgetSection.pages[0].transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)), 0);
    const type = budgetSection.type;

    const difference = planned - recorded;

    return { type, planned, recorded, difference };
}

// The function deducts all recorded differences from the income's difference.
function calculateFinalDifference(differences) {
    if(differences.length === 0) return 0;

    return differences.reduce((acc, item) => {

        //If more income or more savings were generated than planned (difference < 0), that counts positively. On the other hand, less income or savings is counted negatively
        if (item.type === 'income' || item.type === 'savings' && item.difference < 0) {
            return acc + item.difference * -1;
        } else {
            return acc + item.difference;
        }

        //If more expenses were generated than planned (difference > 0), that counts negatively. ON the other hand, less expenses is counted positively
        if (item.type === 'expense' || item.type === 'provisions' && item.difference < 0) {
            return acc + item.difference * -1;
        } else {
            return acc + item.difference;
        }

    }, 0);
}

function calculateScore(finalDifference, totalPlanned) {
    if(finalDifference >= 0) return 5;

    const deviation = Math.abs(finalDifference) / totalPlanned * 100;
    const planningAccuracy = 100 - deviation;
    const fiveStarRating = 5 / 100 * planningAccuracy;
    return Math.round(fiveStarRating * 20) / 20;
}

export function getBudgetScore(budgetSections) {

    const differences = budgetSections.map( budgetSection => {
        return calculatePlannedVsRecordedExpenses(budgetSection);
    })

    const totalPlanned = differences.reduce((acc, item) => acc + item.planned, 0);

    const finalDifference = calculateFinalDifference(differences);
    const roundedDifference = Math.round(finalDifference * 20) / 20;

    const score = calculateScore(roundedDifference, totalPlanned);

    return {
        score: score,
        difference: roundedDifference
    };
}