// BudgetCheckList.js
import React from 'react';
import FormattedAmount from '../../components/logical-ui-elements/FormattedAmount';
import i18n from '../../translations/i18n';
import EncryptionService from "../../services/encryptionService";

const BudgetCheckList = ({listTitle, budgetCheckPages, setCurrentIndex }) => {

    return (
        <div className="list-group mb-5">
            <button onClick={() => setCurrentIndex("start")} type="button"
                className="list-group-item list-group-item-action bg-primary text-white"
                aria-current="true">
                {i18n.t(listTitle)}
            </button>
            {budgetCheckPages.map((page, index) => (
                    <button key={index} onClick={() => setCurrentIndex(page.key)} type="button"
                        className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        aria-current="true">
                        <span className={"small"}>{i18n.t(page.title)} <span
                            className={"badge rounded-pill " + (page.transactions.filter(item => !item.isChecked).length > 0 ? "bg-warning" : "bg-success")}>{page.transactions.filter(item => !item.isChecked).length}</span></span><span
                        className={"small"}>
                        <FormattedAmount amount={page.transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)), 0)} /></span>
                    </button>
                )
            )}
        </div>
    );
};

export default BudgetCheckList;