import React, { useEffect, useState } from "react";
import { getAllData, getDataById, getDataByKey, updateDataById } from "../../services/indexedDB";
import { useParams } from "react-router-dom";
import { createBudgetCheckData } from "../../services/budgetCheckProcessData";
import FinalView from "./FinalView";
import MonthNameGiver from "../../components/logical-ui-elements/MonthNameGiver";
import i18n from "../../translations/i18n";
import translations from "./BudgetCheckProcess.json";
import EncryptionService from "../../services/encryptionService";
import { useNavigate } from "react-router-dom";
import BudgetCheckList from "./BudgetCheckList";
import PositionCheckPage from "./position-check-page/PositionCheckPage";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const { t } = i18n; // Import the translation function

function BudgetCheckProcess({ setChange }) {
    const id = useParams().bcId;
    const navigate = useNavigate();

    const [transactions, setTransactions] = useState([]);
    const [budgetPositions, setBudgetPositions] = useState([]);
    const [budgetCheck, setBudgetCheck] = useState({});
    const [budgetCheckData, setBudgetCheckData] = useState([]);

    const [maxIndex, setMaxIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState("start");
    const [positionChange, setPositionChange] = useState(false);
    const [remaining, setRemaining] = useState(0);
    const [progress, setProgress] = useState(0);
    const [addUnexpectedPosition, setAddUnexpectedPosition] = useState(false);

    const [budgetCheckId, setBudgetCheckId] = useState(0);
    const [budgetCheckMonth, setBudgetCheckMonth] = useState(0);
    const [budgetCheckScore, setBudgetCheckScore] = useState(0);
    const [budgetCheckDeviation, setBudgetCheckDeviation] = useState(0);

    const fetchTransactions = async () => {
        try {
            const fetchedData = await getDataByKey('transactions', 'budget_check_id', parseInt(id));
            setTransactions(fetchedData);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchBudgetCheck = async () => {
        try {
            const fetchedData = await getDataById('budget-checks', parseInt(id));
            setBudgetCheck(fetchedData);
            setBudgetCheckMonth(fetchedData['month']);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchBudgetPositions = async () => {
        try {
            const fetchedData = await getAllData('financial-positions');
            setBudgetPositions(fetchedData.values);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            setBudgetCheckId(id);
        }
    }, [id]);

    useEffect(() => {
        fetchTransactions();
        fetchBudgetCheck();
        fetchBudgetPositions();
        setPositionChange(false);
    }, [budgetCheckId, positionChange]);

    useEffect(() => {
        const checkableItems = transactions.filter((item) => !item.isChecked).length;
        const totalItems = transactions.length;
        if (checkableItems === 0) {
            setProgress(100);
        } else {
            setProgress(100 - ((checkableItems / totalItems) * 100));
        }
    }, [transactions]);

    useEffect(() => {
        const bcData = createBudgetCheckData(transactions, budgetPositions, budgetCheckMonth);
        setBudgetCheckData(bcData);
        setRemaining(bcData.remainingEffectiveRegular || 0);
    }, [transactions, budgetPositions, budgetCheckMonth]);

    useEffect(() => {
        if (budgetCheckData) {
            setMaxIndex(budgetCheckData.length - 1);
        }
    }, [budgetCheckData]);

    useEffect(() => {
    }, [addUnexpectedPosition]);

    const closeBudgetCheck = async () => {
        var iv = "";
        var bDeviation = null;

        if (EncryptionService.checkIfKeyExists()) {
            iv = EncryptionService.generateIv();
            bDeviation = EncryptionService.encrypt(budgetCheckDeviation.toString(), iv);
        }

        const data = {
            ...budgetCheck,
            score: budgetCheckScore,
            budget_deviation: bDeviation !== null ? bDeviation : budgetCheckDeviation,
            closed: true,
            iv: iv
        };
        await updateDataById('budget-checks', parseInt(budgetCheck.id), data);
        setChange(true);
        navigate(`/budget-checks/`);
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col text-center">
                    <h3>{t('budget-check')} <MonthNameGiver month={budgetCheckMonth}/> {budgetCheck.year}</h3>
                </div>
            </div>
            {!budgetCheck.closed && (
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="progress mb-3">
                            <div
                                className={"progress-bar " + (progress === 100 ? "progress-bar-striped progress-bar-animated bg-success" : "bg-warning")}
                                role="progressbar" style={{width: progress + "%"}}
                                aria-valuenow="50">Accomplished: {progress.toFixed(2)}%
                            </div>
                        </div>
                        {progress === 100 ? (
                            <button
                                className="btn btn-success mb-3 mt-1"
                                onClick={() => setCurrentIndex("final-view")}
                            >
                                {i18n.t('jump-to-finalize')}
                            </button>
                        ) : null}
                    </div>
                </div>
            )}
            {budgetCheckData && budgetCheck && (
                currentIndex === "start" ? (
                    <div className="row">
                        <div className="col-12 text-center">
                            <hr/>
                            {budgetCheckData.map((section, index) => (
                                <BudgetCheckList
                                    key={index}
                                    listTitle={section.title}
                                    budgetCheckPages={section.pages}
                                    setCurrentIndex={setCurrentIndex}
                                />
                            ))}
                        </div>
                    </div>
                ) : currentIndex === "final-view" ? (
                    <FinalView
                        bSections={budgetCheckData}
                        setBudgetCheckDeviation={setBudgetCheckDeviation}
                        setBudgetCheckScore={setBudgetCheckScore}
                    />
                ) : (
                    <PositionCheckPage
                        transactions={transactions}
                        currentIndex={currentIndex}
                        budgetCheck={budgetCheck}
                        setPositionChange={setPositionChange}
                        setAddUnexpectedPosition={setAddUnexpectedPosition}
                        addUnexpectedPosition={addUnexpectedPosition}
                        signalChange={setPositionChange}
                    />
                )
            )}
            <div className="fixed-buttons-container">
                <button
                    className="btn btn-info"
                    value={'return'}
                    onClick={() => {
                        if(addUnexpectedPosition === true) {
                            setAddUnexpectedPosition(false);
                        } else if (currentIndex === "start") {
                            window.history.back()
                        } else {
                            setCurrentIndex("start")
                        }
                    }}
                >
                    {addUnexpectedPosition === true ? (
                        <i className="bi bi-x-lg"/>
                    ) : currentIndex === "start" ? (
                        <i className="bi bi-arrow-return-left"/>
                    ) : (
                        <i className="bi bi-arrow-left"/>
                    )}
                </button>
                {currentIndex !== "final-view" && currentIndex !== "start" && addUnexpectedPosition === false && (
                    <button
                        className="btn btn-warning"
                        value={'Close'}
                        onClick={() => {
                            setAddUnexpectedPosition(true);
                        }}
                    >
                        <i className="bi bi-plus"/>
                    </button>
                )}
                {currentIndex === "final-view" && !budgetCheck.closed && (
                    <button
                        className="btn btn-success"
                        value={'Close'}
                        onClick={() => {
                            closeBudgetCheck()
                            setChange(true);
                        }}
                    >
                        <i className="bi bi-floppy"/>
                    </button>
                )}
            </div>
        </div>
    );
}

export default BudgetCheckProcess;