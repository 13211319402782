import React, {useEffect, useState} from 'react';
import BarChart from "../charts/BarChart";
import {getDataByKey} from "../../../services/indexedDB";
import {getAbbreviationFromPlan, getPaymentPlan} from "../../../services/paymentPlanner";
import i18n from "../../../translations/i18n";
import translations from "./TotalProvisionStatus.json";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

function TotalProvisionStatus({financialPositions, setDeficit, setPlannedStartProvision}) {

    const [relevantPlannedPositions, setRelevantPlannedPositions] = useState([]);
    const [allEffectivePositions, setAllEffectivePositions] = useState([]);
    const [relevantEffectivePositions, setRelevantEffectivePositions] = useState([]);

    //  Chart data
    const [plannedChartData, setPlannedChartData] = useState([]);
    const [effectiveChartData, setEffectiveChartData] = useState([]);
    const [chartData, setChartData] = useState([]);

    const fetchTransactions = async () => {
        const response = await getDataByKey('transactions', 'year', new Date().getFullYear());
        setAllEffectivePositions(response);
    }

    useEffect(() => {
        if(financialPositions) {
            setRelevantPlannedPositions(financialPositions.filter(position => position.payable.length < 12 && position.type === 'expense'));
        }
    }, [financialPositions]);

    useEffect(() => {
        fetchTransactions();
    }, [financialPositions]);

    useEffect(() => {
        if(allEffectivePositions.length > 0) {
            const filteredEffectivePositions = allEffectivePositions.filter(position =>
                relevantPlannedPositions.some(plannedPosition => plannedPosition.id === position.finPosId)
            );
            setRelevantEffectivePositions(filteredEffectivePositions);
        }
    }, [allEffectivePositions, financialPositions]);

    useEffect(() => {
        if(relevantPlannedPositions.length > 0) {
            const paymentPlan = getPaymentPlan(relevantPlannedPositions);
            const chartData = [
                {
                    label: i18n.t('planned-account-balance-changes'),
                    type: 'line',
                    data: paymentPlan.accumulatedProvisions,
                    backgroundColor: 'rgba(93, 173, 226, 1)',
                    borderColor: '#84b0cd',
                    borderWidth: 1,
                },
                {
                    label: i18n.t('planned-bills'),
                    type: 'bar',
                    data: paymentPlan.payments,
                    backgroundColor: 'rgba(93, 173, 226, 0.4)',
                    borderColor: '#84b0cd',
                    borderWidth: 1,
                }
            ];
            setPlannedStartProvision(paymentPlan.startingProvisionAmount || 0);
            setPlannedChartData(chartData);
        } else {
            setPlannedChartData([]);
        }
    }, [relevantPlannedPositions]);

    useEffect(() => {
        if(relevantEffectivePositions.length > 0) {
            const effectivePayments = getPaymentPlan(relevantEffectivePositions);
            const chartData = [
                {
                    label: i18n.t('effective-account-balance-changes'),
                    type: 'line',
                    data: effectivePayments.accumulatedProvisions,
                    backgroundColor: 'rgba(243, 156, 18, 0.6)',
                    borderColor: '#F39C12',
                    borderWidth: 1,
                },
                {
                    label: i18n.t('effective-bills'),
                    type: 'bar',
                    data: effectivePayments.payments,
                    backgroundColor: 'rgba(243, 156, 18, 0.6)',
                    borderColor: '#F39C12',
                    borderWidth: 1,
                },
            ];
            setEffectiveChartData(chartData);
        } else {
            setEffectiveChartData([]);
        }
    }, [relevantEffectivePositions]);

    useEffect(() => {
        if(plannedChartData && effectiveChartData) {
            setChartData([...plannedChartData, ...effectiveChartData]);
            if(effectiveChartData.length > 0 && plannedChartData.length > 0) {
                setDeficit(getAbbreviationFromPlan(plannedChartData[0].data, effectiveChartData[0].data));
            }
        }
    }, [effectiveChartData, plannedChartData]);

    return (
            <div className="col-12 text-center mt-3">
                <h1 className="mb-3 mt-5">Total Provision Status</h1>
                <BarChart
                    chartData={chartData}
                />
            </div>
    );
}

export default TotalProvisionStatus;