import EncryptionService from './encryptionService';

function buildMonthlyAmountArray( positionArray, provisions ) {
    const array = [];
    for(let i = 0; i < 12; i++) {
        let monthlyAmount = 0;
        positionArray.forEach(position => {
            if(provisions) {
                if(EncryptionService.decrypt(position.monthly_position_size, position.iv) && EncryptionService.decrypt(position.monthly_position_size, position.iv) > 0) {
                    monthlyAmount += parseFloat(EncryptionService.decrypt(position.monthly_position_size, position.iv));
                } else if (position.classification === "provisions" && position.payable === i) {
                    monthlyAmount += parseFloat(EncryptionService.decrypt(position.amount_paid, position.iv));
                } else {
                    monthlyAmount += 0;
                }
            } else {
                if(position.annual_position_size && position.payable.includes(i)) {
                    monthlyAmount += parseFloat(EncryptionService.decrypt(position.annual_position_size, position.iv)) / position.payable.length;
                } else if (position.amount_paid && position.payable === i && (position.classification === 'planned-bills')) {
                    monthlyAmount += parseFloat(EncryptionService.decrypt(position.amount_paid, position.iv));
                } else {
                    monthlyAmount += 0;
                }
            }
        });
        array.push(parseFloat(monthlyAmount.toFixed(2)));
    }
    return array;
}

function buildAccumulatedArray(provisionArray, billArray) {
    const array = [];
    for(let i = 0; i < 12; i++) {
        if(i === 0) {
            array.push(provisionArray[i] - billArray[i]);
        } else {
            array.push(parseFloat((array[i - 1] + provisionArray[i] - billArray[i]).toFixed(2)));
        }
    }
    return array;
}

// Function receives the relevant positions and returns the payment plan
export function getPaymentPlan( positionArray ) {
    let startingProvisionAmount = 0;

    const provisions = buildMonthlyAmountArray( positionArray, true)
    const payments = buildMonthlyAmountArray( positionArray, false)

    const accumulatedProvisions = buildAccumulatedArray(provisions, payments)

    const lowestValue = Math.min(...accumulatedProvisions)

    if(lowestValue < 0) {
        for(let i = 0; i < 12; i++) {
            accumulatedProvisions[i] += lowestValue * -1;
            startingProvisionAmount = lowestValue * -1;
        }
    }

    return {
        provisions: provisions,
        payments: payments,
        accumulatedProvisions: accumulatedProvisions,
        startingProvisionAmount: startingProvisionAmount
    }
}

export function getAbbreviationFromPlan( accPlanArray, accEffectiveArray ) {
    const planned = Math.abs(parseFloat(accPlanArray[new Date().getMonth()]));
    const effective = Math.abs(parseFloat(accEffectiveArray[new Date().getMonth()]));

    const difference = planned - effective;

    if(effective > planned) {
        return {
            abbreviation: difference,
            abbreviationType: 'positive'
        }
    } else if(effective < planned) {
        return {
            abbreviation: difference,
            abbreviationType: 'negative'
        }
    } else {
        return {
            abbreviation: difference,
            abbreviationType: 'neutral'
        }
    }
}
