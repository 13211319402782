import React from 'react';
import {Tooltip} from "react-tooltip";
import i18n from "../../translations/i18n";

function InputSelect({field, formValues, callback}) {
    const { t } = i18n;

    return (
        <div className="mb-3">
            {field.label && (
                <label htmlFor={field.key}
                       className={`form-label ${field.required ? 'fw-bold' : ''}`}>{t(field.label)}{field.required ? <span className='fw-bold'>*</span> : ''}</label>
            )}
            {field.tooltip && (
                <>
                <i className="bi bi-question-circle ms-1" data-testid={`tooltip-icon-${field.key}`} data-tooltip-id={`tooltip-${field.key}`}></i>
                <Tooltip id={`tooltip-${field.key}`} place="right" effect="solid">
                    {t(field.tooltip)}
                </Tooltip>
                </>
            )}

            <select className="form-select"
                id="typeSelection"
                name={field.key}
                    disabled={field.disabled}
                value={formValues[field.key] || field.defaultValue}
                onChange={(e) => callback({
                    name: e.target.name,
                    value: e.target.value
                })}>
                    {field.options.map((option, index) => (
                        <option
                            key={index}
                            value={option.key}>{t(option.label)}
                        </option>
                    ))}
            </select>
        </div>
    )
}

export default InputSelect;