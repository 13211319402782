import {deleteDataById, getAllData, getDataByKey, updateDataById} from './../../services/indexedDB';
import budgetCheckConfig from './budgetCheckConfig';
import { processSnapshotPerCategory } from './../../services/budgetCheckSnapshot';

const transactionDefaults = budgetCheckConfig.budgetCheckTransactionRecords

export default async function addYearsToTransactions() {
    const allBudgetChecks = await getAllData('budget-checks');
    for (const budgetCheck of allBudgetChecks.values) {
        const transactions = await getDataByKey('transactions', 'budget_check_id', budgetCheck.id);
        transactions.forEach(transaction => {
            const updatedTransaction = {
                ...transactionDefaults,
                id: transaction.id,
                finPosId: parseInt(transaction.finPosId),
                icon: transaction.icon,
                title: transaction.title,
                payable: budgetCheck.month,
                year: budgetCheck.year,
                amount_paid: transaction.amount_paid,
                amount_planned: transaction.amount_planned,
                budget_check_id: transaction.budget_check_id,
                isChecked: transaction.isChecked,
                type: transaction.type
            }
            updateDataById('transactions', transaction.id, updatedTransaction)
        });
    }
}

function categoricalPositions(finPosData, category) {
    switch(category) {
        case 'monthly-bills':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length === 12);
        case 'monthly-expenses':
            return finPosData.filter(item => item.monthly_spending_check === true);
        case 'provisions':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length < 12);
        case 'planned-bills':
            return finPosData.filter(item => item.type === 'expense' && item.payable.length !== 12);
        case 'savings':
            return finPosData.filter(item => item.type === 'savings');
        case 'income':
            return finPosData.filter(item => item.type === "income");
    }
}

export async function updateClassificationOfTransactions() {
    // Get All budget checks and transactions
    const allBudgetChecks = await getAllData('budget-checks');
    const allTransactions = await getAllData('transactions');

    // Figure out, what are invalid bdugetChecks
    const faultyBudgetChecks = allBudgetChecks.values.filter(item => item.year === undefined || item.month === undefined);

    // Check and see if there are any transactions that are linked to faulty budget checks
    const filteredFaultTransactions = allTransactions.values.filter(transaction =>
        faultyBudgetChecks.some(faultyCheck => faultyCheck.id === transaction.budgetCheckId)
    );

    // If there are faulty transactions, remove them
    if(filteredFaultTransactions.length > 0) {
        // Remove faulty transactions
        filteredFaultTransactions.forEach(transaction => {
            deleteDataById('transactions', transaction.id);
        }, []);
    }

    if(faultyBudgetChecks.length > 0) {
        // Remove faulty budget checks
        faultyBudgetChecks.forEach(budgetCheck => {
            deleteDataById('budget-checks', budgetCheck.id);
        }, []);
    }

    // Get all financial positions and transactions
    const allFinPos = await getAllData('financial-positions');
    const remainingTransactions = await getAllData('transactions');


    // Via the budget positions, find the ones, that qualify as monthly-bills and monthly-provisions
    const provisionPositions = categoricalPositions(allFinPos.values, 'provisions');
    const regularBills = categoricalPositions(allFinPos.values, 'monthly-bills');

    const reformedTransactions = remainingTransactions.values.map(transaction => {
        if((transaction.transaction_type ||transaction.transaction_type === null) && transaction.type === "savings") {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest,
                classification: "savings",
                isUnexpected: false
            }
        }
        if(transaction.transaction_type && transaction.type === "income") {
            const { transaction_type, ...rest } = transaction;
            console.log("I run")

            return {
                ...rest,
                classification: "income",
                isUnexpected: false
            }
        }
        if(transaction.transaction_type === "irregular") {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest,
                classification: "planned-bills",
                isUnexpected: false
            }
        }

        if(transaction.transaction_type && provisionPositions.find(item => item.id === transaction.finPosId && transaction.transaction_type !== "irregular") ) {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest,
                classification: "provisions",
                isUnexpected: false
            }
        }

        if(transaction.transaction_type && regularBills.find(item => item.id === transaction.finPosId && transaction.transaction_type === "regular" || transaction.transaction_type === null)) {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest,
                classification: "monthly-bills",
                isUnexpected: false
            }
        }

        if(transaction.transaction_type === "unexpected") {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest,
                classification: "planned-bills",
                isUnexpected: true
            }
        }

        if (transaction.transaction_type === null) {
            const { transaction_type, ...rest } = transaction;
            return {
                ...rest
            };
        }
    })

    reformedTransactions.forEach(transaction => {
        try {
            updateDataById('transactions', transaction.id, transaction)
        } catch (error) {
            console.log(error)
        }
    }, []);
}
