import { Tooltip } from "react-tooltip";
import React, { useState, useEffect } from "react";
import { getDataById } from "../../services/indexedDB";
import i18n from "../../translations/i18n";
import FormattedAmount from "./FormattedAmount";

/**
 * InputCurrency component renders a currency input field with optional label, tooltip, and linked checkbox.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.fieldKey - The key for the input field.
 * @param {boolean} [props.required] - Whether the input is required.
 * @param {boolean} [props.disabled] - Whether the input is disabled.
 * @param {string} [props.tooltip] - The tooltip text to display.
 * @param {string} [props.label] - The label text to display.
 * @param {boolean} [props.isLinked] - Whether the input is linked to another value.
 * @param {Function} [props.setLinkedCheckValue] - The callback function to set the linked checkbox value.
 * @param {boolean} [props.linkedCheckValue] - The value of the linked checkbox.
 * @param {boolean} [props.showCurrency] - Whether to show the currency symbol.
 * @param {number} [props.value] - The value of the input field.
 * @param {Function} props.callback - The callback function to handle input change.
 * @param {number} [props.monthlyRemainingIncome] - The remaining monthly income to display.
 * @param {number} [props.annualRemainingIncome] - The remaining annual income to display.
 * @returns {JSX.Element} The rendered InputCurrency component.
 */
function InputCurrency({ fieldKey, required, disabled, tooltip, label, isLinked, setLinkedCheckValue, linkedCheckValue, showCurrency, value, callback, monthlyRemainingIncome, annualRemainingIncome }) {
    const { t } = i18n;

    const [user, setUser] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [currencyValue, setCurrencyValue] = useState(value || "");
    const [displayCurrency, setDisplayCurrency] = useState(true);

    const fetchUser = async () => {
        const user = await getDataById('user', 0);
        setUser(user);
    }

    useEffect(() => {
        if (showCurrency === true) {
            setDisplayCurrency(true);
        } else {
            setDisplayCurrency(false);
        }
    }, [showCurrency]);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = await fetchUser();
            if (user) {
                setCurrency(user.currency || 'CHF');
            } else {
                setCurrency('CHF');
            }
        };

        fetchUserData();
        setCurrencyValue(value);
    }, [user, isLinked, value]);

    return (
        <div className="mb-3 currency-input">
            {label && (
                <label htmlFor={fieldKey} className={`form-label ${required ? 'fw-bold' : ''}`}>{t(label)}{required ? <span className='fw-bold'>*</span> : ''}</label>
            )}
            {tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldKey}`}></i>
                    <Tooltip id={`tooltip-${fieldKey}`} place="right" effect="solid">
                        {t(tooltip)}
                    </Tooltip>
                </>
            )}
            <div className="input-group">
                {displayCurrency && (
                    <div className="input-group-text separate-line">{currency}</div>
                )}
                <input
                    type="number"
                    className="form-control separate-line"
                    id={fieldKey}
                    name={fieldKey}
                    value={currencyValue}
                    onChange={callback}
                    placeholder="0.00"
                    step="0.05"
                    required={required}
                    disabled={disabled}
                />
                {isLinked && (
                    <div className="input-group-text">
                        <input
                            className="form-check-input mt-0"
                            name={`${fieldKey}-linked`}
                            type="checkbox"
                            checked={linkedCheckValue}
                            onChange={() => setLinkedCheckValue(!linkedCheckValue)}
                            aria-label="Checkbox for following text input"
                        />
                    </div>
                )}
            </div>
            {monthlyRemainingIncome && fieldKey === "monthly_position_size" && (
                <div className="form-text small text-danger fst-italic">{t('monthly-remaining-income')}: <FormattedAmount amount={monthlyRemainingIncome} /></div>
            )}
            {annualRemainingIncome && fieldKey === "annual_position_size" && (
                <div className="form-text small text-danger fst-italic">{t('annual-remaining-income')}: <FormattedAmount amount={annualRemainingIncome} /></div>
            )}
        </div>
    );
}

export default InputCurrency;