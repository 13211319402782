import React from 'react';
import { Tooltip } from "react-tooltip";
import i18n from "../../translations/i18n";
import EncryptionService from "../../services/encryptionService";

/**
 * InputTextField component renders a text input field with optional label and tooltip.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.field - The field configuration object.
 * @param {string} props.fieldConfig.key - The key for the input fieldConfig.
 * @param {string} props.fieldConfig.label - The label text for the input fieldConfig.
 * @param {string} props.fieldConfig.tooltip - The tooltip text for the input fieldConfig.
 * @param {string} props.fieldConfig.fieldType - The type of the input fieldConfig.
 * @param {boolean} props.fieldConfig.required - Whether the input field is required.
 * @param {string} props.fieldConfig.placeholder - The placeholder text for the input fieldConfig.
 * @param {Object} props.formValues - The current form values.
 * @param {Function} props.callback - The callback function to handle input changes.
 * @returns {JSX.Element} The rendered input text field component.
 */
function InputTextField({ fieldConfig, vals, callback }) {
    const { t } = i18n;

    return (
        <div className="mb-3">
            {fieldConfig.label && (
            <label htmlFor={fieldConfig.key} className={`form-label ${fieldConfig.required ? 'fw-bold' : ''}`}>{t(fieldConfig.label)}{fieldConfig.required ? <span className='fw-bold'>*</span> : ''}</label>
            )}
            {fieldConfig.tooltip && (
                <>
                <i className="bi bi-question-circle ms-1" data-testid={`tooltip-${fieldConfig.key}`} data-tooltip-id={`tooltip-${fieldConfig.key}`}></i>
                <Tooltip id={`tooltip-${fieldConfig.key}`} place="right" effect="solid">
                    {t(fieldConfig.tooltip)}
                </Tooltip>
                </>
            )}
            <input
                type={fieldConfig.fieldType}
                className="form-control"
                id={fieldConfig.key}
                name={fieldConfig.key}
                value={vals || ''}
                onChange={(e) => callback({name: fieldConfig.key, value: e.target.value})}
                required={fieldConfig.required}
                placeholder={t(fieldConfig.placeholder)}
            />
        </div>
    );
}

export default InputTextField;