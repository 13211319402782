import React, {useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import './BarChart.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Title, Tooltip, Legend);

const BarChart = ({chartData}) => {

    const data = {
        labels: ['Jan', "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: chartData,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Budgeted vs Effective Amounts',
            }
        },
        scales: {
            y: {
                suggestedMin: 30,
                suggestedMax: 50,
            },
            x: {
                ticks: {
                    callback: function(value) {
                        return this.getLabelForValue(value);
                    },
                    minRotation: 90,
                }
            }
        }
    };

    return (
        <div className="chartBox">
            <Bar data={data} options={options}/>
        </div>
    )
};

export default BarChart;