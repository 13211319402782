import React from "react";

function WidgetContainer({ title, value, detailDescription }) {

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <div className={`card h-100 bg-opacity-25`}>
                <div className="card-body text-center mt-2 mb-2">
                    <h4 className="card-title">{title}</h4>
                    <h1 className="display-5">{value}</h1>
                    <p className="card-text fst-italic">
                        {detailDescription}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WidgetContainer;
