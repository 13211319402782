import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import viewConfiguration from './viewConfiguration.json';
import Loading from '../../components/generic-view-components/loading/Loading';
import NoData from './no-data/NoData';
import TableView from './table/TableView';
import { useTranslation } from 'react-i18next';
import { getAllData } from '../../services/indexedDB';
import './MainMenuOverviewPage.css';
import { distinctValueSummarizer } from "../../services/dataService";
import {getBudgetedSumsAndRemaining} from "../../services/remainingIncomeCalculator";
import BudgetDistribution from "../../components/generic-view-components/budget-distribution/BudgetDistribution";
import BudgetExplorer from "../../components/generic-view-components/budget-explorer/BudgetExplorer";

/**
 * MainMenuOverviewPage component displays an overview of the main menu with data fetched from IndexedDB.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setChange - Function to update the change state.
 * @returns {JSX.Element} The rendered MainMenuOverviewPage component.
 */
function MainMenuOverviewPage({ setChange }) {
    const { t } = useTranslation();
    const locationArray = useLocation();
    const [location, setLocation] = useState(locationArray.pathname.slice(1));
    const [originalPositions, setOriginalPositions] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [viewConfig, setViewConfig] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewModeActive, setViewModeActive] = useState(false);
    const [monthlyRemainingIncome, setMonthlyRemainingIncome] = useState(0);
    const [annualRemainingIncome, setAnnualRemainingIncome] = useState(0);
    const [statisticsView, setStatisticsView] = useState(false);

    /**
     * Fetches positions data from IndexedDB based on the current location.
     */
    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const data = await getAllData(location);
                setOriginalPositions(data.values);
                setFilteredList(data.values);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching positions:', error);
            }
        };
        if (location) {
            fetchPositions();
        }
    }, [location]);

    /**
     * Calculates and sets the monthly and annual remaining income based on the original positions data.
     */
    useEffect(() => {
        const result = getBudgetedSumsAndRemaining(originalPositions);
        setMonthlyRemainingIncome(result.remaining[0].remainingMonthlyIncome);
        setAnnualRemainingIncome(result.remaining[0].remainingAnnualIncome);
        }, [originalPositions]);

    /**
     * Updates the location state and sets loading state when the location changes.
     */
    useEffect(() => {
        if (locationArray.pathname.slice(1) !== location) {
            setLoading(true);
        }
        setViewModeActive(false);
        setLocation(locationArray.pathname.slice(1));
    }, [locationArray]);

    /**
     * Sets the view configuration based on the current location.
     */
    useEffect(() => {
        setViewConfig(viewConfiguration[location]);
    }, [location]);

    if (loading) {
        return <Loading />;
    }

    if (originalPositions.length === 0) {
        return (
            <div className="container">
                <div className="row">
                    <NoData pageContent={t(location)} location={location} />
                </div>
            </div>
        );
    }

    if(statisticsView) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <BudgetExplorer
                            financialPositions={originalPositions}
                        />
                    </div>
                </div>
                <div className="fixed-buttons-container">
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => setStatisticsView(!statisticsView)}
                    >
                        <i className="bi bi-arrow-return-left"/>
                    </button>
                </div>
            </div>
        );
    }

    return (

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center mt-5 mb-2">{t(location)}</h1>
                </div>
            </div>
            {originalPositions.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <BudgetDistribution
                            financialPositions={originalPositions}
                        />
                    </div>
                </div>
                )}
                <div className="row">
                    <div className="col-12">
                        {filteredList.length === 0 && (
                            <div className="text-center">
                                <h2 className="no-search-results">{t('no_results')}</h2>
                                <h4>{t('no_results_description')}</h4>
                            </div>
                        )}
                        <TableView
                            contentList={filteredList}
                            listViewConfig={viewConfig.listViewConfig}
                            listViewCustomOrder={viewConfig.listViewCustomOrder}
                            setViewModeActive={setViewModeActive}
                            viewModeActive={viewModeActive}
                            listViewTitle={location}
                            listViews={viewConfig.listViews}
                            location={location}
                            monthlyRemainingIncome={monthlyRemainingIncome}
                            annualRemainingIncome={annualRemainingIncome}
                        />
                    </div>
                </div>
                <div className="fixed-buttons-container">
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => setStatisticsView(!statisticsView)}
                    >
                        <i className="bi bi-bar-chart" />
                    </button>
                    <Link
                        to={`/${location}/add-new`}
                        className="btn btn-success"
                        state={{ monthlyRemainingIncome, annualRemainingIncome }}
                    >
                        <i className="bi bi-plus-lg" />
                    </Link>
                </div>
            </div>

    );
}

export default MainMenuOverviewPage;