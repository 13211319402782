import React, {useState, useEffect} from "react";
import i18n from "./../../../translations/i18n";
import translations from "./Loading.json";
import "./Loading.css";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

function Loading() {
    const  [cleverText, setCleverText] = useState("Loading...");

    const financialWisdom = [
        i18n.t('spend_less_than_you_earn'),
        i18n.t('start_investing_early'),
        i18n.t('save_for_emergencies'),
        i18n.t('avoid_high_interest_debt'),
        i18n.t('track_your_expenses'),
        i18n.t('live_within_your_means'),
        i18n.t('prioritize_needs_over_wants'),
        i18n.t('build_an_emergency_fund'),
        i18n.t('invest_in_yourself'),
        i18n.t('dont_rely_solely_on_credit'),
        i18n.t('understand_compound_interest'),
        i18n.t('set_financial_goals'),
        i18n.t('avoid_impulse_buys'),
        i18n.t('review_your_budget_monthly'),
        i18n.t('save_for_retirement_early'),
        i18n.t('diversify_your_investments'),
        i18n.t('money_isnt_everything'),
        i18n.t('avoid_lifestyle_inflation'),
        i18n.t('plan_for_big_purchases'),
        i18n.t('learn_from_financial_mistakes')
    ];

    useEffect(() => {
        setTimeout(() => {
            const randomIndex = Math.floor(Math.random() * financialWisdom.length);
            setCleverText(financialWisdom[randomIndex]);
        }, 7500);
    }, [cleverText]);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * financialWisdom.length);
        setCleverText(financialWisdom[randomIndex]);
    }, [] );

    return (
        <div className="container">
            <div className="row">
                <div className="col positioner text-center">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h1 className="clever-text fst-italic">{cleverText}</h1>
                </div>
            </div>
        </div>
    );
}

export default Loading;
