import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

/**
 * ViewSearch component provides a search input field to filter a list of items.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.initialValueList - The initial list of items to be filtered.
 * @param {Function} props.setNewValueList - The function to update the filtered list.
 * @returns {JSX.Element} The rendered search input field component.
 */
function ViewSearch(props) {
    const [initialValueList, setInitialValueList] = useState(props.initialValueList);

    /**
     * Handles the search input change event to filter the list of items.
     *
     * @param {Object} event - The input change event.
     */
    const searchFunction = (event) => {
        let searchValue = event.target.value.toLowerCase();
        let searchResults = initialValueList.filter(item => {
            let loopItem = Object.keys(item).map(key => {
                if (typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(searchValue);
                }
                return null;
            });
            return loopItem.includes(true);
        });
        props.setNewValueList(searchResults);
    }

    useEffect(() => {
        setInitialValueList(props.initialValueList);
    }, [props.initialValueList]);

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Search Positions..."
                           data-testid="search-input"
                           aria-label="Search Positions..." aria-describedby="basic-addon2"
                           onChange={(e) => { searchFunction(e) }} />
                    <span className="input-group-text" id="basic-addon2">
                        <FontAwesomeIcon icon={`fas fa-search`} className="search-icon fa-fw" />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ViewSearch;