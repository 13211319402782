// TransactionList.js
import React, {useState, useEffect} from 'react';
import TransactionCard from '../TransactionCard';
import config from './../../budget-checks/budgetCheckConfig.json'
import i18n from '../../../translations/i18n';
import translations from './PositionCheckPage.json';
import InputDatabaseDropdown from "../../../components/logical-ui-elements/InputDatabaseDropdown";
import {addDataByKey, getDataById} from "../../../services/indexedDB";
import InputTextField from "../../../components/logical-ui-elements/InputTextField";
import InputCurrency from "../../../components/logical-ui-elements/InputCurrency";
import EncryptionService from "../../../services/encryptionService";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const TransactionList = ({ transactions, currentIndex, budgetCheck, setPositionChange, addUnexpectedPosition = false, setAddUnexpectedPosition, signalChange}) => {
    const defaultTransactionValues = config.budgetCheckTransactionRecords
    const [unexpectedTransactionRecord, setUnexpectedTransactionRecord] = useState(defaultTransactionValues);
    const [currentBudgetCheckData, setCurrentBudgetCheckData] = useState([]);

    const [finPosId, setFinPosId] = useState(null);
    const [finPosData, setFinPosData] = useState([]);

    const fetchBudgetPositions = async () => {
        const data = await getDataById('financial-positions', parseInt(finPosId));
        setFinPosData(data);
    }

    useEffect(() => {
        setCurrentBudgetCheckData(transactions.filter(item => item.classification === currentIndex));
    }, [currentIndex, transactions]);

    useEffect(() => {
        if(finPosId) {
            const budgetPosition = fetchBudgetPositions()
            setFinPosData(budgetPosition)
        }
    }, [finPosId]);

    useEffect(() => {
        setUnexpectedTransactionRecord({
            ...unexpectedTransactionRecord,
            title: finPosData.title,
        })
    }, [finPosData]);

    useEffect(() => {
        const categoryData = {
            ...defaultTransactionValues,
            payable: parseInt(budgetCheck.month),
            budget_check_id: parseInt(budgetCheck.id),
            classification: currentIndex,
            isUnexpected: true,
            year: parseInt(budgetCheck.year),
            type: currentBudgetCheckData.type,
        }

        setUnexpectedTransactionRecord(categoryData);
    }, [currentBudgetCheckData, budgetCheck]);

    const saveUnexpectedPosition = async (e) => {
        e.preventDefault()
        const iv = EncryptionService.generateIv();
        const encryptedTitle = EncryptionService.encrypt(unexpectedTransactionRecord.title, iv);
        const encryptedAmount = EncryptionService.encrypt(unexpectedTransactionRecord.amount_paid.toString(), iv);
        setUnexpectedTransactionRecord({
            ...unexpectedTransactionRecord,
            title: encryptedTitle,
            amount_paid: encryptedAmount,
            iv: iv,
        })
        await addDataByKey('transactions', unexpectedTransactionRecord)
        setAddUnexpectedPosition(false);
        signalChange(true);
    }

    const handleChange = (e) => {
        const [name, value] = [e.name, e.value];
        const data = {
            ...unexpectedTransactionRecord,
            [name]: value
        }
        setUnexpectedTransactionRecord(data);
        if(name === 'finPosId') {
            setFinPosId(parseInt(value));
        }
    }

    const handleCurrency = (e) => {
        const value = e.target.value;
        const data = {
            ...unexpectedTransactionRecord,
            amount_paid: parseInt(value)
        }
        setUnexpectedTransactionRecord(data);
    }

    if(!addUnexpectedPosition) {
        return (
            <div className="row">
                <div className="col-12 text-center">
                    {currentBudgetCheckData.length > 0 && (
                        <h1>{i18n.t(currentBudgetCheckData[0].classification)}</h1>
                    )}
                    <hr/>
                </div>
                {currentBudgetCheckData.map((transaction, index) => (
                    <TransactionCard
                        key={transaction.id}
                        index={index}
                        budgetCheckStatus={budgetCheck.closed}
                        transaction={transaction}
                        signalChange={setPositionChange}
                    />
                ))}
            </div>
        );
    } else {
        return (
            <div className="row mt-5">
                <div className="col-12">
                    <h3>Add unexpected {currentBudgetCheckData.key}</h3>
                </div>
                <div className="col-12">
                    <form onSubmit={saveUnexpectedPosition}>
                        <InputDatabaseDropdown
                            apiEndpoint={'financial-positions'}
                            value={finPosId}
                            valueLabel={"title"}
                            listFilter={"type"}
                            listFilterCriteria={currentBudgetCheckData.type}
                            callback={(e) => {
                                handleChange(e)
                            }}
                            name={"finPosId"}
                            label={"Does this position exist in your budget?"}
                        />
                        <InputTextField
                            fieldConfig={{
                                key: 'title',
                                label: 'Description',
                                required: true,
                                placeholder: 'Enter a Description for this transaction',
                            }}
                            vals={unexpectedTransactionRecord.title}
                            callback={handleChange}
                        />
                        <InputCurrency
                            fieldKey={'amount_paid'}
                            required={true}
                            label={'Amount'}
                            showCurrency={true}
                            value={unexpectedTransactionRecord.amount_paid}
                            callback={handleCurrency}
                        />
                        <button
                            className="btn btn-primary"
                            onClick={saveUnexpectedPosition}
                        >
                            Save
                        </button>
                    </form>
                </div>
            </div>
        )
    }
};

export default TransactionList;