import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import i18n from '../../../translations/i18n';
import translations from './PieChart.json';

ChartJS.register(ArcElement, Tooltip, Legend);

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const PieChart = ({labels, chartData, onSegmentClick, backgroundColors, borderColors}) => {
    const data = {
        labels: labels,
        datasets: [
            {
                label: i18n.t('amount'),
                data: chartData,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: i18n.t('budget-explorer'),
            },
            datalabels: {
                formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2) + '%';
                    return percentage;
                },
                color: '#000',
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const index = elements[0].index;
                const label = data.labels[index];
                onSegmentClick(label);
            }
        },
    };

    return <Pie data={data} options={options} plugins={[ChartDataLabels]} />;
};

export default PieChart;