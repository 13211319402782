import React, { useEffect, useState, useRef } from 'react';
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import i18n from 'i18next';
import BudgetScore from "../../components/logical-ui-elements/BudgetScore";
import Loading from "../../components/generic-view-components/loading/Loading";
import EncryptionService from "../../services/encryptionService";
import { getBudgetScore } from "../../services/budgetCheckScoreCalculator";

function FinalView({ bSections, setBudgetCheckDeviation, setBudgetCheckScore }) {
    const t = i18n.t.bind(i18n);
    const elementsRef = useRef([]);

    const [loading, setLoading] = useState(false);
    const [budgetDeviation, setBudgetDeviation] = useState(0);
    const [budgetScore, setBudgetScore] = useState(0);
    const [budgetSections, setBudgetSections] = useState([]);

    useEffect(() => {
        setBudgetSections(bSections);
    }, [bSections]);

    useEffect(() => {
        setBudgetCheckDeviation(budgetDeviation);
        setBudgetCheckScore(budgetScore);
    }, [budgetDeviation, budgetScore, setBudgetCheckDeviation, setBudgetCheckScore]);

    useEffect(() => {
        const calculatedScore = getBudgetScore(budgetSections);
        setBudgetScore(calculatedScore.score);
        setBudgetDeviation(calculatedScore.difference);
    }, [budgetSections]);

    const determineBadgeColor = (planned, effective, type) => {
        if (planned === effective) {
            return 'bg-primary';
        }
        if (type === 'expense' || type === 'irregular' && planned > effective) {
            return 'bg-success';
        }
        if ((type === 'income' || type === 'savings') && planned > effective) {
            return 'bg-danger';
        }
        if (type === 'expense' || type === 'irregular' && planned < effective) {
            return 'bg-danger';
        }
        if ((type === 'income' || type === 'savings') && planned < effective) {
            return 'bg-success';
        }
    }

    return (
        loading ? (
            <Loading />
        ) : (
            <div className="container">
                <div className="alert alert-warning p-3 mb-5">
                    <h4>Non-functional</h4>
                    <p>This page is not yet fully functional. Calculations do not work properly.</p>
                </div>
                <div className="row text-center mt-5 mb-5">
                    <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[2] = el}>
                        <h3 className="">{t("budget-check-final-view-budget-score")}</h3>
                        <h2 className="display-1"><BudgetScore score={budgetScore} /></h2>
                    </div>
                </div>
                <div className="row text-center mt-5 mb-5">
                    <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[1] = el}>
                        <h3>{t("budget-check-final-view-abbreviation-from-budget")}</h3>
                        {budgetDeviation > 0 ? (
                            <div>
                                <h2 className="display-1 text-success"> +<FormattedAmount amount={budgetDeviation} /></h2>
                                <p className="small">Congratulations! This month you have a positive deviation from your budget. Meaning you have spent less (or earned more) than planned!</p>
                            </div>
                        ) : (
                            <div>
                                <h2 className="display-1 text-danger"> -<FormattedAmount amount={budgetDeviation} /></h2>
                                <p className="small">It seems you have spent more (or earned less) than planned during this month. Don't give up yet! Adapt your budget and keep going. It's a marathon, not a sprint.</p>
                            </div>
                        )}
                        <div className="badges mb-4">
                        </div>
                    </div>
                </div>
                {budgetSections.map((section, index) => (
                    <div key={index} className="row text-center mt-5 mb-5">
                        <div className="col-12 mt-5 mb-5 fade-in" ref={el => elementsRef.current[3] = el}>
                            <h3>{t(section.title)}</h3>
                            <h2 className="display-1">
                                <FormattedAmount amount={section.pages[0].transactions.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)), 0)} />
                            </h2>
                            <div className="badges">
                                {section.pages[0].transactions.map((position, index) => (
                                    <span key={index} className={`badge ms-1 fst-italic ${determineBadgeColor(EncryptionService.decrypt(position.amount_planned, position.iv), EncryptionService.decrypt(position.amount_paid, position.iv), position.type)}`}>{EncryptionService.decrypt(position.title, position.iv)}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}

export default FinalView;