import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import MainMenuOverviewPage from './core-components/main-menu-overview-page/MainMenuOverviewPage';
import UserSettings from "./core-components/user-settings/UserSettings";
import Dashboard from './core-components/dashboard/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './core-components-unprotected/Logout';
import Navigation from "./components/generic-view-components/navigation/Navigation";
import BudgetCheckProcess from "./core-components/budget-check-process/BudgetCheckProcess";
import DetailViewPage from "./core-components/detail-view-page/DetailViewPage";
import Login from "./core-components-unprotected/Login";
import Onboarding from "./core-components-unprotected/Onboarding";
import Footer from "./components/generic-view-components/footer/Footer";
import AddNewItem from "./core-components/main-menu-overview-page/add-new-item-form/AddNewItem";
import TemplatePositions from './components/generic-view-components/template-picker/TemplatePositions';
import userSessionManager from './services/userSessionManager';
import TemplatePicker from "./components/generic-view-components/template-picker/TemplatePicker";
import BudgetChecks from "./core-components/budget-checks/BudgetChecks";
import './App.css';
import {getAllData, updateDataById} from "./services/indexedDB";
import addYearsToTransactions, {
    updateClassificationOfTransactions
} from "./core-components/budget-checks/budgetCheckUpgradeService";


/**
 * The main App component that sets up the routing and navigation for the application.
 *
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [change, setChange] = useState(() => {
        const savedChange = localStorage.getItem('change');
        return savedChange ? JSON.parse(savedChange) : false;
    });

    const [user, setUser] = useState({});
    const [budgetVersion, setBudgetVersion] = useState(0);

    useEffect(() => {
        localStorage.setItem('change', JSON.stringify(change));
    }, [change]);

    const getUser = async () => {
        const user = await getAllData('user');
        setUser(user.values[0]);
    }

    useEffect(() => {
        const id = getUser();
    }, []);

    useEffect(() => {
        if (user.budgetCheckModuleVersion) {
            setBudgetVersion(user.budgetCheckModuleVersion);
        } else if(!user.budgetCheckModuleVersion) {
            setBudgetVersion(0);
        }
    }, [user])

    const updateUser = async (updatedUser) => {
        const feedback = await updateDataById('user', 0, updatedUser);
        console.log(feedback);
        if (feedback) {
            setUser(updatedUser);
        }
    };

    useEffect(() => {
            let updatedUser = user;
            if (budgetVersion < 0.1) {
                addYearsToTransactions();
                updatedUser = {
                    ...user,
                    budgetCheckModuleVersion: 0.1
                };
                const response = updateUser(updatedUser);
                setBudgetVersion(0.1);
            } else if (budgetVersion < 0.2) {
                addYearsToTransactions();
                updatedUser = {
                    ...user,
                    budgetCheckModuleVersion: 0.2
                };
                updateUser(updatedUser);
                setBudgetVersion(0.2);
            } else if (budgetVersion < 0.4) {
                updateClassificationOfTransactions();
                updatedUser = {
                    ...user,
                    budgetCheckModuleVersion: 0.4
                };
                updateUser(updatedUser);
                setBudgetVersion(0.4);
            }
            setUser(updatedUser);
    }, [budgetVersion]);

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            window.history.pushState(null, '', window.location.href);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const updateSessionTimestamp = () => {
            userSessionManager.updateTimestamp();
        };

        window.addEventListener('click', updateSessionTimestamp);
        window.addEventListener('keydown', updateSessionTimestamp);
        window.addEventListener('mousemove', updateSessionTimestamp);

        return () => {
            window.removeEventListener('click', updateSessionTimestamp);
            window.removeEventListener('keydown', updateSessionTimestamp);
            window.removeEventListener('mousemove', updateSessionTimestamp);
        };
    }, []);

    useEffect(() => {
        const isSessionValid = userSessionManager.isSessionValid();
        const excludedPaths = ['/', '/login', '/home', '/onboarding'];
        if (!isSessionValid && !excludedPaths.includes(location.pathname)) {
            localStorage.removeItem('userSession');
            navigate('/login', { state: { message: 'Your session has expired' } });
        }
    }, [location]);

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, [location]);

    const showNavigation = userSessionManager.isSessionValid() && !['/login', '/onboarding', '/home'].includes(location.pathname);

    return (
        <div className="App">
            {showNavigation &&
                <Navigation
                    change={change}
                    setChange={setChange}
                />
            }
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/Dashboard" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }/>
                <Route path="/financial-positions" element={
                    <ProtectedRoute>
                        <MainMenuOverviewPage
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/template" element={
                    <ProtectedRoute>
                        <TemplatePicker
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/budget-checks" element={
                    <ProtectedRoute>
                        <BudgetChecks />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/template/:templateKey" element={
                    <ProtectedRoute>
                        <TemplatePositions
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/user-settings" element={
                    <ProtectedRoute>
                        <UserSettings
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/financial-positions/details/:pos_id" element={
                    <ProtectedRoute>
                        <DetailViewPage
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/budget-checks/details/:bcId" element={
                    <ProtectedRoute>
                        <BudgetCheckProcess
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/:location/add-new" element={
                    <ProtectedRoute>
                        <AddNewItem
                            setChange={setChange}
                        />
                    </ProtectedRoute>
                }/>
                <Route path="/logout" element={
                    <ProtectedRoute>
                        <Logout />
                    </ProtectedRoute>
                }/>
            </Routes>
            <Footer/>
        </div>
    );
}

/**
 * AppWrapper component that wraps the App component with a Router.
 *
 * @returns {JSX.Element} The rendered AppWrapper component.
 */
function AppWrapper() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default AppWrapper;