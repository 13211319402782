// InputUserTags.js
import React, {useState, useEffect} from 'react';
import { Tooltip } from 'react-tooltip';
import i18n from "../../translations/i18n";

const InputUserTags = ({ fieldConfig, vals, callback}) => {
    const {t} = i18n;
    const [inputValue, setInputValue] = useState([]);

    useEffect(() => {
        if (vals) {
            setInputValue(vals.join(' ').toLowerCase());
        } else {
            setInputValue([]);
        }
    }, [vals]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        try {
            const tagsArray = value.split(' ').map(tag => tag.trim());
            setInputValue(tagsArray.join(' '));
            callback({ name: name, value: tagsArray });
        } catch (error) {
            console.error("Error parsing user tags:", error);
            callback({ name: name, value: [] });
        }
    };

    const handleRemoveTag = (index) => {
        const updatedTags = vals.filter((_, i) => i !== index);
        setInputValue(updatedTags.join(' '));
        callback({ name: fieldConfig.key, value: updatedTags });
    };

    return (
        <div className="mb-3">
            <label
                htmlFor={fieldConfig.key}
                className={`form-label ${fieldConfig.required ? 'fw-bold' : ''}`}
            >
                {t(fieldConfig.label)} <small>{t('list_separated_by_space')}</small></label>
            {fieldConfig.tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldConfig.key}`}></i>
                    <Tooltip id={`tooltip-${fieldConfig.key}`} place="right" effect="solid">
                        {t(fieldConfig.tooltip)}
                    </Tooltip>
                </>
            )}
            <input
                type="text"
                className="form-control"
                placeholder={fieldConfig.placeholder}
                id={fieldConfig.key}
                name={fieldConfig.key}
                value={inputValue}
                onChange={(e) => handleChange(e)}
            />
            <div className="form-text">
            {vals.map((tag, index) => (
                <>
                <span key={index} className="badge bg-primary rounded-pill ms-1" onClick={() => handleRemoveTag(index)}
                      style={{cursor: 'pointer'}}>
                    {tag} <span>&times;</span>
                </span>
                </>
            ))}
            </div>
        </div>
    );
};

export default InputUserTags;