import React from "react";
import { Tooltip } from "react-tooltip";
import i18n from "../../translations/i18n";

/**
 * InputSwitch component renders a switch input with optional label and tooltip.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.fieldKey - The key for the input field.
 * @param {boolean} [props.checked] - The checked state of the switch.
 * @param {string} [props.tooltip] - The tooltip text to display.
 * @param {string} [props.label] - The label text to display.
 * @param {boolean} [props.required] - Whether the input is required.
 * @param {Function} props.callback - The callback function to handle input change.
 * @returns {JSX.Element} The rendered InputSwitch component.
 */
function InputSwitch({ fieldConfig, vals, callback }) {
    const { t } = i18n;

    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch"
                   id={fieldConfig.key}
                   name={fieldConfig.key}
                   checked={vals !== undefined ? vals : false}
                   required={fieldConfig.required}
                   onChange={(e) => callback({name: fieldConfig.key, value: e.target.checked})}
            />
            {fieldConfig.label && (
                <label htmlFor={fieldConfig.key} className={`form-label ${fieldConfig.required ? 'fw-bold' : ''}`}>{t(fieldConfig.label)}</label>
            )}
            {fieldConfig.tooltip && (
                <>
                    <i data-testid={`tooltip-${fieldConfig.key}`} className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldConfig.key}`}></i>
                    <Tooltip id={`tooltip-${fieldConfig.key}`} place="right" effect="solid">
                        {t(fieldConfig.tooltip)}
                    </Tooltip>
                </>
            )}
        </div>
    );
}

export default InputSwitch;