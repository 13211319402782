import EncryptionService from "./encryptionService";

/**
 * Retrieves distinct values from the provided data array based on the given criteria key.
 * @param {Array} data - The array of data objects.
 * @param {string} criteriaKey - The key to determine distinct values.
 * @returns {Array} An array of distinct values.
 */
export function getDistinctValues(data, criteriaKey) {
    if (data.length === 0) return [];

    const distinctValues = new Set();

    data.forEach((item) => {
        const decryptedValue = EncryptionService.decrypt(item[criteriaKey], item.iv);
        distinctValues.add(decryptedValue);
    });

    return Array.from(distinctValues);
}

/**
 * Summarizes values from the provided data array based on the given criteria key and sum key.
 * @param {Array} data - The array of data objects.
 * @param {string} criteriaKey - The key to determine distinct values.
 * @param {string} sumKey - The key of the values to be summed.
 * @returns {Object} An object with distinct values as keys and their sums as values.
 */
export function distinctValueSummarizer(data, criteriaKey, sumKey) {
    if (!data || !criteriaKey || !sumKey) return {};
    if (Array.isArray(data) === false) return {};
    if (data.length === 0) return {};

    const result = {};

    data.forEach((item) => {
        let arrayKey = "";
        if(criteriaKey === 'type') {
            arrayKey = item[criteriaKey];
        } else {
            arrayKey = EncryptionService.decrypt(item[criteriaKey], item.iv);
        }
        const value = EncryptionService.decrypt(item[sumKey], item.iv);

        if (!isNaN(value)) {
            if (!result[arrayKey]) {
                result[arrayKey] = 0;
            }
            result[arrayKey] += parseFloat(value);
        }
    });

    return result;
}

/**
 * Calculates the relative portion of each sum from the provided sums object.
 * @param {Object} sums - An object with keys as distinct values and values as their sums.
 * @returns {Object} An object with keys as distinct values and values as their relative portions in percentage.
 */
export function relativePortionCalculator(sums) {
    if(!sums || typeof sums !== "object") return {};

    const total = Object.values(sums).reduce((acc, val) => acc + val, 0);
    const result = {};

    Object.keys(sums).forEach((key) => {
        result[key] = (sums[key] / total) * 100;
    });

    return result;
}

/**
 * Filters the provided data array based on the given filter key and filter criteria.
 * @param {Array} data - The array of data objects.
 * @param {string} filterKey - The key to filter the data.
 * @param {string} filterCriteria - The criteria to filter the data.
 * @returns {Array} An array of filtered data objects.
 */
export function filterData(data, filterKey, filterCriteria) {
    return data.filter((item) => EncryptionService.decrypt(item[filterKey], item.iv) === filterCriteria);
}

/**
 * Removes values from the provided data array based on the given filter key and filter criteria.
 * @param {Array} data - The array of data objects.
 * @param {string} filterKey - The key to filter the data.
 * @param {string} filterCriteria - The criteria to filter the data.
 * @returns {Array} An array of data objects with the filtered values removed.
 */
export function removeArrayValues(data, filterKey, filterCriteria) {
    return data.filter((item) => {
        const decryptedValue = EncryptionService.decrypt(item[filterKey], item.iv);
        return decryptedValue !== filterCriteria.toString();
    });
}

/**
 * Sorts the provided data array based on the given sort key and sort order.
 * @param {Array} data - The array of data objects.
 * @param {string} sortKey - The key to sort the data.
 * @param {string} sortOrder - The order to sort the data ('asc' for ascending, 'desc' for descending).
 * @returns {Array} The sorted array of data objects.
 */
export function sortData(data, sortKey, sortOrder) {
    return data.sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortKey] - b[sortKey];
        } else {
            return b[sortKey] - a[sortKey];
        }
    });
}

/**
 * Subtracts matching values from two provided arrays.
 * @param {Array} array1 - The first array of values.
 * @param {Array} array2 - The second array of values.
 * @returns {Array} An array of the results of the subtraction.
 */
export function subtractMatchingValues(array1 = [], array2 = []) {
    const result = {};

    if (Object.keys(array1).length === 0 || Object.keys(array2).length === 0) {
        return {};
    }

    Object.keys(array1).forEach(key1 => {
        Object.keys(array2).forEach(key2 => {
            if(key1 === key2) {
                result[key1] = array1[key1] - array2[key2];
            }
        });
    });

    return result;
}

export function calculateCategorySum(data, field, filterField, filterValue) {
    return data
        .filter((item) => {
            if(filterField === 'type') {
                return item[filterField] === filterValue
            } else {
                return EncryptionService.decrypt(item[filterField], item.iv) === filterValue
            }
        })
        .reduce((sum, item) => sum + parseFloat(EncryptionService.decrypt(item[field], item.iv)), 0);
};