import Loading from "../../components/generic-view-components/loading/Loading";
import React, { useEffect, useState } from "react";
import { getAllData } from "../../services/indexedDB";
import EncryptionService from "../../services/encryptionService";
import BudgetExplorer from "../../components/generic-view-components/budget-explorer/BudgetExplorer";
import WidgetContainer from "../../components/generic-view-components/widget-container/WidgetContainer";
import i18n from "../../translations/i18n";
import translations from "./Dashboard.json";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import TotalProvisionStatus from "../../components/generic-view-components/total-provision-status/TotalProvisionStatus";
import AddButtonRegular from "../../components/logical-ui-elements/AddButtonRegular";
import {getBudgetedSumsAndRemaining} from "../../services/remainingIncomeCalculator";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [financialPositions, setFinancialPositions] = useState(null);
    const [budgetChecks, setBudgetChecks] = useState(null);
    const [openBudgetChecks, setOpenBudgetChecks] = useState(null);
    const [remaining, setRemaining] = useState(0);
    const [largestPosition, setLargestPosition] = useState(null);
    const [plannedMonthlyProvisions, setPlannedMonthlyProvisions] = useState(0);
    const [currentDeficit, setCurrentDeficit] = useState(0);
    const [plannedStartProvision, setPlannedStartProvision] = useState(0);


    const getUser = async () => {
        const data = await getAllData('user');
        setTimeout(() => {
            if(data.values) {
                setUser(data.values[0]);
                setLoading(false);
            }
        }, 1000);
    };

    const getFinancialPositions = async () => {
        const data = await getAllData('financial-positions');
        setTimeout(() => {
            if(data.values) {
                setFinancialPositions(data.values);
                setLoading(false);
            }
        }, 1000);
    }

    const getBudgetChecks = async () => {
        const data = await getAllData('budget-checks');
        setTimeout(() => {
            if(data.values) {
                setBudgetChecks(data.values);
                setLoading(false);
            }
        }, 1000);
    }

    useEffect(() => {
        getUser()
        getFinancialPositions()
        getBudgetChecks()
    }, []);

    useEffect(() => {
        if(financialPositions) {
            const remaining = getBudgetedSumsAndRemaining(financialPositions)
            if(remaining.remaining[0].annualIsDeficit) {
                setRemaining(<span className="text-danger">- <FormattedAmount amount={parseFloat(remaining.remaining[0].remainingAnnualIncome.toFixed(2))} /></span>);
            } else {
                setRemaining(<span className="text-success"><FormattedAmount
                    amount={parseFloat(remaining.remaining[0].remainingAnnualIncome.toFixed(2))}/></span>);
            }
        }
    }, [financialPositions, loading]);

    useEffect(() => {
        if (financialPositions) {
            const allExpenses = financialPositions.filter(item => item.type === 'expense');
            const orderedList = allExpenses.sort((a, b) => parseFloat(EncryptionService.decrypt(b.annual_position_size, b.iv)) - parseFloat(EncryptionService.decrypt(a.annual_position_size, a.iv)));
            setLargestPosition(orderedList[0]);
        }
    }, [financialPositions, loading]);

    useEffect(() => {
        if(budgetChecks) {
            const openChecks = budgetChecks.filter(item => item.closed === false);
            if(openChecks.length === 0) {
                setOpenBudgetChecks(0);
            } else {
                setOpenBudgetChecks(openChecks.length);
            }
        }
    }, [budgetChecks, loading]);

    useEffect(() => {
        if(financialPositions) {
            const monthlyProvisions = financialPositions.filter(item => item.type === 'expense' && item.payable.length < 12).reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.monthly_position_size, item.iv)), 0);
            setPlannedMonthlyProvisions(monthlyProvisions);
        }
    }, [financialPositions, loading]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="container">
            <div className="row pt-5">
                {user && (
                    <WidgetContainer
                        title={i18n.t('welcome')}
                        value={EncryptionService.decrypt(user.username, user.iv)}
                        detailDescription={i18n.t('last-save') + ": " + new Date(user.last_save).toLocaleString()}
                    />
                )}
                {budgetChecks && (
                    <WidgetContainer
                        title={i18n.t('open-budget-checks')}
                        value={openBudgetChecks}
                        detailDescription={i18n.t('open-budget-checks-description')}
                    />
                )}
                <hr className="mt-5 mb-5"/>
                {financialPositions && financialPositions.length ? (
                    <div className="row m-0 p-0 mb-5">
                        <BudgetExplorer financialPositions={financialPositions}/>
                    </div>
                ) : (
                    <WidgetContainer
                        title={i18n.t('create-budget-positions')}
                        value={
                        <AddButtonRegular
                            location={"financial-positions"}
                            pageTitle={i18n.t("add-financial-positions")}
                        />
                        }
                        detailDescription={i18n.t('create-budget-positions-description')}
                    />
                )}
                {financialPositions && (
                    <WidgetContainer
                        title={i18n.t('remaining-budget')}
                        value={remaining}
                        detailDescription={i18n.t('remaining-budget-description')}
                    />
                )}
                {largestPosition && (
                    <WidgetContainer
                        title={<span>{i18n.t('largest-budgeted-expense')} <br/> ({i18n.t('annually')})</span>}
                        value={<span><FormattedAmount
                            amount={EncryptionService.decrypt(largestPosition.annual_position_size, largestPosition.iv)}/></span>}
                        detailDescription={i18n.t('largest-budgeted-expense-description', {position: largestPosition.title})}
                    />
                )}
                <hr className="mt-5 mb-5"/>
                {financialPositions && financialPositions.length ? (
                    <div className="row m-0 p-0 mb-5">
                        <TotalProvisionStatus
                            financialPositions={financialPositions}
                            setDeficit={setCurrentDeficit}
                            setPlannedStartProvision={setPlannedStartProvision}
                        />
                    </div>
                ) : (
                    <WidgetContainer
                        title={i18n.t('create-budget-positions')}
                        value={
                            <AddButtonRegular
                                location={"add-financial-positions"}
                                pageTitle={i18n.t("financial-positions")}
                            />
                        }
                        detailDescription={i18n.t('create-budget-positions-description')}
                    />
                )}
                <WidgetContainer
                    title={i18n.t('planned-monthly-provisions')}
                    value={<FormattedAmount amount={plannedMonthlyProvisions}/>}
                    detailDescription={i18n.t('planned-monthly-provisions-description')}
                />
                <WidgetContainer
                    title={i18n.t('current-plan-accuracy')}
                    value={<span
                        className={currentDeficit.abbreviationType === "positive" ? 'text-success' : currentDeficit.abbreviationType === 'negative' ? 'text-danger' : null}> <FormattedAmount
                        amount={currentDeficit.abbreviation}/> </span>}
                    detailDescription={currentDeficit.abbreviationType === "positive" ? i18n.t('current-plan-accuracy-positive') : currentDeficit.abbreviationType === 'negative' ? i18n.t('current-plan-accuracy-negative') : i18n.t('current-plan-accuracy-neutral')}
                />
                <WidgetContainer
                    title={i18n.t('start-of-the-year-provision')}
                    value={<FormattedAmount amount={plannedStartProvision}/>}
                    detailDescription={i18n.t('start-of-the-year-provision-description')}
                />
            </div>
        </div>
    );
}

export default Dashboard;