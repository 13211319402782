/**
 * User session management utility.
 * Provides methods to manage user sessions using localStorage.
 */
const userSessionManager = {
    /**
     * Opens a new user session and stores the session token and timestamp in localStorage.
     * @param {string} key - The session token.
     */
    openSession: async (key) => {
        const timestamp = new Date().toISOString();
        localStorage.setItem('userSession', JSON.stringify({
            token: key,
            timestamp: timestamp
        }));
    },

    /**
     * Updates the timestamp of the current session if the session is valid.
     * @throws {Error} If no session is found.
     */
    updateTimestamp: () => {
        if(userSessionManager.isSessionValid()) {
            const session = JSON.parse(localStorage.getItem('userSession'));
            if (!session) {
                throw new Error('No session found');
            }

            // Update the timestamp
            session.timestamp = new Date().toISOString();
            localStorage.setItem('userSession', JSON.stringify(session));
        }
    },

    /**
     * Checks if the current session is valid based on the timestamp.
     * @returns {boolean} True if the session is valid, false otherwise.
     */
    isSessionValid: () => {
        try {
            const session = JSON.parse(localStorage.getItem('userSession'));
            if (!session) {
                return false;
            }

            const sessionTimestamp = new Date(session.timestamp);
            const currentTime = new Date();
            const timeDifference = (currentTime - sessionTimestamp) / (1000 * 60); // time difference in minutes

            return timeDifference <= 30;
        } catch (error) {
            return false;
        }
    }
};

export default userSessionManager;