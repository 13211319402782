import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {deleteDataById, updateDataById} from "../../../services/indexedDB";
import { useTranslation } from "react-i18next";
import EncryptionService from "../../../services/encryptionService";

/**
 * DeleteForm component allows users to delete a financial position.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.values - The values object containing the id and title of the financial position.
 * @param {Function} props.onFormSubmit - The callback function to handle form submission.
 * @param {Function} props.onCancel - The callback function to handle form cancellation.
 * @param {string} props.location_id - The location id where the financial position is stored.
 * @param {Function} props.setChange - The function to set the change state.
 * @returns {JSX.Element} The rendered DeleteForm component.
 */
function DeleteForm( { values, onFormSubmit, onCancel, location_id, setChange } ) {

    const [id, setDeleteId] = React.useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [deleteTitle, setDeleteTitle] = useState();

    useEffect (() => {
        setDeleteId(values.id);
        setDeleteTitle(EncryptionService.decrypt(values.title, values.iv));
    }, [values.id, values.title]);

    /**
     * Deletes the financial position by id and updates the user data.
     */
    const deleteFinancialPosition = async () => {
        try {
            await deleteDataById(location_id, id);
            await updateDataById('user', 0, {last_save: new Date().toISOString()});
            onFormSubmit();
            setChange(true);
            navigate(`/${location_id}`);
        } catch (error) {
            console.error('Error deleting financial position:', error);
            return { error: 'Error deleting financial position' };
        }
    };

    /**
     * Cancels the delete action and calls the onCancel callback.
     */
    const cancelAction = () => {
        onCancel();
    }

    return (
        <div className={`container ${location_id}-form mt-4 mb-5`}>
            <form>
                <p>Are you sure you want to delete the "{deleteTitle}" entry?</p>

                <div className="fixed-buttons-container">
                    <button type="button" data-testid="delete-button" className="btn btn-secondary" onClick={cancelAction}>
                        <i className="bi bi-x-lg"></i>
                    </button>
                    <button type="button" data-testid="cancel-button" className="btn btn-danger" onClick={deleteFinancialPosition}>
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default DeleteForm;