import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import i18n from "../../translations/i18n";

function AddButton( {pageTitle, location} ) {

    const [title, setTitle] = useState(pageTitle);

    const navigate = useNavigate();
    const t = i18n.t.bind(i18n);

    const handleAddClick = () => {
        navigate(`/${location}/add-new`);
    };

    useEffect(() => {
        setTitle(pageTitle);
    }, [pageTitle]);

    return (
        <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddClick}>
        <FontAwesomeIcon icon="fa fa-plus"/> {t('add-button-add', {title})}
        </button>
    )
}

export default AddButton;
