import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormattedAmount from "../../../../components/logical-ui-elements/FormattedAmount";
import MonthNameGiver from "../../../../components/logical-ui-elements/MonthNameGiver";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import "./TableViewDataRow.css";
import EncryptionService from "../../../../services/encryptionService";

function TableViewDataRow({ position, listViewConfig, location, annualRemainingIncome, monthlyRemainingIncome }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [displayedValues, setDisplayedValues] = useState({});

    useEffect(() => {
        const initialValues = {};
        listViewConfig.forEach((header) => {
                if (header.meta) {
                    try {
                        initialValues[header.key] = EncryptionService.decrypt(position[header.key], position.iv);
                    } catch (error) {
                        console.log("Error encrypting title: " + error);
                    }
                    if (header.meta.icon) {
                        initialValues[header.meta.icon] = position[header.meta.icon];
                    }
                    if (header.meta.detail_info) {
                        try {
                            initialValues[header.meta.detail_info] = EncryptionService.decrypt(position[header.meta.detail_info], position.iv);
                        } catch (error) {
                            console.log("Error decrypting detail info: " + error);
                        }
                    }
                    if (header.meta.second_line && Array.isArray(position[header.meta.second_line])) {
                        const decryptedArray = [];
                        for (let i = 0; i < position[header.meta.second_line].length; i++) {
                            try {
                                decryptedArray[i] = EncryptionService.decrypt(position[header.meta.second_line][i], position.iv);
                            } catch (error) {
                                console.log("Error encrypting second line: " + error);
                            }
                        }
                        initialValues[header.meta.second_line] = decryptedArray;
                    }
                } else {
                    try {
                        initialValues[header.key] = EncryptionService.decrypt(position[header.key], position.iv);
                    } catch (error) {
                        console.log("Error decrypting non-meta value: " + error);
                    }
                }
        });
        setDisplayedValues(initialValues);
    }, [position, listViewConfig]);

    const handleTrClick = () => {
        navigate(`/${location}/details/${position.id}`, { state: { monthlyRemainingIncome, annualRemainingIncome } });
    }

    return (
        <tr className="PositionComponent align-middle" onClick={() => handleTrClick()}>
            {listViewConfig.map((header, index) => (
                index === 0 ? (
                    <td key={"table_data_row_" + index} className="primary-column">
                        {header.meta && header.meta.icon && displayedValues[header.meta.icon] && (
                            <span className="me-2" style={{ width: '20px', display: 'inline-block' }}>
                                <FontAwesomeIcon icon={displayedValues[header.meta.icon]} />
                            </span>
                        )}
                        <span className="position-title me-2">
                            {header.listPresentationType === "date" && displayedValues[header.key]?
                                format(new Date(displayedValues[header.key]), 'dd. MMMM yyyy')
                                : displayedValues[header.key]
                            }
                        </span>
                        {header.meta && header.meta.detail_info && Array.isArray(displayedValues[header.meta.detail_info]) ?
                            (displayedValues[header.meta.detail_info].length === 12 ?
                                    <small className="position-date me-1">({t('monthly')})</small> :
                                    displayedValues[header.meta.detail_info].map((month, index) => (
                                        <small className="position-date me-1" key={index}>({<MonthNameGiver month={month} />})</small>
                                    ))
                            ) : (
                                displayedValues[header.meta.detail_info] && (
                                    <small className="position-date me-1">({displayedValues[header.meta.detail_info]})</small>
                                )
                            )
                        }
                        <br />
                        {header.meta && header.meta.second_line && Array.isArray(displayedValues[header.meta.second_line]) ?
                            displayedValues[header.meta.second_line].map((tag, index) => (
                                <span key={"tag_" + tag + "_" + index} className="user-tag badge rounded-pill text-bg-primary">{tag}</span>
                            )) : null
                        }
                    </td>
                ) : header.listPresentationType === "currency" ? (
                    <td key={index} className={`column-width currency text-end`}>
                        <span className={`d-inline ${position.type === "income" ? "plus" : "minus"}`}><FormattedAmount amount={displayedValues[header.key]}/></span>
                    </td>
                ) : header.listPresentationType === "badge" ? (
                    <td key={index} className="column-width">
                        <span className="badge rounded-pill text-bg-primary">{ displayedValues[header.key]}</span>
                    </td>
                ) : header.listPresentationType === "date" ? (
                    <td key={index} className="column-width">
                        <span className="badge rounded-pill text-bg-primary">
                            {format(new Date(displayedValues[header.key]), 'dd.M.yyyy')}
                        </span>
                    </td>
                ) : header.listPresentationType === "status" ? (
                    <td key={index} className="column-width">
                        {position[header.key] === false ? (
                            <span><FontAwesomeIcon icon="fa-solid fa-lock" /> {header.closedStatusText}</span>
                        ) : (
                            <span><FontAwesomeIcon icon="fa-solid fa-lock-open" /> {header.openStatusText}</span>
                        )}
                    </td>
                ) : null
            ))}
        </tr>
    );
}

export default TableViewDataRow;