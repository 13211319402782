// frontend/src/services/JsonHandlingService.js
import { fetchAllDataFromIndexedDB, addDataByKey, initDB } from './indexedDB';

const JsonHandlingService = {
    /**
     * Exports all data from IndexedDB to a JSON file and triggers a download.
     * @param {string} username - The username to include in the filename.
     * @param {string} dateString - The date string to include in the filename.
     */
    exportDataToJsonFile: async (username, dateString) => {
        try {
            const data = await fetchAllDataFromIndexedDB();
            const jsonString = JSON.stringify(data, null, 2);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${dateString} - ${username}'s budget.json`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    },

    /**
     * Imports data from a JSON file into IndexedDB.
     * Clears existing data in all object stores before importing.
     * @param {File} file - The JSON file to import.
     */
    importDataFromJsonFile: async (file) => {
        if (file && file.type === "application/json") {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    const db = await initDB();
                    const transaction = db.transaction(db.objectStoreNames, 'readwrite');

                    // Clear existing data in all object stores
                    for (const storeName of db.objectStoreNames) {
                        const objectStore = transaction.objectStore(storeName);
                        await objectStore.clear();
                    }

                    // Add new data
                    for (const key in json) {
                        if (json.hasOwnProperty(key)) {
                            const tableRows = json[key];
                            for (const row of tableRows) {
                                await addDataByKey(key, row);
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            };
            reader.readAsText(file);
        } else {
            console.error("Please upload a valid .json file.");
        }
    }
};

export default JsonHandlingService;