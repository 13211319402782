import EncryptionService from "../../services/encryptionService";

export function summarizeMonthlyData(array1, array2) {
    const dataArray = [];
    for(let i = 0; i < 12; i++) {
        dataArray.push(array1[i] + array2[i]);
    }
    return dataArray;
}

export function combineDataArrays(provisionArray, billArray, initialValue) {
    const combinedArray = [];
    for(let i = 0; i < 12; i++) {
        if(i === 0 && i === 0) {
            combinedArray.push(initialValue + provisionArray[i] - billArray[i]);
        } else {
            combinedArray.push(combinedArray[i-1] + provisionArray[i] - billArray[i]);
        }
    }
    return combinedArray
}

export function buildTransactionDataArray(contextArray, type, sumKey) {
    const dataArray = [];
    for(let i = 0; i < 12; i++) {
        const item = contextArray.find(item => item.payable === i && item.transaction_type === type);
        if(item) {
            dataArray.push(parseFloat(EncryptionService.decrypt(item[sumKey], item.iv)) || 0);
        } else {
            dataArray.push(0);
        }
    }
    return dataArray;
}

export function buildPlannedDataArray(finPos, provisions) {
    const dataArray = [];
    for(let i = 0; i < 12; i++) {
        if(provisions === true) {
            dataArray.push(parseFloat(EncryptionService.decrypt(finPos.monthly_position_size, finPos.iv)) || 0);
        } else {
            if(finPos.payable.includes(i)) {
                dataArray.push(parseFloat(EncryptionService.decrypt(finPos.annual_position_size, finPos.iv) / finPos.payable.length) || 0);
            } else {
                dataArray.push(0);
            }
        }
    }
    return dataArray;
}

export function calculatePlannedProvisionsFromPreviousYear(financialPosition) {
    let prevPlannedYearProvisions = 0;
    const firstPaymentDue = financialPosition.payable[0];
    const firstPaymentSize = parseFloat(EncryptionService.decrypt(financialPosition.annual_position_size, financialPosition.iv)) / financialPosition.payable.length;
    if(firstPaymentDue === 0) {
        prevPlannedYearProvisions = firstPaymentSize;
    } else {
        const provisionsInNewYear = (firstPaymentDue -1) * parseFloat(EncryptionService.decrypt(financialPosition.monthly_position_size, financialPosition.iv));
        prevPlannedYearProvisions = firstPaymentSize - provisionsInNewYear;
    }
    return prevPlannedYearProvisions;
}

export function calculateProvisionsFromPreviousYear(transactionData, selectedYear) {
    const previousYear = parseInt(selectedYear) - 1;
    const contextArray = transactionData.filter(item => item.year === previousYear);
    let prevYearProvisions = 0;
    for (let i = 0; i < 12; i++) {
        const regularType = contextArray.find(item => item.payable === i && item.transaction_type === 'regular');
        const billType = contextArray.find(item => item.payable === i && item.transaction_type === 'irregular');
        if(regularType) {
            prevYearProvisions += parseFloat(EncryptionService.decrypt(regularType.amount_paid, regularType.iv)) || 0;
        }
        if(billType) {
            prevYearProvisions -= parseFloat(EncryptionService.decrypt(billType.amount_paid, billType.iv)) || 0;
        }
    }
    return prevYearProvisions;
}