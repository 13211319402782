// frontend/src/services/encryptionService.js
import CryptoJS from 'crypto-js';

const EncryptionService = {
    /**
     * Generates an encryption key using PBKDF2.
     * @param {string} password - The password to derive the key from.
     * @param {string} salt - The salt to use in the key derivation.
     * @returns {string} The derived key as a hexadecimal string.
     */
    generateKey: (password, salt) => {
        const key = CryptoJS.PBKDF2(password, CryptoJS.enc.Hex.parse(salt), {
            keySize: 256 / 32, // 256-bit key
            iterations: 1000 // Number of iterations
        });
        return key.toString();
    },

    /**
     * Generates a random initialization vector (IV).
     * @returns {string} The generated IV as a hexadecimal string.
     */
    generateIv: () => {
        return CryptoJS.lib.WordArray.random(128 / 8).toString();
    },

    /**
     * Generates a random salt.
     * @returns {string} The generated salt as a hexadecimal string.
     */
    generateSalt: () => {
        return CryptoJS.lib.WordArray.random(128 / 8).toString();
    },

    isHexadecimal(token) {
        return /^[0-9A-Fa-f]{6}$/.test(token);
    },

    /**
     * Checks if an encryption key exists in the user session.
     * @returns {boolean} True if the key exists, false otherwise.
     */
    checkIfKeyExists: () => {
        const userSession = JSON.parse(localStorage.getItem('userSession'));

        try {
            if (!userSession.token || userSession.token === "" ) {
                return false;
            }
            return true;
        } catch (error) {
            return false;
        }
    },

    /**
     * Encrypts the given data using AES encryption.
     * @param {string} data - The data to encrypt.
     * @param {string} [iv=""] - The initialization vector (IV) for encryption.
     * @param {string} [givenKey=""] - The encryption key. If not provided, the key from the user session will be used.
     * @returns {string} The encrypted data as a string.
     */
    encrypt: (data, iv = "", givenKey = "") => {
        var key;

        if(data === undefined) {
            return;
        }

        try {
            if(givenKey === ""){
                const userSession = JSON.parse(localStorage.getItem('userSession'));
                key = userSession.token;
            } else {
                key = givenKey;
            }
        } catch (error) {
            return data;
        }

        if (!key) {
            return data;
        }

        if(iv === "" || iv === undefined || iv === null) {
            return data;
        }

        const parsedKey = CryptoJS.enc.Hex.parse(key);

        var parsedIv = CryptoJS.enc.Hex.parse(iv);

        const encrypted = CryptoJS.AES.encrypt(data, parsedKey, {
            iv: parsedIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.toString();
    },

    /**
     * Decrypts the given encrypted data using AES decryption.
     * @param {string} encryptedData - The data to decrypt.
     * @param {string} [iv=""] - The initialization vector (IV) used for decryption.
     * @returns {string} The decrypted data as a string.
     */
    decrypt: (encryptedData, iv = "") => {
        var key = "";

        try {
            const userSession = JSON.parse(localStorage.getItem('userSession'));
            key = userSession.token;
        } catch (error) {
            return encryptedData;
        }

        if(iv === "" || iv === undefined || iv === null) {
            return encryptedData;
        }

        if(encryptedData === undefined || encryptedData === null) {
            return encryptedData;
        }

        if (!key || key === "" || key.length === 0) {
            return encryptedData;
            throw new Error('No secret key found in localStorage');
        }
        const parsedKey = CryptoJS.enc.Hex.parse(key);
        const parsedIv = CryptoJS.enc.Hex.parse(iv);

        const decrypted = CryptoJS.AES.decrypt(encryptedData, parsedKey, {
            iv: parsedIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }
};

export default EncryptionService;