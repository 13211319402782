// frontend/src/components/generic-view-components/BudgetExplorer.js
import React, { useEffect, useState } from 'react';
import PieChart from '../charts/PieChart';
import FormattedAmount from '../../logical-ui-elements/FormattedAmount';
import EncryptionService from '../../../services/encryptionService';
import i18n from "../../../translations/i18n";
import translations from "./BudgetExplorer.json";
import { distinctValueSummarizer } from '../../../services/dataService';
import './BudgetExplorer.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const BudgetExplorer = ({ financialPositions }) => {
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [deepDiveIndex, setDeepDiveIndex] = useState(0);
    const [filterKey, setFilterKey] = useState('type');
    const [previousFilterKey, setPreviousFilterKey] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [legend, setLegend] = useState([]);
    const [colorScheme, setColorScheme] = useState([]);
    const [borderColorScheme, setBorderColorScheme] = useState([]);
    const [chosenType, setChosenType] = useState('income');
    const [animate, setAnimate] = useState(false);

    const navigate = useNavigate();

    const levelArray = ['type', 'category', 'title'];

    const expenseColors = [
        'rgba(243, 156, 18, 0.6)',  // Sunset Orange
        'rgba(231, 76, 60, 0.6)',   // Red
        'rgba(241, 199, 134, 0.6)', // Sunset Orange Subtle
        'rgba(230, 162, 155, 0.6)', // Red Subtle
        'rgba(243, 156, 18, 0.4)',  // Sunset Orange
        'rgba(231, 76, 60, 0.4)',   // Red
        'rgba(241, 199, 134, 0.4)', // Sunset Orange Subtle
        'rgba(230, 162, 155, 0.4)', // Red Subtle
        'rgba(243, 156, 18, 0.2)',  // Sunset Orange
        'rgba(231, 76, 60, 0.2)',   // Red
        'rgba(241, 199, 134, 0.2)', // Sunset Orange Subtle
        'rgba(230, 162, 155, 0.2)', // Red Subtle
    ];

    const expenseBorderColors = [
        'rgba(243, 156, 18, 1)',  // Sunset Orange
        'rgba(231, 76, 60, 1)',   // Red
        'rgba(241, 199, 134, 1)', // Sunset Orange Subtle
        'rgba(230, 162, 155, 1)', // Red Subtle
    ];

    const incomeColors = [
        'rgba(26, 188, 156, 0.6)',  // Teal
        'rgba(39, 174, 96, 0.6)',   // Green
        'rgba(118, 221, 201, 0.6)', // Teal Subtle
        'rgba(12, 89, 75, 0.6)',    // Teal Emphasis
        'rgba(104, 165, 130, 0.6)', // Green Subtle
        'rgba(21, 92, 51, 0.6)',    // Green Emphasis
        'rgba(26, 188, 156, 0.4)',  // Teal
        'rgba(39, 174, 96, 0.4)',   // Green
        'rgba(118, 221, 201, 0.4)', // Teal Subtle
        'rgba(12, 89, 75, 0.4)',    // Teal Emphasis
        'rgba(104, 165, 130, 0.4)', // Green Subtle
        'rgba(21, 92, 51, 0.4)',    // Green Emphasis
        'rgba(26, 188, 156, 0.2)',  // Teal
        'rgba(39, 174, 96, 0.2)',   // Green
        'rgba(118, 221, 201, 0.2)', // Teal Subtle
        'rgba(12, 89, 75, 0.2)',    // Teal Emphasis
        'rgba(104, 165, 130, 0.2)', // Green Subtle
        'rgba(21, 92, 51, 0.2)',    // Green Emphasis
    ];

    const incomeBorderColors = [
        'rgba(26, 188, 156, 1)',  // Teal
        'rgba(39, 174, 96, 1)',   // Green
        'rgba(118, 221, 201, 1)', // Teal Subtle
        'rgba(12, 89, 75, 1)',    // Teal Emphasis
        'rgba(104, 165, 130, 1)', // Green Subtle
        'rgba(21, 92, 51, 1)',    // Green Emphasis
    ];

    const savingsColors = [
        'rgba(52, 152, 219, 0.6)',  // Cobalt Blue
        'rgba(93, 173, 226, 0.6)',  // Light Blue
        'rgba(132, 176, 205, 0.6)', // Cobalt Blue Subtle
        'rgba(168, 199, 220, 0.6)', // Light Blue Subtle
        'rgba(26, 79, 115, 0.6)',   // Cobalt Blue Emphasis
        'rgba(39, 74, 96, 0.6)',    // Light Blue Emphasis
        'rgba(52, 152, 219, 0.4)',  // Cobalt Blue
        'rgba(93, 173, 226, 0.4)',  // Light Blue
        'rgba(132, 176, 205, 0.4)', // Cobalt Blue Subtle
        'rgba(168, 199, 220, 0.4)', // Light Blue Subtle
        'rgba(26, 79, 115, 0.4)',   // Cobalt Blue Emphasis
        'rgba(39, 74, 96, 0.4)',    // Light Blue Emphasis
        'rgba(52, 152, 219, 0.2)',  // Cobalt Blue
        'rgba(93, 173, 226, 0.2)',  // Light Blue
        'rgba(132, 176, 205, 0.2)', // Cobalt Blue Subtle
        'rgba(168, 199, 220, 0.2)', // Light Blue Subtle
        'rgba(26, 79, 115, 0.2)',   // Cobalt Blue Emphasis
        'rgba(39, 74, 96, 0.2)',    // Light Blue Emphasis
    ];

    const savingsBorderColors = [
        'rgba(52, 152, 219, 1)',  // Cobalt Blue
        'rgba(93, 173, 226, 1)',  // Light Blue
        'rgba(132, 176, 205, 1)', // Cobalt Blue Subtle
        'rgba(168, 199, 220, 1)', // Light Blue Subtle
        'rgba(26, 79, 115, 1)',   // Cobalt Blue Emphasis
        'rgba(39, 74, 96, 1)',    // Light Blue Emphasis
    ];

    const onSegmentClick = (label) => {
        if (label === 'remaining' || label === 'deficit') {
            return;
        }
        if (deepDiveIndex < levelArray.length - 1) {
            if (deepDiveIndex === 0) {
                setChosenType(label);
            }
            setDeepDiveIndex((prev) => prev + 1);
            setPreviousFilterKey(filterKey);
            setFilterKey(label);
        }
    };

    useEffect(() => {
        if (deepDiveIndex === 0) {
            const colorScheme = chartLabels.map((label) => {
                switch (label) {
                    case 'income':
                        return incomeColors[0];
                    case 'expense':
                        return expenseColors[0];
                    case 'savings':
                        return savingsColors[0];
                    default:
                        return 'rgba(44, 62, 80, 0.4)';
                }
            });
            setColorScheme(colorScheme);

            const borderColorScheme = chartLabels.map((label) => {
                switch (label) {
                    case 'income':
                        return incomeBorderColors[0];
                    case 'expense':
                        return expenseBorderColors[0];
                    case 'savings':
                        return savingsBorderColors[0];
                    case 'remaining':
                        return 'rgba(44, 62, 80, 1)';
                }
            });
            setBorderColorScheme(borderColorScheme);

        } else {
            switch (chosenType) {
                case 'income':
                    setColorScheme(incomeColors);
                    setBorderColorScheme(incomeBorderColors);
                    break;
                case 'expense':
                    setColorScheme(expenseColors);
                    setBorderColorScheme(expenseBorderColors);
                    break;
                case 'savings':
                    setColorScheme(savingsColors);
                    setBorderColorScheme(savingsBorderColors);
                    break;
            }
        }
    }, [deepDiveIndex, chosenType, chartLabels]);

    useEffect(() => {
        if (financialPositions) {
            let filteredData;
            if (deepDiveIndex === 0) {
                filteredData = financialPositions;
                const income = filteredData.filter(item => item.type === 'income').reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.annual_position_size, item.iv)), 0);
                const budgetDistribution = filteredData.filter(item => item.type !== 'income').reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.annual_position_size, item.iv)), 0);
                const deficit = (income - budgetDistribution).toFixed(2);
                if (deficit > 0) {
                    filteredData = [...filteredData, { type: 'remaining', annual_position_size: deficit }];
                } else {
                    filteredData = [...filteredData, { type: 'deficit', annual_position_size: deficit }];
                }
            } else {
                filteredData = financialPositions.filter((item) => {
                    if(deepDiveIndex < 2) {
                        return item[levelArray[deepDiveIndex - 1]] === filterKey
                    } else {
                        return EncryptionService.decrypt(item[levelArray[deepDiveIndex - 1]], item.iv) === filterKey
                    }
                });
            }

            let distinctValueSummary = distinctValueSummarizer(filteredData, levelArray[deepDiveIndex], 'annual_position_size');
            distinctValueSummary = Object.fromEntries(
                Object.entries(distinctValueSummary).sort(([, a], [, b]) => b - a)
            );
            const valueArray = Object.values(distinctValueSummary);
            const distinctValues = Object.keys(distinctValueSummary);
            setChartLabels(distinctValues);
            setChartData(valueArray);
            setLegend(distinctValueSummary);
            setFilteredList(filteredData);
            setAnimate(true);
            setTimeout(() => setAnimate(false), 500);
        }
    }, [financialPositions, deepDiveIndex, filterKey]);

    return (
        <div className="col-lg-12 col-md-6 col-sm-12 mt-5">
            <div className="col-12 text-center mb-4">
                <h1>Budget Explorer</h1>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                    <PieChart
                        labels={chartLabels}
                        chartData={chartData}
                        onSegmentClick={onSegmentClick}
                        backgroundColors={colorScheme}
                        borderColors={borderColorScheme}
                    />
                </div>
                {legend && (
                    <div className="col-lg-8 col-md-6 col-sm-12 mt-3 mt-md-0">
                        <h3 className="text-center">{deepDiveIndex === 0 ? i18n.t('types') : deepDiveIndex === 1 ? i18n.t('categories') : i18n.t('budget-position')}</h3>
                        <ul className={`list-group ${animate ? 'fade-in' : ''}`}>
                            {Object.keys(legend).map((key, index) => (
                                <li key={index + "_" + key} className="list-group-item list-group-item-action"
                                    style={{backgroundColor: colorScheme[index % colorScheme.length], cursor: 'pointer'}}
                                    onClick={() => {
                                        if (deepDiveIndex < levelArray.length - 1) {
                                            onSegmentClick(key);
                                        } else {
                                            const positionId = filteredList.find(item => EncryptionService.decrypt(item[levelArray[deepDiveIndex]], item.iv) === key).id;
                                            navigate(`/financial-positions/details/${positionId}`);
                                        }
                                    }}>
                                    <span className="d-flex justify-content-between align-items-center">
                                        <span className="">{deepDiveIndex === 2 ?
                                            <FontAwesomeIcon icon="fa-solid fa-link"/> : null} {i18n.t(key)} <span
                                            className="small">({(legend[key] / filteredList.reduce((acc, item) => acc + parseFloat(EncryptionService.decrypt(item.annual_position_size, item.iv)), 0) * 100).toFixed(2)}%)</span></span>
                                        <span className="small"><FormattedAmount
                                            amount={legend[key].toFixed(2)}/></span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                        {deepDiveIndex > 0 && (
                        <div className="col-12 text-center">
                            <button className="mt-3 btn btn-primary" onClick={() => {
                                setDeepDiveIndex((prev) => {
                                    if (prev > 0) {
                                        const newIndex = prev - 1;
                                        setFilterKey(previousFilterKey);
                                        return newIndex;
                                    }
                                    return prev;
                                });
                            }}>{i18n.t('back')}
                            </button>
                        </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BudgetExplorer;