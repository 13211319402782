import EncryptionService from './encryptionService';
import {isNumber} from "chart.js/helpers";

// This function retrieves all positions of a specific type (income, expense, savings) from the positions array
function getTypePositions(positions, type) {
    return positions.filter(position => position.type === type);
}

function summarizeIndividualPositions(positions, key) {
    return positions.reduce((acc, position) => {
        const value = EncryptionService.decrypt(position[key], position.iv);
        if(isNumber(value)) {
            return acc + parseFloat(EncryptionService.decrypt(position[key], position.iv));
        } else {
            return acc;
        }
    }, 0);
}

function calculateDifference(income, expense, savings) {
    let difference = income - expense - savings;
    if(difference < 0) {
        return difference * -1;
    } else {
        return difference;
    }
}

function calculatePercentageOfIncome(value, income) {
    if(income === 0 || value === 0) {
        return 0;
    } else {
        return parseFloat(((value / income) * 100).toFixed(2));
    }
}

function calculatePercentageOfPlannedBudget(value, plannedBudget) {
    if(plannedBudget === 0 || value === 0) {
        return 0;
    } else {
        return parseFloat(((value / plannedBudget) * 100).toFixed(2));
    }
}

//This service calculates the remaining income after all the expenses and savings planned by the user
export function getBudgetedSumsAndRemaining(positions) {

    // Makes sure the right data is sent to the function
    if(Array.isArray(positions) && positions.length > 0) {

        //Get all positions of the types income
        const incomePositions = getTypePositions(positions, 'income');
        const incomeAnnualSum = summarizeIndividualPositions(incomePositions, 'annual_position_size');
        const incomeMonthlySum = summarizeIndividualPositions(incomePositions, 'monthly_position_size');

        //Get all positions of the types expense
        const expensePositions = getTypePositions(positions, 'expense');
        const expenseAnnualSum = summarizeIndividualPositions(expensePositions, 'annual_position_size');
        const expenseMonthlySum = summarizeIndividualPositions(expensePositions, 'monthly_position_size');

        //Get all positions of the types savings
        const savingsPositions = getTypePositions(positions, 'savings');
        const savingsAnnualSum = summarizeIndividualPositions(savingsPositions, 'annual_position_size');
        const savingsMonthlySum = summarizeIndividualPositions(savingsPositions, 'monthly_position_size');

        //Calculate the remaining incomes
        const remainingAnnualIncome = calculateDifference(incomeAnnualSum, expenseAnnualSum, savingsAnnualSum);
        const remainingMonthlyIncome = calculateDifference(incomeMonthlySum, expenseMonthlySum, savingsMonthlySum);

        return {
            income: [
                incomeAnnualSum,
                incomeMonthlySum
            ],
            expense: [{
                expenseAnnualSum,
                expenseMonthlySum,
                percentageOfIncome: [{
                    annual: calculatePercentageOfIncome(expenseAnnualSum, incomeAnnualSum),
                    monthly: calculatePercentageOfIncome(expenseMonthlySum, incomeMonthlySum)
                }],
                percentageOfExpenses: [{
                    annual: calculatePercentageOfPlannedBudget(expenseAnnualSum, incomeAnnualSum - remainingAnnualIncome),
                    monthly: calculatePercentageOfPlannedBudget(expenseMonthlySum, incomeMonthlySum - remainingMonthlyIncome)
                }]
            }],
            savings: [{
                savingsAnnualSum,
                savingsMonthlySum,
                percentageOfIncome: [{
                    annual: calculatePercentageOfIncome(savingsAnnualSum, incomeAnnualSum),
                    monthly: calculatePercentageOfIncome(savingsMonthlySum, incomeMonthlySum)
                }],
                percentageOfExpenses: [{
                    annual: calculatePercentageOfPlannedBudget(savingsAnnualSum, incomeAnnualSum - remainingAnnualIncome),
                    monthly: calculatePercentageOfPlannedBudget(savingsMonthlySum, incomeMonthlySum - remainingMonthlyIncome)
                }]
            }],
            remaining: [{
                remainingAnnualIncome,
                annualIsDeficit: incomeAnnualSum - expenseAnnualSum - savingsAnnualSum < 0,
                remainingMonthlyIncome,
                monthlyIsDeficit: incomeMonthlySum - expenseMonthlySum - savingsMonthlySum < 0,
                percentageOfIncome: [{
                    annual: calculatePercentageOfIncome(remainingAnnualIncome, incomeAnnualSum),
                    monthly: calculatePercentageOfIncome(remainingMonthlyIncome, incomeMonthlySum)
                }],
                percentageOfExpenses: [{
                    annual: calculatePercentageOfPlannedBudget(remainingAnnualIncome, incomeAnnualSum + remainingAnnualIncome),
                    monthly: calculatePercentageOfPlannedBudget(remainingMonthlyIncome, incomeMonthlySum + remainingMonthlyIncome)
                }]
            }]
        }
    } else {
        return {
            income: [0, 0],
            expense: [0, 0],
            savings: [0, 0],
            remaining: [0, 0]
        }
    }
}