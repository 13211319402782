import React, { useEffect, useState, useRef } from "react";
import { getAllData, deleteDataById, deleteDataByKey } from "../../services/indexedDB";
import Loading from "../../components/generic-view-components/loading/Loading";
import './budgetCheck.css';
import i18n from "../../translations/i18n";
import BudgetScore from "../../components/logical-ui-elements/BudgetScore";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import { useNavigate } from "react-router-dom";
import FormatDate from "../../components/logical-ui-elements/FormatDate";
import EncryptionService from "../../services/encryptionService";
import MonthNameGiver from "../../components/logical-ui-elements/MonthNameGiver";
import { getDistinctValues } from "../../services/dataService";
import InputSelect from "../../components/logical-ui-elements/InputSelect";
import {createBudgetCheckSnapshot} from "../../services/budgetCheckSnapshot";
import translations from "./BudgetChecks.json";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

function BudgetChecks() {
    const [loading, setLoading] = useState(true);
    const { t } = i18n;
    const navigate = useNavigate();

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectableYears, setSelectableYears] = useState([]);
    const [budgetCheckArray, setBudgetCheckArray] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const confirmDeleteRef = useRef(null);

    const fetchAllBudgetCheckPositions = async () => {
        const fetchedData = await getAllData('budget-checks');
        setSelectableYears(() => {
            const sYears = getDistinctValues(fetchedData.values, 'year')
            if(sYears.includes(currentYear)) {
                return sYears.sort((a, b) => b - a);
            } else if(currentMonth === 11) {
                return [...sYears, currentYear + 1].sort((a, b) => b - a);
            } else {
                return [...sYears, currentYear].sort((a, b) => b - a);
            }
        });
        setBudgetCheckArray(fetchedData.values.filter(item => item.year === parseInt(selectedYear)));
        setTimeout(() => {
            if(fetchedData.values.length > 0) {
                setLoading(false)
            }
        }, 1000);
    };

    useEffect(() => {
        if(confirmDelete === null) {
            setLoading(true);
            fetchAllBudgetCheckPositions();
        }
    }, [selectedYear, confirmDelete]);

    const goToBudgetCheck = (id) => {
        navigate('/budget-checks/details/' + id);
    };

    const handleDeleteClick = async (id) => {
        if (confirmDelete === id) {
            setLoading(true);
            await deleteDataById('budget-checks', id);
            await deleteDataByKey('transactions', 'budget_check_id', id);
            fetchAllBudgetCheckPositions();
        } else {
            setConfirmDelete(id);
        }
    };

    const handleClickOutside = (event) => {
        if (confirmDeleteRef.current && !confirmDeleteRef.current.contains(event.target) && event.target.tagName !== 'BUTTON') {
            setConfirmDelete(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const createBudgetCheck = async (month) => {
        setLoading(true);
        await createBudgetCheckSnapshot(selectedYear, month).then(() => {
            fetchAllBudgetCheckPositions();
        });
    };

    const months = Array.from({ length: 12 }, (_, i) => i).reverse();
    const budgetCheckMap = budgetCheckArray.reduce((acc, item) => {
        acc[item.month] = item;
        return acc;
    }, {});

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mt-5 text-center">{t('budget-checks')}</h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <InputSelect
                    field={{
                        key: 'year',
                        required: true,
                        label: 'Select Year',
                        options: selectableYears.map(year => ({ key: year, label: year })),
                    }}
                    formValues={{ year: selectedYear }}
                    callback={({name, value}) => {setSelectedYear(value)}}
                />
            </div>
            {loading ? (
                <Loading key="loader" />
            ) : (
                <div className="row">
                    {months.map((month) =>
                        budgetCheckMap[month] ? (
                            <div key={month} className="col-lg-4 col-md-6 col-sm-12 g-5">
                                <div className="card mt-1 p-0 h-100">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <span><MonthNameGiver month={budgetCheckMap[month].month} /> {budgetCheckMap[month].year}</span>
                                        {budgetCheckMap[month].closed ? (
                                            <span><i className="bi bi-door-closed" /> {i18n.t('closed')}</span>
                                        ) : (
                                            <span><i className="bi bi-door-open" /> {i18n.t('open')}</span>
                                        )}
                                    </div>
                                    <div className="card-body text-center">
                                        <h2><BudgetScore score={budgetCheckMap[month].score} /></h2>
                                        <h2><FormattedAmount amount={EncryptionService.decrypt(budgetCheckMap[month].budget_deviation, budgetCheckMap[month].iv)} /></h2>
                                    </div>
                                    <div className="card-footer justify-content-between align-items-center d-flex">
                                        <span className="small fst-italic"><FormatDate dateString={budgetCheckMap[month].creation_date} /></span>
                                        <span ref={confirmDeleteRef}>
                                            {confirmDelete === budgetCheckMap[month].id ? (
                                                <button className="btn btn-danger me-1" onClick={() => handleDeleteClick(budgetCheckMap[month].id)}>Are you sure?</button>
                                            ) : (
                                                <button className="btn btn-danger me-1" onClick={() => handleDeleteClick(budgetCheckMap[month].id)}><i className="bi bi-trash" /></button>
                                            )}
                                            <button className="btn btn-primary ms-1" onClick={() => goToBudgetCheck(budgetCheckMap[month].id)}>{budgetCheckMap[month].closed ? <i className="bi bi-eye" /> : <i className="bi bi-pencil" />}</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ((selectedYear < currentYear )|| (selectedYear === currentYear && month <= currentMonth + 1) || (selectedYear > currentYear && month === 0)) ? (
                                <div key={month} className="col-lg-4 col-md-6 col-sm-12 g-5">
                                    <div className="empty-card card mt-1 p-0 h-100">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <MonthNameGiver month={month}/> {selectedYear}
                                            <span><i className="bi bi-mailbox-flag"/> {t('ready')}</span>
                                        </div>
                                        <div className="card-body text-center align-middle">
                                            <button className="btn btn-primary" onClick={() => createBudgetCheck(month)}>
                                                {t('create-budgetcheck')}
                                            </button>
                                        </div>
                                        <div className="card-footer fst-italic small">
                                            This budget check has not yet been created
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        )
                    )}
                </div>
            )}
        </div>
    );
}

export default BudgetChecks;