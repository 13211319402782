import React from 'react';
import AddButtonRegular from "../../../components/logical-ui-elements/AddButtonRegular";
import './NoData.css';
import i18n from "../../../translations/i18n"

function NoData({pageContent, location}) {

    const t = i18n.t.bind(i18n);

    return (
        <div className="container">
            <div className="row no-data">
                <div className="col-12 text-center">
                    <h2>{t('no-data-available')}</h2>
                    <h4>{t('no-data-message', { pageContent })}</h4>
                    <AddButtonRegular
                        location={location}
                        pageTitle={pageContent}
                    />
                </div>
            </div>
        </div>
    );
}

export default NoData;