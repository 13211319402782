import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import InputCurrency from '../../components/logical-ui-elements/InputCurrency';
import { updateDataById, deleteDataById } from "../../services/indexedDB";
import EncryptionService from "../../services/encryptionService";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import i18n from "./../../translations/i18n";
import translations from "./TransactionCard.json";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

const TransactionCard = ({ transaction, index, signalChange, budgetCheckStatus }) => {
    const [amountPaid, setAmountPaid] = useState(0);
    const [isChecked, setIsChecked] = useState(transaction.isChecked);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const confirmDeleteRef = useRef(null);
    const deleteButtonRef = useRef(null);
    const [planDeviationPositive, setPlanDeviationPositive] = useState(false);
    const [deviation, setDeviation] = useState(false);

    useEffect(() => {
        setAmountPaid(parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv)));
        setIsChecked(transaction.isChecked);
    }, [transaction]);

    useEffect(() => {
        const planned = parseFloat(EncryptionService.decrypt(transaction.amount_planned, transaction.iv));
        const paid = parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv));

        if (planned !== paid) {
            setDeviation(true);
            if ((transaction.type === 'income' || transaction.type === 'savings' || transaction.type === 'provisions') && planned > paid) {
                setPlanDeviationPositive(false);
            } else if ((transaction.type === 'income' || transaction.type === 'savings' || transaction.type === 'provisions') && planned < paid) {
                setPlanDeviationPositive(true);
            } else if (planned > paid) {
                setPlanDeviationPositive(true);
            } else {
                setPlanDeviationPositive(false);
            }
        } else {
            setDeviation(false);
        }
    }, [amountPaid, transaction]);

    const handleCurrencyChange = (e) => {
        setAmountPaid(parseFloat(e.target.value));
    }

    const handleSave = (e, id) => {
        if(budgetCheckStatus) {
            return;
        }
        const updatedChecked = !isChecked;
        const EncryptedAmountPaid = EncryptionService.encrypt(amountPaid.toString(), transaction.iv);
        setIsChecked(updatedChecked);
        updateDataById('transactions', id, { ...transaction, isChecked: updatedChecked, amount_paid: EncryptedAmountPaid });
        signalChange(true);
    }

    const handleNotPaid = () => {
        const EncryptedAmountPaid = EncryptionService.encrypt("0", transaction.iv);
        setAmountPaid(0);
        setIsChecked(true);
        updateDataById('transactions', transaction.id, { ...transaction, isChecked: true, amount_paid: EncryptedAmountPaid });
        signalChange(true);
    }

    const cancelDelete = () => {
        setConfirmDelete(false);
    }

    const handleDelete = () => {
        if(confirmDelete === true) {
            deleteDataById('transactions', transaction.id);
            signalChange(true);
            setConfirmDelete(false);
        } else {
            setConfirmDelete(true);
        }
    }

    const popover = (
        <Popover id="popover-basic" ref={confirmDeleteRef}>
            <Popover.Body>{i18n.t('are-you-sure')}</Popover.Body>
        </Popover>
    );

    return (
        <div className="col-12 col-md-6 col-lg-4 g-5">
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <span><FontAwesomeIcon icon={transaction.icon} /> {EncryptionService.decrypt(transaction.title, transaction.iv)}</span>
                    <span>
                        {deviation && amountPaid !== 0 && (
                            planDeviationPositive ? <i className="bi bi-arrow-through-heart-fill text-success me-1"></i> : <i className="bi bi-exclamation-diamond-fill text-danger me-1"></i>
                        )}
                        {isChecked ?
                            <i className="bi bi-check2-square text-success" /> : <i className="bi bi-square text-warning" />
                        }
                    </span>
                </div>
                <div className="card-body text-center">
                    <InputCurrency
                        showCurrency={true}
                        value={amountPaid}
                        id={`amount-${index}-${transaction.id}`}
                        name={`amount-${index}-${transaction.id}`}
                        disabled={isChecked}
                        callback={(e) => handleCurrencyChange(e, transaction.id)}
                    />
                </div>
                <div className="card-footer justify-content-between align-items-center d-flex">
                    <div>
                    <span className="badge bg-info me-1">{i18n.t(transaction.transaction_type)}</span>
                        {amountPaid === 0 && (
                            <span className="badge bg-danger">{i18n.t('not-paid')}</span>
                        )}
                        {deviation && amountPaid !== 0 && (
                            planDeviationPositive ? <span className="fst-italic badge bg-success me-1">{i18n.t('deviation')}: +<FormattedAmount amount={parseFloat(EncryptionService.decrypt(transaction.amount_planned, transaction.iv)) - parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv))} /></span> : <span className="fst-italic badge bg-danger me-1">{i18n.t('deviation')}:  -<FormattedAmount amount={parseFloat(EncryptionService.decrypt(transaction.amount_planned, transaction.iv)) - parseFloat(EncryptionService.decrypt(transaction.amount_paid, transaction.iv))} /></span>
                        )}
                    </div>
                    <span>
                        {confirmDelete && (
                            <button
                                className="btn btn-outline-secondary me-2"
                                disabled={!confirmDelete}
                                onClick={cancelDelete}
                            >
                                <i className="bi bi-x-lg"/>
                            </button>
                        )}
                        {!transaction.isUnexpected ? (
                            <button
                                className="btn btn-danger me-2"
                                disabled={isChecked || budgetCheckStatus}
                                onClick={handleNotPaid}
                            >
                            <i className="bi bi-x-lg"/>
                             </button>
                         ) : (
                             <OverlayTrigger trigger="click" placement="top" overlay={popover} show={confirmDelete}>
                                 <button
                                     className="btn btn-danger me-2"
                                     disabled={isChecked || budgetCheckStatus}
                                     onClick={handleDelete}
                                     ref={deleteButtonRef}
                                 >
                                     {confirmDelete ? <i className="bi bi-check-lg"/> : <i className="bi bi-trash"/>}
                                 </button>
                             </OverlayTrigger>
                         )}
                        {!confirmDelete && (
                            <>
                                <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={`amount-${index}-${transaction.id}`}
                                    name={`amount-${index}-${transaction.id}`}
                                    checked={isChecked}
                                    disabled={budgetCheckStatus}
                                    onChange={(e) => handleSave(e, transaction.id)}
                                />
                                <label className={`btn ${transaction.isChecked ? "btn-primary" : "btn-warning"}`} htmlFor={`amount-${index}-${transaction.id}`}>
                                    {transaction.isChecked ? <i className="bi bi-unlock"/> : <i className="bi bi-check-lg"/>}
                                </label>
                            </>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TransactionCard;