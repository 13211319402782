// frontend/src/core-components/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();

    return (
        <h1>You have been logged out.</h1>
    );
}

export default Logout;
