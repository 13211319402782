import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import i18n from "./../../translations/i18n";
import translations from "./DetailViewPage.json";
import {getAllData, getDataByKey} from "../../services/indexedDB";
import Loading from "../../components/generic-view-components/loading/Loading";
import './DetailViewPage.css';
import viewConfiguration from "./../main-menu-overview-page/viewConfiguration.json";
import CreateEditForm from "../../components/generic-view-components/create-edit-form/CreateEditForm";
import DeleteForm from "../../components/generic-view-components/delete-form/DeleteForm";
import EncryptionService from "../../services/encryptionService";
import BarChart from "../../components/generic-view-components/charts/BarChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormattedAmount from "../../components/logical-ui-elements/FormattedAmount";
import WidgetContainer from "../../components/generic-view-components/widget-container/WidgetContainer";
import {calculateCategorySum, calculateCategorySumNonEncryptedFilterField} from "../../services/dataService";
import InputSelect from "../../components/logical-ui-elements/InputSelect";
import MonthNameGiver from "../../components/logical-ui-elements/MonthNameGiver";
import {
    buildPlannedDataArray,
    buildTransactionDataArray, calculatePlannedProvisionsFromPreviousYear, calculateProvisionsFromPreviousYear,
    combineDataArrays,
    summarizeMonthlyData
} from "./DetailViewPageLogic";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

function DetailViewPage( {setChange }) {

    // Configuration states
    const locationArray = useLocation();
    const { pos_id } = useParams();
    const [viewConfig, setViewConfig] = useState([]);
    const [config_id, setConfigId] = useState(null);
    const [formFieldsConfig, setFormFieldsConfig] = useState([]);
    const { state } = locationArray;
    const { monthlyRemainingIncome, annualRemainingIncome } = state || {};

    // Logical states
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);

    // Data Sets
    const [financialPositions, setFinancialPositions] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [currentFinancialPosition, setCurrentFinancialPosition] = useState([]);
    const [chartData, setChartData] = useState([]);

    //KPI states
    const [deficit, setDeficit] = useState(0);
    const [deficitDetailText, setDeficitDetailText] = useState('');
    const [aheadOfSchedule, setAheadOfSchedule] = useState(false);
    const [behindSchedule, setBehindSchedule] = useState(false);
    const [perfectPlan, setPerfectPlan] = useState(false);
    const [categoryRatio, setCategoryRatio] = useState(0);
    const [incomeRatio, setIncomeRatio] = useState(0);

    useEffect(() => {
        if(financialPositions && financialPositions.length > 0) {
            const filterValue = EncryptionService.decrypt(currentFinancialPosition.category, currentFinancialPosition.iv);
            const catSum = calculateCategorySum(financialPositions, 'annual_position_size', 'category', filterValue);
            setCategoryRatio((parseFloat(EncryptionService.decrypt(currentFinancialPosition.annual_position_size, currentFinancialPosition.iv)) / catSum) * 100);

            const incomeSum = calculateCategorySum(financialPositions, 'annual_position_size', 'type', 'income');
            const annualPositionSize = parseFloat(EncryptionService.decrypt(currentFinancialPosition.annual_position_size, currentFinancialPosition.iv));
            setIncomeRatio((annualPositionSize / incomeSum) * 100);
        }
    }, [financialPositions]);

    useEffect(() => {
        if(aheadOfSchedule) {
            setDeficitDetailText(i18n.t('ahead-of-schedule'))
        }
        if(behindSchedule) {
            setDeficitDetailText(i18n.t('behind-schedule'))
        }
        if(perfectPlan) {
            setDeficitDetailText(i18n.t('perfect-plan'))
        }

    }, [deficit, aheadOfSchedule, behindSchedule, perfectPlan]);

    const prepareChartDataRegular = () => {
        const contextArray = transactionData.filter(item => item.year === parseInt(selectedYear));
        const planned = [];
        const effective = [];
        for (let i = 0; i < 12; i++) {
            const item = contextArray.find(item => item.payable === i);
            if (item) {
                planned.push(parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv)) || 0);
                effective.push(parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)) || 0);
            } else {
                if(parseInt(currentFinancialPosition.monthly_position_size) === 0 && currentFinancialPosition.payable.includes(i)) {
                    planned.push(parseFloat(EncryptionService.decrypt(currentFinancialPosition.annual_position_size, currentFinancialPosition.iv)) || 0);
                    effective.push(0);
                } else {
                    planned.push(parseFloat(EncryptionService.decrypt(currentFinancialPosition.monthly_position_size, currentFinancialPosition.iv)) || 0);
                    effective.push(0);
                }
            }
        }

        const plannedAmount = contextArray.reduce((acc, item) => acc + (parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv)) || 0), 0);
        const effectiveAmount = contextArray.reduce((acc, item) => acc + (parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)) || 0), 0);
        const isExpense = currentFinancialPosition.type === 'expense';
        const def = contextArray.reduce((acc, item) => acc + (parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv)) || 0), 0) - contextArray.reduce((acc, item) => acc + (parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv)) || 0), 0);

        const isEqual = effectiveAmount === plannedAmount;
        const isGreater = effectiveAmount > plannedAmount;
        const isLess = effectiveAmount < plannedAmount;

        if (isExpense) {
            setPerfectPlan(isEqual);
            setBehindSchedule(isGreater);
            setAheadOfSchedule(isLess);
            setDeficit(def < 0 ? def * -1 : def);
        } else {
            setPerfectPlan(isEqual);
            setBehindSchedule(isLess);
            setAheadOfSchedule(isGreater);
            setDeficit(def < 0 ? def * -1 : def);
        }

        setChartData([{
                label: i18n.t('budgeted'),
                type: 'line',
                data: planned,
                backgroundColor: 'rgba(93, 173, 226, 1)',
                borderColor: '#84b0cd',
                borderWidth: 1,
            },
            {
                label: i18n.t('effective'),
                type: 'bar',
                data: effective,
                backgroundColor: 'rgba(26, 188, 156, 0.4)',
                borderColor: '#F39C12',
                borderWidth: 0,
            }]);
    }

    const prepareChartDataIrregular = () => {
        // Filter the transaction data for the selected year
        const contextArray = transactionData.filter(item => item.year === parseInt(selectedYear));

        // Calculate planned provisions from the past year
        const plannedFromPreviousYear = calculatePlannedProvisionsFromPreviousYear(currentFinancialPosition);

        // Calculate planned provisions and bills for the current year
        const plannedProvisions = buildPlannedDataArray(currentFinancialPosition, true);
        const plannedBill = buildPlannedDataArray(currentFinancialPosition, false);

        // Combine the planned provisions and bills with the planned provisions from the previous year to build the full array
        const combinedPlannedProvisions = combineDataArrays(plannedProvisions, plannedBill, plannedFromPreviousYear);

        // Calculate effective provisions from the past year
        const effectiveFromPreviousYear = calculateProvisionsFromPreviousYear(transactionData, selectedYear);

        // Calculate effective provisions, bills and unexpected bills for the current year
        const provisions = buildTransactionDataArray(contextArray, 'regular', 'amount_paid');
        const bill = buildTransactionDataArray(contextArray, 'irregular', 'amount_paid');
        const unexpectedBill = buildTransactionDataArray(contextArray, 'unexpected', 'amount_paid');

        // Combine the effective provisions and bills with the unexpected bills to build the full array
        const combinedUnexpectedAndBillsArray = summarizeMonthlyData(unexpectedBill, bill);
        const combinedProvisionsUnexpectedBillsArray = combineDataArrays(provisions, combinedUnexpectedAndBillsArray, effectiveFromPreviousYear);


        const plannedAmount = combinedPlannedProvisions[combinedProvisionsUnexpectedBillsArray.length - 1];
        const effectiveAmount = combinedProvisionsUnexpectedBillsArray[combinedProvisionsUnexpectedBillsArray.length - 1];
        const def = plannedAmount - effectiveAmount;

        setPerfectPlan(effectiveAmount === plannedAmount);
        setBehindSchedule(effectiveAmount < plannedAmount);
        setAheadOfSchedule(effectiveAmount > plannedAmount);
        setDeficit(def < 0 ? def * -1 : def);

        setChartData([
            {
                label: i18n.t('effective-provisions'),
                type: 'line',
                data: combinedProvisionsUnexpectedBillsArray,
                backgroundColor: 'rgba(243, 156, 18, 1)',
                borderColor: '#F39C12',
                borderWidth: 1,
            },
            {
                label: i18n.t('planned-provisions'),
                borderDash: [5, 5],
                type: 'line',
                data: combinedPlannedProvisions,
                backgroundColor: 'rgba(93, 173, 226, 1)',
                borderColor: '#84b0cd',
                borderWidth: 1,
            },
            {
                label: i18n.t('effective-bills'),
                type: 'bar',
                data: combinedUnexpectedAndBillsArray,
                backgroundColor: 'rgba(243, 156, 18, 0.6)',
                borderColor: '#F39C12',
                borderWidth: 0,
            },
            {
                label: i18n.t('planned-bills'),
                type: 'bar',
                data: plannedBill,
                backgroundColor: 'rgba(93, 173, 226, 0.4)',
                borderColor: '#84b0cd',
                borderWidth: 0,
            }
        ]);
    }

    useEffect(() => {
        if(!selectedYear) {
            setSelectedYear(new Date().getFullYear());
        }
        if(transactionData) {
            const isRegular = currentFinancialPosition.payable && currentFinancialPosition.payable.length === 12;
            const hasProvisions = parseFloat(EncryptionService.decrypt(currentFinancialPosition.monthly_position_size, currentFinancialPosition.iv)) > 0;
            const isExpense = currentFinancialPosition.type === 'expense';

            if(isRegular === true || hasProvisions === false) {
                console.log("Handle regular income or expense");
                prepareChartDataRegular();
            }

            if(isRegular === false && hasProvisions === true && isExpense === true) {
                console.log("Handle irregular expense with provisions");
                prepareChartDataIrregular();
            } else {
                console.log("Handle irregular income with provisions");
                prepareChartDataRegular();
            }
        }
    }, [transactionData, selectedYear]);

    const fetchPositions = async () => {
        try {
            const data = await getAllData("financial-positions");
            setFinancialPositions(data.values);
            setCurrentFinancialPosition(data.values.find(item => parseInt(item.id) === parseInt(pos_id)));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    };

    const fetchTransactionData = async () => {
        try {
            const data = await getDataByKey("transactions", "finPosId", parseInt(pos_id));
            setTransactionData(data);
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    }

    useEffect(() => {
        if (location && config_id && pos_id) {
            fetchPositions();
            fetchTransactionData();
        }
    }, [config_id, pos_id]);

    useEffect(() => {
        if(config_id) {
            setViewConfig(viewConfiguration[config_id].detailViewConfig[0]);
        }
    }, [config_id]);

    useEffect(() => {
        setLoading(true);
        setLocation(locationArray.pathname.slice(1));
    }, [locationArray]);

    useEffect(() => {
        const parts = locationArray.pathname.split('/');
        const conf_id = parts.length > 2 ? parts[1] : null;
        setConfigId(conf_id);
        setFormFieldsConfig(viewConfiguration[conf_id].formFields);
    }, [locationArray]);

    useEffect(() => {
        if (!editMode && !deleteMode) {
            fetchPositions();
        }
    }, [editMode, deleteMode]);

    useEffect(() => {
        const handlePopState = (event) => {
            if (editMode || deleteMode) {
                setEditMode(false);
                setDeleteMode(false);
            } else {
                window.history.back();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [editMode, deleteMode]);

    const handleFormSubmit = async () => {
        setEditMode(false);
        setDeleteMode(false);
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
            !editMode && !deleteMode ? (
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="mb-3 mt-3"><FontAwesomeIcon icon={currentFinancialPosition.icon}/> {EncryptionService.decrypt(currentFinancialPosition.title, currentFinancialPosition.iv)}</h1>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-9 col-sm-12 col-md-8 text-center">
                            <BarChart chartData={chartData}/>
                            {transactionData.length === 0 ? (
                                <p className="text-danger fst-italic">{i18n.t('no-transactions-recorded-yet')}</p>
                            ) : null}
                        </div>
                        <div className="col-lg-3 col-sm-12 col-md-4">
                            <InputSelect
                                field={{
                                    label: i18n.t('select-year'),
                                    options: [{key: new Date().getFullYear(), label: new Date().getFullYear()}, {key: new Date().getFullYear() - 1, label: new Date().getFullYear() - 1}, {key: new Date().getFullYear() - 2 , label: new Date().getFullYear() - 2}, {key: new Date().getFullYear() - 3, label: new Date().getFullYear() - 3}, {key: new Date().getFullYear() - 4, label: new Date().getFullYear() - 4}, {key: new Date().getFullYear() - 5, label: new Date().getFullYear() - 5}],
                                }}
                                formValues={selectedYear}
                                callback={(e) => setSelectedYear(e.target.value)}
                            />
                            {currentFinancialPosition.payable && currentFinancialPosition.payable.length < 12 ? (
                                <p className="p-1">{i18n.t('irregular-payment-plan-explanation')}</p>
                            ) : (
                                <p className="p-1">{i18n.t('regular-payment-plan-explanation')}</p>
                            )}
                        </div>
                        <WidgetContainer
                            key="deficit"
                            title={i18n.t('budget-abbreviation')}
                            value={<FormattedAmount amount={deficit}/>}
                            detailDescription={deficitDetailText}
                        />
                        <WidgetContainer
                            key={"position-monthly-amount"}
                            title={i18n.t('position-monthly-amount')}
                            value={<FormattedAmount amount={parseFloat(EncryptionService.decrypt(currentFinancialPosition.monthly_position_size, currentFinancialPosition.iv))}/>}
                            detailDescription={i18n.t("position-monthly-amount-description")}
                        />
                        <WidgetContainer
                            key={"position-annual-amount"}
                            title={i18n.t('position-annual-amount')}
                            value={<FormattedAmount amount={parseFloat(EncryptionService.decrypt(currentFinancialPosition.annual_position_size, currentFinancialPosition.iv))}/>}
                            detailDescription={i18n.t("position-annual-amount-description")}
                        />
                        <WidgetContainer
                            key={"position-category"}
                            title={i18n.t('position-category')}
                            value={EncryptionService.decrypt(currentFinancialPosition.category, currentFinancialPosition.iv)}
                            detailDescription={i18n.t("position-category-description")}
                        />
                        <WidgetContainer
                            key={"category-ratio"}
                            title={i18n.t('category-ratio')}
                            value={categoryRatio.toFixed(2) + '%'}
                            detailDescription={i18n.t("category_ratio_description")}
                        />
                        <WidgetContainer
                            key={"income-ratio"}
                            title={i18n.t('income-ratio')}
                            value={incomeRatio.toFixed(2) + '%'}
                            detailDescription={i18n.t("income_ratio_description")}
                        />
                        <WidgetContainer
                            key={"position-user-tags"}
                            title={i18n.t('position-user-tags')}
                            value={null}
                            detailDescription={currentFinancialPosition.user_tags.map(tag => <span key={tag} className={"m-1 badge fst-italic rounded-pill bg-info"}>{EncryptionService.decrypt(tag, currentFinancialPosition.iv)}</span>)}
                        />
                        <WidgetContainer
                            key={"position-payable"}
                            title={i18n.t('position-payable')}
                            value={null}
                            detailDescription={currentFinancialPosition.payable.map(item => <span key={item} className={"m-1 badge fst-italic rounded-pill bg-info"}><MonthNameGiver month={item} /></span>)}
                        />
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <h2>{i18n.t('budget-check-transactions')}</h2>
                        </div>
                        <div className="row">
                            {transactionData.length === 0 ? (
                                <div className="col text-center">
                                    <p className="text-danger fst-italic">{i18n.t('no-transactions-recorded-yet')}</p>
                                </div>
                            ) : (
                                <div className="col">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col">{i18n.t('month')}</th>
                                            <th scope="col">{i18n.t('budgeted')}</th>
                                            <th scope="col">{i18n.t('effective')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {transactionData.filter(item => item.year === parseInt(selectedYear)).map((item, index) => (
                                            <tr key={index}>
                                                <td className="small"><MonthNameGiver month={item.payable} /> {item.year}</td>
                                                <td className="small"><FormattedAmount amount={parseFloat(EncryptionService.decrypt(item.amount_planned, item.iv))}/></td>
                                                <td className="small"><FormattedAmount amount={parseFloat(EncryptionService.decrypt(item.amount_paid, item.iv))}/></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="fixed-buttons-container">
                        <button
                            className={`btn btn-secondary`}
                            type="button"
                            onClick={() => window.history.back()} // Replace '123' with the actual itemId
                        >
                            <i className="bi bi-arrow-return-left"></i>
                        </button>
                        <button
                            className={`btn btn-success`}
                            type="button"
                            onClick={() => setEditMode(true)}
                        >
                            <i className="bi bi-pencil"></i>
                        </button>
                        <button
                            className={`btn btn-danger`}
                            type="button"
                            onClick={() => setDeleteMode(true)} // Replace '123' with the actual itemId
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </div>
                </div>
            ) : editMode && !deleteMode ? (
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4 className="mt-4 mb-2">{i18n.t('edit')}: {EncryptionService.decrypt(currentFinancialPosition[viewConfig.defaultWidgets.posInfo[0].key], currentFinancialPosition.iv)}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <CreateEditForm
                                formFields={formFieldsConfig}
                                initialValues={currentFinancialPosition}
                                onFormSubmit={handleFormSubmit}
                                onCancel={() => setEditMode(false)}
                                location={config_id}
                                setChange={setChange}
                                monthlyRemainingIncome={monthlyRemainingIncome}
                                annualRemainingIncome={annualRemainingIncome}
                            />
                        </div>
                    </div>
                </div>
            ) : !editMode && deleteMode ? (
                <div className="container">
                    <div className="row">
                        <h1>{i18n.t('delete')}: {EncryptionService.decrypt(currentFinancialPosition[viewConfig.defaultWidgets.posInfo[0].key], currentFinancialPosition.iv)}</h1>
                        <hr/>
                    </div>
                    <div className="row">
                        <DeleteForm
                            values={currentFinancialPosition}
                            onFormSubmit={handleFormSubmit}
                            onCancel={() => setDeleteMode(false)}
                            setChange={setChange}
                            location_id={config_id}
                        />
                    </div>
                </div>
            ) : null
    );
}

export default DetailViewPage;