import DataBaseDropdown from "../../logical-ui-elements/InputDatabaseDropdown";
import InputDataList from "../../logical-ui-elements/InputDataList";
import InputIconProvider from "../../logical-ui-elements/InputIconProvider";
import InputMonthSelector from "../../logical-ui-elements/input-month-selector/InputMonthSelector";
import { Tooltip } from 'react-tooltip';
import InputCurrency from "../../logical-ui-elements/InputCurrency";
import InputTextField from "../../logical-ui-elements/InputTextField";
import InputRadioButton from "../../logical-ui-elements/InputRadioButton";
import InputSwitch from "../../logical-ui-elements/InputSwitch";
import InputSelect from "../../logical-ui-elements/InputSelect";
import InputUserTags from "../../logical-ui-elements/InputUserTags";

function FieldComponent({ field, formValues, handleChange, userCurrency, t, linkedCheckValue, setLinkedCheckValue, handleCurrency, monthlyRemainingIncome, annualRemainingIncome }) {

    switch (field.fieldType) {
        case 'radio':
            return (
                <InputRadioButton
                    fieldConfig={field}
                    callback={(e) => handleChange(e)}
                    vals={formValues[field.key] || field.defaultValue }
                />
            );
        case 'select':
            return (
                    <InputSelect
                        field={field}
                        formValues={formValues}
                        callback={(e) => handleChange(e)}
                    />
            );
        case 'databasedDropdown':
            return (
                <DataBaseDropdown
                    apiEndpoint={field.apiEndpoint}
                    value={formValues[field.key] || ''}
                    valueLabel={field.valueLabel}
                    valueLabelAddedInfo={field.valueLabelAddedInfo}
                    defaultValue={t(field.placeholder)}
                    callback={(e) => handleChange(e)}
                    name={field.key}
                    label={t(field.label)}
                />
            );
        case 'dataList':
            return (
                <InputDataList
                    fieldConfig={field}
                    vals={formValues[field.key] || ""}
                    callback={(e) => handleChange(e)}
                />
            );
        case 'iconProvider':
            return (
                <InputIconProvider
                    fieldConfig={field}
                    vals={formValues[field.key] || ''}
                    callback={handleChange}
                />
            );
        case 'user_tags':
            return (
                <InputUserTags
                    fieldConfig={field}
                    vals={formValues[field.key]}
                    callback={handleChange}
                />
            );
        case 'monthSelector':
            return (
                <InputMonthSelector
                    fieldConfig={field}
                    vals={formValues[field.key]}
                    callback={handleChange}
                />
            );
        case 'currency':
            return (
                <InputCurrency
                    fieldKey={field.key}
                    required={field.required}
                    tooltip={field.tooltip}
                    label={field.label}
                    value={formValues[field.key]}
                    callback={handleCurrency}
                    userCurrency={userCurrency}
                    disabled={false}
                    isLinked={field.isLinked || false}
                    linkedCheckValue={linkedCheckValue}
                    setLinkedCheckValue={setLinkedCheckValue}
                    monthlyRemainingIncome={monthlyRemainingIncome}
                    annualRemainingIncome={annualRemainingIncome}
                />
            );
        case 'percent':
            return (
                <div className="mb-3 percent-input">
                    <label htmlFor={field.key} className="form-label">{t(field.label)}</label>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${field.key}`}></i>
                    <Tooltip id={`tooltip-${field.key}`} place="right" effect="solid">
                        {t(field.tooltip)}
                    </Tooltip>
                    <div className="input-group">
                        <span className="input-group-text">%</span>
                        <input
                            type="number"
                            className="form-control"
                            id={field.key}
                            name={field.key}
                            value={formValues[field.key] || 0}
                            onChange={handleChange}
                            placeholder={`0.00`}
                            required={field.required}
                            step="0.01"
                        />
                    </div>
                </div>
            );
        case ("switch"): {
            return (
                <InputSwitch
                    fieldConfig={field}
                    vals={formValues[field.key]}
                    callback={handleChange}
                />
            );
        }
        default:
            return (
                <InputTextField
                    fieldConfig={field}
                    vals={formValues[field.key]}
                    callback={handleChange}
                />
            );
    }
}

export default FieldComponent;