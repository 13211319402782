import React from "react";
import i18n from "../../translations/i18n";
import {Tooltip} from "react-tooltip";

function InputRadioButton({ fieldConfig, vals, callback }) {
    const {t} = i18n;

    return (
        <div className="mb-3">
            {fieldConfig.label && (
                <label htmlFor={fieldConfig.key} className={`form-label ${fieldConfig.required ? 'fw-bold' : ''}`}>{t(fieldConfig.label)}{fieldConfig.required ? <span className='fw-bold'>*</span> : ''}</label>
            )}
            {fieldConfig.tooltip && (
                <>
                    <i className="bi bi-question-circle ms-1" data-tooltip-id={`tooltip-${fieldConfig.key}`}></i>
                    <Tooltip id={`tooltip-${fieldConfig.key}`} place="right" effect="solid">
                        {t(fieldConfig.tooltip)}
                    </Tooltip>
                </>
            )}
            {fieldConfig.options.map((option, index) => (
                <div className="form-check">
                    <input
                        className="form-check-input"
                        key={index}
                        type="radio"
                        id={option.key}
                        name={fieldConfig.key}
                        value={option.key}
                        checked={vals === option.key}
                        onChange={(e) => {
                            callback({name: fieldConfig.key, value: e.target.value});
                        }}
                    />
                    <label
                        className={`form-check-label ${vals === option.key ? 'active' : ''}`}
                        htmlFor={option.key}>
                        {t(option.key)}
                    </label>
                </div>
            ))}
        </div>
    )
}

export default InputRadioButton;