// frontend/src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "login-message-continue-with-existing-data": "Continue with existing data",
          "login-button-open-other-budget": "Open other budget",
          "login-button-continue-existing-data": "Continue with existing data",
          "login-button-login": "Login",
          "login-button-start-new": "Start a new budget",
          "login-message-existing-data": "We found an existing budget in your app's local storage!",
          "login-message-overwritten-unsaved-lost": "In case of another file upload, your unsaved changes will be overwritten and lost.",
          "user_settings": "User Settings",
          "login-label-password": "Password",
          "login-label-username": "Username",
          "login-label-upload-file": "Upload file",
          "username": "Username",
          "payday": "Payday",
          "location": "Location",
          "language": "Language",
          "default_currency": "Default Currency",
          "password": "Password",
          "confirm_password": "Confirm Password",
          "update_settings": "Update Settings",
          "monthly-remaining-income": "Monthly Remaining Income",
          "annual-remaining-income": "Annual Remaining Income",
          "financial-positions-placeholder-category": "Type a new category or choose existing one",
          'no-data-available': "No data available",
          "no-data-message": "It seems you have not created any {{pageContent}} yet.",
          'add-button-add': "Add {{title}}",
          'budget-check-no-due-bills': "No due bills",
          "budget-check-final-view-unexpected-transactions": "Unexpected Transactions",
          "registration-process-onboarding-welcome": "Welcome to ecBudget!",
          'registration-error': "Error registering user",
          "registration-process-onboarding-description": "First off, thank you for choosing ecBudget! Since budgets usually involve personal and sensitive data it is important to point out, how this app treats your data:",
          "registration-process-onboarding-local-storage": "ecBudget stores your data locally on your browser only, there is no central database holding any personal data of yours. ecBudget will give you the option to encrypt your local data in order to make it more secure. ecBudget cannot restore your data once lost. ecBudget will indicate unsaved changes in order to let you make frequent backups. The file will be downloaded to your device's download folder by default. Where you store the file does not matter, you can re-use it on any other device. Hence it is not recommended to share copies of this file with any unknown third-parties. Keeping the data safely and treating it responsibly is in the full responsibility of you as the user.",
          "registration-process-onboarding-terms": "By clicking the button below, you agree to have understood these current terms.",
          "registration-process-onboarding-okay": "Okay, I get it",
          "onboarding": "Onboarding",
          "german": "Deutsch",
          "italian": "Italiano",
          "french": "Français",
          "english": "English",
          "albanian": "Albanian",
          "chf": "CHF",
          "eur": "EUR",
          "usd": "USD",
          "budget-check-date": "Budget Check Date",
          "budget-check-help": "The day of the month you want to check your budget. (Used for reminders)",
          "encryption": "Encryption",
          "encryption-description": "You can encrypt the contents of your stored data with a password. This password cannot be reset by the app and is the only key to access your budget.",
          "encryption-mode": "I want to encrypt my data",
          "confirm-password": "Confirm Password",
          "password-criteria": "Password must contain:",
          "password-length": "At least 8 characters",
          "password-uppercase": "At least one uppercase letter",
          "password-lowercase": "At least one lowercase letter",
          "password-number": "At least one number",
          "password-special-char": "At least one special character",
          "register": "Register",
          'budget-check-no-due-bills-description': "There are no bills due for this month.",
          'password-match-error': "Passwords do not match.",
          "password_criteria": {
            "length": "At least 10 characters",
            "uppercase": "At least one uppercase letter",
            "lowercase": "At least one lowercase letter",
            "number": "At least one number",
            "special": "At least one special character"
          },
          "password_error": "Password does not meet criteria.",
          "confirm_password_error": "Passwords do not match.",
          "settings_updated": "Settings updated successfully",
          "settings_update_error": "Error updating settings",
          "financial_positions": "Budget Positions",
          "accounts": "Accounts",
          "budget_checks": "Budget Checks",
          "add_new": "Add New",
          "sum": "Sum",
          "monthly": "Monthly",
          "january": "January",
          "february": "February",
          "march": "March",
          "april": "April",
          "may": "May",
          "june": "June",
          "july": "July",
          "august": "August",
          "september": "September",
          "october": "October",
          "november": "November",
          "december": "December",
          "rest": "Rest",
          "data_updated_successfully": "Data has been updated successfully",
          "edit": "Edit",
          "delete": "Delete",
          "insights": "Insights",
          "select_type": "Select Type",
          "list_separated_by_space": "(list separated by empty space)",
          "submit": "Submit",
          "income": "Income",
          "fixed_costs": "Fixed Costs",
          "variable_costs": "Variable Costs",
          "savings": "Savings",
          "wealth": "Wealth",
          "dept": "Dept",
          "no_results": "No results found :-(",
          "no_results_description": "There are no results for the selected filter. Please try again with different filter settings.",
          "due": "Due",
          "income_ratio_description": "This percentage shows how much of your annual income is used for the selected category.",
          "title_income_ratio": "Income Ratio",
          "category_ratio_description": "This percentage shows how much this position's value is compared to the other positions in this category.",
          "cancel": "Cancel",
          "assets": "Assets",
          "provision": "Provision",
          "upcoming-bills": "Upcoming Bills",
          "top_expenses": "Top 3 Expenses",
          "no_data_available": "No data available",
          "welcome_info_title": "Welcome",
          "net_wealth_title": "Net Wealth*",
          "net_wealth_description": "*All your savings, assets, etc. minus your debts.",
          "unallocated_income": "Unallocated Income",
          "unallocated_income_description": "*What remains after deducting all positions from your income in the budget.",
          "widget_monthly_inflow_account_allocation": "Monthly Account Inflow",
          "widget_monthly_outflow_account_allocation": "Monthly Account Outflow",
          "budget-check-final-view-monthly-spending": "Monthly Spending",
          "budget-check-page-title": "Budget check for ",
          "budget_check_category_income": "Income",
          "budget_check_category_fixed_costs": "Fixed costs",
          "budget_check_category_variable_costs": "Variable costs",
          "budget_check_category_savings": "Savings",
          "budget-check-status-closed": "Closed",
          "budget-check-status-open": "Ready",
          "budget-check-table-header-position": "Position",
          "budget-check-table-header-account": "Account",
          "budget-check-table-header-budgeted": "Budgeted",
          "budget-check-table-header-effective": "Effective amount",
          "budget-check-table-header-paid-received": "Logged",
          "budget-check-item-bill-due": "The bill for this position is due",
          "budget-check-next-button": "Next",
          "budget-check-next-button-disabled": "Check all positions first",
          "budget-check-previous-button": "Previous",
          "budget-check-save-button": "Save",
          "budget-check-table-footer-total": "Total",
          "budget-check-table-header-paid-from-account": "Paid from account...",
          "transaction-section-all-checked": "All transactions have been checked",
          "transaction-section-all-checked-description": "All positions have been checked. The total amount of {{type}} transactions for this month results to:",
          "transaction-section-hide-checked": "Hide Checked Transactions",
          "transaction-section-show-checked": "Show Checked Transactions",
          "transaction-search-checked-transactions": "Checked Transactions",
          "transaction-search-checked-transactions-description": "Here you find all transactions from the category, if you need to adapt one, you can uncheck them here.",
          'transaction-search-uncheck-transaction': "Uncheck Transaction",
          "transaction-search-no-transactions-found": "No transactions found",
          'budget-check-final-view-income-transactions': "Income Transactions",
          'budget-check-final-view-regular-transactions': "Regular Transactions",
          'budget-check-final-view-irregular-transactions': "Irregular Transactions",
          'budget-check-final-view-savings-transactions': "Savings Transactions",
          'budget-check-process-main-title': "Budget Check",
          "pre-logged": "Pre-logged",
          "changed": "Changed",
          "budget-check-process-next": "Next",
          "budget-check-final-view-your-results": "Budget Check Results",
          "budget-check-final-view-abbreviation-from-budget": "Budget Abbreviation",
          "budget-check-final-view-months-income": "This month's income",
          "budget-check-final-view-months-provisions": "Provisions Made",
          "budget-check-final-view-bills-paid": "Bills paid!",
          "budget-check-final-view-unplanned-expenses": "Unplanned expenses",
          "budget-check-final-view-total-savings": "Total Savings",
          "budget-check-final-view-budget-score": "Your Budget Score",
          "budget-check-process-save": "Save",
          "budget-check-process-previous": "Previous",
          'budget-check-final-view-monthly-bills-paid': "Monthly bills paid",
          "navigation-menu-tooltip-dashboard": "Dashboard",
          "navigation-menu-tooltip-financial-positions": "Financial Positions",
          "navigation-menu-tooltip-accounts": "Accounts",
          "navigation-menu-tooltip-budget-checks": "Budget Checks",
          "navigation-menu-tooltip-user-profile": "User Profile",
          "navigation-menu-tooltip-save": "Save",
          "budget-check-table-header-date": "Date",
          "budget-check-table-header-budget-score": "Budget Score",
          "budget-check-table-header-deviation-budget": "Deviation from Budget",
          "budget-check-table-header-status": "Status",
          "budget-check-table-view-title": "Budget Checks",
          'budget-check-add-unexpected-transaction': "Add unexpected transaction",
          'create-edit-form-required-fields': "Required Fields",
          'create-edit-form-optional-fields': "Optional Fields",
          "dashboard-widget-top-spending-categories": "Top Spending Categories",
          "dashboard-widget-top-three-expenses": "Top 3 Expenses",
          "expense": "Expense",
          "navigation-menu-tooltip-budget-checks-tip": "Budget Check",
          "navigation-menu-tooltip-financial-positions-tip": "Budget Positions",
          "budget-check-provision-table-view-title": "Provisions and monthly bills",
          "budget-check-definitive-expenses-table-view-title": "Large bills and unexpected expenses",
          "budget-check-partial-bill": "Partial bill",
          "budget-check-total-budgeted-bill": "Total budgeted for this bill",
          "big-bills": "Big Bills",
          "monthly-spending": "Monthly Spending",
          "unplanned-expense": "Unplanned Expense",
          "table-has-no-values": "This table has no values",
          "monthly_spending_check": "Monthly Spending Check",
          "financial-positions": "Financial Positions",
          "budget-checks": "Budget Checks",
          "no-changes": "No changes",
          "unsaved-changes": "Unsaved changes",
          "profile": "Profile",
          "dashboard": "Dashboard",
          'dashboard-widget-last-save': "Last save: ",
          "add-missing-values-to-template": "Add the missing values to the template",
          "template-question-type-monthly-amount": "Indicate Monthly amount",
          "template-question-type-annual-amount": "Indicate Annual amount",
          "template-question-type-monthly-payable": "Choose the month you pay or receive the amount",
          "template-name-one-person-income": "One person income (name)",
          "template-name-bonus-income": "Bonus income",
          "template-name-irregular-income": "Irregular income",
          "template-name-rent": "Rent",
          "template-name-rental-extra-costs": "Rental extra costs",
          "template-name-streaming-subscription": "Streaming subscription",
          "template-name-electricity": "Electricity",
          "template-name-mobile-internet-tv": "Mobile, internet, TV",
          "template-name-cantonal-tax": "Cantonal tax",
          "template-name-governmental-tax": "Governmental tax",
          "template-name-fire-department-tax": "Fire department tax",
          "template-name-health-insurance": "Health insurance",
          "template-name-additional-insurance": "Additional insurance",
          "template-name-life-insurance": "Life insurance",
          "template-name-blocked-bank-savings": "Blocked bank savings",
          "template-name-liability-insurance": "Liability insurance",
          "template-name-legal-protection-insurance": "Legal protection insurance",
          "template-name-public-transport-subscription": "Public transport subscription",
          "template-name-public-transport-tickets": "Public transport tickets",
          "template-name-bike": "Bike",
          "template-name-e-bike": "E-bike",
          "template-name-car": "Car",
          "template-name-motorcycle": "Motorcycle",
          "template-name-moto-tax": "Moto tax",
          "template-name-Fuel": "Fuel",
          "template-name-moto-insurance": "Moto insurance",
          "template-name-moto-maintenance": "Moto maintenance",
          "template-name-moto-fees": "Moto fees",
          "template-name-moto-amortisation": "Moto amortisation",
          "template-name-moto-leasing": "Moto leasing",
          "template-name-media-subscriptions": "Media subscriptions",
          "template-name-association-contributions": "Association contributions",
          "template-name-membership-contributions": "Membership contributions",
          "template-name-school-money": "School money",
          "template-name-continuous-education-costs": "Continuous education costs",
          "template-name-electronics": "Electronics",
          "template-name-hobby": "Hobby",
          "template-name-child-care": "Child care",
          "template-name-dept-yield-costs": "Dept yield costs",
          "template-name-dept-down-payments": "Dept down payments",
          "template-name-alimony-payments": "Alimony payments",
          "template-name-household-costs": "Household costs",
          "template-name-food-drinks": "Food & drinks",
          "template-name-guests": "Guests",
          "template-name-alcoholic-drinks": "Alcoholic drinks",
          "template-name-pet": "Pet",
          "template-name-clothes": "Clothes",
          "template-name-pocket-money": "Pocket money",
          "template-name-hairdresser": "Hairdresser",
          "template-name-eating-out": "Eating out",
          "template-name-going-out": "Going out",
          "template-name-provisions": "Provisions",
          "template-name-eye-check-up": "Eye check-up",
          "template-name-teeth-check-up": "Teeth check-up",
          "template-name-therapy": "Therapy",
          "template-name-veterinarian": "Veterinarian",
          "template-name-presents": "Presents",
          "template-name-donations": "Donations",
          "template-name-school-camps": "School camps",
          "template-name-school-costs": "School costs",
          "template-name-vacation": "Vacation",
          "template-name-etf-savings-plan": "ETF savings plan",
          "template-name-cash-savings": "Cash savings",
          "template-name-asset-savings": "Asset savings",
          "template-name-serafe-single-household": "Serafe single household",
          "template-name-replacement-for-military-service": "Replacement for military service",
          "template-description-replacement-for-military-service": "Replacement tax for military service",
          "template-description-one-person-income": "Income from a single person",
          "template-description-bonus-income": "Additional income from bonuses or 13th salary",
          "template-description-irregular-income": "Income from irregular sources (e.g. self-employment, side hustle, etc.)",
          "template-description-rent": "Monthly rent payments",
          "template-description-rental-extra-costs": "Extra costs for renting a flat, like heating, water, etc.",
          "template-description-serafe-single-household": "Serafe fee for single households, is a mandatory fee for TV and radio",
          "template-description-streaming-subscription": "Subscription for any kind of streaming services like Netflix, Disney+, etc.",
          "template-description-electricity": "Electricity bill coming from your electricity provider",
          "template-description-mobile-internet-tv": "Mobile, internet, and TV bills",
          "template-description-cantonal-tax": "Provisions for cantonal tax rates and final bills",
          "template-description-governmental-tax": "Provisions for governmental tax rates and final bills",
          "template-description-fire-department-tax": "Provisions for fire department tax payments",
          "template-description-health-insurance": "Health insurance base premiums (mandatory for everyone)",
          "template-description-additional-insurance": "Additional health insurance coverage",
          "template-description-life-insurance": "Life insurance premiums",
          "template-description-blocked-bank-savings": "Blocked bank savings for retirement",
          "template-description-liability-insurance": "Liability insurance premiums",
          "template-description-legal-protection-insurance": "Legal protection insurance premiums",
          "template-description-public-transport-subscription": "Subscription for public transport",
          "template-description-public-transport-tickets": "Budget position for single public transport tickets.",
          "template-description-bike": "Expenses for bike maintenance and other eventualities",
          "template-description-e-bike": "Expenses for e-bike maintenance",
          "template-description-car": "All in one position for car maintenance, fuel, insurance, etc.",
          "template-description-motorcycle": "All in one position for motorcycle maintenance, fuel, insurance, etc.",
          "template-description-moto-tax": "Tax payments for car or motorcycle",
          "template-description-Fuel": "Fuel expenses",
          "template-description-moto-insurance": "Insurance premiums for car or motorcycle",
          "template-description-moto-maintenance": "Maintenance costs for car or motorcycle",
          "template-description-moto-fees": "Other fees and costs for car and motorcycle",
          "template-description-moto-amortisation": "Amortization costs for car and motorcycle",
          "template-description-moto-leasing": "Leasing payments for car and motorcycle",
          "template-description-media-subscriptions": "Subscriptions for media services",
          "template-description-association-contributions": "Contributions to associations",
          "template-description-membership-contributions": "Membership contributions (e.g. sports clubs, gym, etc.)",
          "template-description-school-money": "School-related expenses like lunch, cafeteria, etc.",
          "template-description-continuous-education-costs": "Provisions or costs for continuous education",
          "template-description-electronics": "Expenses for electronics",
          "template-description-hobby": "Expenses for hobbies",
          "template-description-child-care": "Child care expenses",
          "template-description-dept-yield-costs": "Costs for dept yield",
          "template-description-dept-down-payments": "Down payments for dept",
          "template-description-alimony-payments": "Alimony payments",
          "template-description-household-costs": "Household-related expenses",
          "template-description-food-drinks": "Expenses for food and drinks",
          "template-description-guests": "Expenses for guests",
          "template-description-alcoholic-drinks": "Expenses for alcoholic drinks",
          "template-description-pet": "Expenses for pet care",
          "template-description-clothes": "Expenses for clothes",
          "template-description-pocket-money": "Pocket money for personal expenses throughout the day",
          "template-description-hairdresser": "Expenses for hairdresser",
          "template-description-eating-out": "Expenses for eating out with friends or family",
          "template-description-going-out": "Expenses for going out and partying with friends or family",
          "template-description-provisions": "Provisions and savings",
          "template-description-eye-check-up": "Expenses for regular eye check-ups",
          "template-description-teeth-check-up": "Expenses for regular teeth check-up",
          "template-description-therapy": "Expenses for therapy",
          "template-description-veterinarian": "Expenses for veterinarian",
          "template-description-presents": "Expenses for presents",
          "template-description-donations": "Expenses for donations",
          "template-description-school-camps": "Expenses for school camps",
          "template-description-school-costs": "School-related costs like books, materials, etc.",
          "template-description-vacation": "Savings for vacation",
          "template-description-cash-savings": "Cash savings",
          "template-description-etf-savings-plan": "ETF savings plan",
          "template-name-house-heating": "House Heating",
          "template-description-house-heating": "House Heating",
          "template-name-house-tax": "House Tax",
          "template-description-house-tax": "Tax for the house",
          "template-name-house-mortgage": "House Mortgage",
          "template-description-house-mortgage": "Mortgage rate payments",
          "template-name-house-insurance": "House Insurance",
          "template-description-house-insurance": "House Insurance",
          "template-name-house-electricity": "House Electricity",
          "template-description-house-electricity": "House Electricity",
          "house-owner": "House Owner",
          "rent": "Rent",
          "fees-and-taxes": "Fees and Taxes",
          "subscriptions": "Subscriptions",
          "electricity": "Electricity",
          "communication": "Communication",
          "insurance": "Insurance",
          "retirement-provisions": "Retirement Provisions",
          "transportation": "Transportation",
          "personal-transport": "Personal Transport",
          "various": "Various",
          "personal-expenses": "Personal Expenses",
          "child-costs": "Child Costs",
          "household": "Household",
          "pet": "Pet",
          "provisions": "Provisions",
          "health": "Health",
          "donations": "Donations",
          "cash-savings": "Cash Savings",
          "salary": "salary",
          "net": "net",
          "bonus": "bonus",
          "irregular": "irregular",
          "fixed-costs": "fixed-costs",
          "cold-rent": "cold-rent",
          "extra-costs": "extra-costs",
          "variable-costs": "variable-costs",
          "water": "water",
          "heating": "heating",
          "serafe": "serafe",
          "state-fee": "state-fee",
          "media": "media",
          "streaming": "streaming",
          "apps": "apps",
          "subscription": "subscription",
          "power": "power",
          "mobile": "mobile",
          "internet": "internet",
          "tv": "tv",
          "connectivity": "connectivity",
          "cantonal": "cantonal",
          "tax": "tax",
          "governmental": "governmental",
          "fire-department": "fire-department",
          "military": "military",
          "replacement": "replacement",
          "life": "life",
          "retirement": "retirement",
          "self-employment": "self-employment",
          "house-ownership": "house-ownership",
          "bank-savings": "bank-savings",
          "investment": "investment",
          "liability": "liability",
          "legal": "legal",
          "protection": "protection",
          "public-transport": "public-transport",
          "tickets": "tickets",
          "bike": "bike",
          "e-bike": "e-bike",
          "leasing": "leasing",
          "fuel": "fuel",
          "maintenance": "maintenance",
          "fees": "fees",
          "amortisation": "amortisation",
          "media-subscriptions": "media-subscriptions",
          "association": "association",
          "contributions": "contributions",
          "membership": "membership",
          "school": "school",
          "money": "money",
          "continuous-education": "continuous-education",
          "education": "education",
          "hobby": "hobby",
          "child-care": "child-care",
          "yield": "yield",
          "costs": "costs",
          "down-payments": "down-payments",
          "amortization": "amortization",
          "alimony": "alimony",
          "payments": "payments",
          "daily-expenses": "daily-expenses",
          "food": "food",
          "groceries": "groceries",
          "drinks": "drinks",
          "guests": "guests",
          "alcoholic-drinks": "alcoholic-drinks",
          "going-out": "going-out",
          "party": "party",
          "bars": "bars",
          "eyes": "eyes",
          "check-up": "check-up",
          "doctor": "doctor",
          "teeth": "teeth",
          "therapy": "therapy",
          "veterinarian": "veterinarian",
          "pet-food": "pet-food",
          "pet-care": "pet-care",
          "presents": "presents",
          "camps": "camps",
          "vacation": "vacation",
          "mortgage": "mortgage",
          'irregular-transactions': "Irregular Transactions",
          'irregular-transactions-title': "Irregular Transactions",
          'no-data-irregular-transactions-title': "No irregular transactions for the selected year.",
          'no-data-irregular-transactions-description': "There are no irregular transactions for the selected year. Irregular budgeted positions will only be shown for the current of future year. Please try again with a different year or create your first budget positions.",
          'overdue': "Overdue",
          'needs-attention': "Needs Attention",
          'bill-paid': "Bill Paid",
          'planned-month': "Planned Month",
          'paid': "Paid",
        }
      },
      de: {
        translation: {
            "login-message-continue-with-existing-data": "Mit vorhandenen Daten fortfahren",
            "login-button-open-other-budget": "Anderes Budget öffnen",
            "login-button-continue-existing-data": "Mit vorhandenen Daten fortfahren",
            "login-button-login": "Anmelden",
            "login-button-start-new": "Neues Budget starten",
            "login-message-existing-data": "Wir haben ein vorhandenes Budget im lokalen Speicher Ihrer App gefunden!",
            "login-message-overwritten-unsaved-lost": "Bei einem weiteren Datei-Upload werden Ihre ungespeicherten Änderungen überschrieben und gehen verloren.",
            "user_settings": "Benutzereinstellungen",
            "login-label-password": "Passwort",
            "login-label-username": "Benutzername",
            "login-label-upload-file": "Datei hochladen",
            "username": "Benutzername",
            "payday": "Zahltag",
            "location": "Standort",
            "language": "Sprache",
            "default_currency": "Standardwährung",
            "password": "Passwort",
            "confirm_password": "Passwort bestätigen",
            "update_settings": "Einstellungen aktualisieren",
            "monthly-remaining-income": "Monatlich verbleibendes Einkommen",
            "annual-remaining-income": "Jährlich verbleibendes Einkommen",
            "financial-positions-placeholder-category": "Geben Sie eine neue Kategorie ein oder wählen Sie eine vorhandene aus",
            "no-data-available": "Keine Daten verfügbar",
            "no-data-message": "Es scheint, dass Sie noch keine {{pageContent}} erstellt haben.",
            "add-button-add": "{{title}} hinzufügen",
            "budget-check-no-due-bills": "Keine fälligen Rechnungen",
            "budget-check-final-view-unexpected-transactions": "Unerwartete Transaktionen",
            "registration-process-onboarding-welcome": "Willkommen bei ecBudget!",
            "registration-error": "Fehler bei der Registrierung des Benutzers",
            "registration-process-onboarding-description": "Zunächst einmal vielen Dank, dass Sie sich für ecBudget entschieden haben! Da Budgets in der Regel persönliche und sensible Daten enthalten, ist es wichtig zu erläutern, wie diese App mit Ihren Daten umgeht:",
            "registration-process-onboarding-local-storage": "ecBudget speichert Ihre Daten nur lokal in Ihrem Browser, es gibt keine zentrale Datenbank, die Ihre persönlichen Daten speichert. ecBudget bietet Ihnen die Möglichkeit, Ihre lokalen Daten zu verschlüsseln, um sie sicherer zu machen. ecBudget kann Ihre Daten nicht wiederherstellen, wenn sie verloren gehen. ecBudget wird ungespeicherte Änderungen anzeigen, damit Sie häufige Backups erstellen können. Die Datei wird standardmäßig in den Download-Ordner Ihres Geräts heruntergeladen. Wo Sie die Datei speichern, spielt keine Rolle, Sie können sie auf jedem anderen Gerät wiederverwenden. Es wird daher nicht empfohlen, Kopien dieser Datei mit unbekannten Dritten zu teilen. Die sichere Aufbewahrung und der verantwortungsvolle Umgang mit den Daten liegt in Ihrer vollen Verantwortung als Benutzer.",
            "registration-process-onboarding-terms": "Durch Klicken auf die Schaltfläche unten erklären Sie sich damit einverstanden, diese aktuellen Bedingungen verstanden zu haben.",
            "registration-process-onboarding-okay": "Okay, ich verstehe",
            "onboarding": "Einführung",
            "german": "Deutsch",
            "italian": "Italienisch",
            "french": "Französisch",
            "english": "Englisch",
            "albanian": "Albanisch",
            "chf": "CHF",
            "eur": "EUR",
            "usd": "USD",
            "budget-check-date": "Budgetprüfung Datum",
            "budget-check-help": "Der Tag des Monats, an dem Sie Ihr Budget überprüfen möchten. (Wird für Erinnerungen verwendet)",
            "encryption": "Verschlüsselung",
            "encryption-description": "Sie können den Inhalt Ihrer gespeicherten Daten mit einem Passwort verschlüsseln. Dieses Passwort kann von der App nicht zurückgesetzt werden und ist der einzige Schlüssel zum Zugriff auf Ihr Budget.",
            "encryption-mode": "Ich möchte meine Daten verschlüsseln",
            "confirm-password": "Passwort bestätigen",
            "password-criteria": "Passwort muss enthalten:",
            "password-length": "Mindestens 8 Zeichen",
            "password-uppercase": "Mindestens einen Großbuchstaben",
            "password-lowercase": "Mindestens einen Kleinbuchstaben",
            "password-number": "Mindestens eine Zahl",
            "password-special-char": "Mindestens ein Sonderzeichen",
            "register": "Registrieren",
            "budget-check-no-due-bills-description": "Für diesen Monat sind keine Rechnungen fällig.",
            "password-match-error": "Passwörter stimmen nicht überein.",
            "password_criteria": {
              "length": "Mindestens 10 Zeichen",
              "uppercase": "Mindestens einen Großbuchstaben",
              "lowercase": "Mindestens einen Kleinbuchstaben",
              "number": "Mindestens eine Zahl",
              "special": "Mindestens ein Sonderzeichen"
            },
            "password_error": "Passwort erfüllt die Kriterien nicht.",
            "confirm_password_error": "Passwörter stimmen nicht überein.",
            "settings_updated": "Einstellungen erfolgreich aktualisiert",
            "settings_update_error": "Fehler beim Aktualisieren der Einstellungen",
            "financial_positions": "Budgetpositionen",
            "accounts": "Konten",
            "budget_checks": "Budgetprüfungen",
            "add_new": "Neu hinzufügen",
            "sum": "Summe",
            "monthly": "Monatlich",
            "january": "Januar",
            "february": "Februar",
            "march": "März",
            "april": "April",
            "may": "Mai",
            "june": "Juni",
            "july": "Juli",
            "august": "August",
            "september": "September",
            "october": "Oktober",
            "november": "November",
            "december": "Dezember",
            "rest": "Rest",
            "data_updated_successfully": "Daten wurden erfolgreich aktualisiert",
            "edit": "Bearbeiten",
            "delete": "Löschen",
            "insights": "Einblicke",
            "select_type": "Typ auswählen",
            "list_separated_by_space": "(Liste durch Leerzeichen getrennt)",
            "submit": "Einreichen",
            "income": "Einkommen",
            "fixed_costs": "Fixkosten",
            "variable_costs": "Variable Kosten",
            "savings": "Ersparnisse",
            "wealth": "Vermögen",
            "dept": "Schulden",
            "no_results": "Keine Ergebnisse gefunden :-(",
            "no_results_description": "Für den ausgewählten Filter wurden keine Ergebnisse gefunden. Bitte versuchen Sie es erneut mit anderen Filtereinstellungen.",
            "due": "Fällig",
            "income_ratio_description": "Dieser Prozentsatz zeigt, wie viel Ihres Jahreseinkommens für die ausgewählte Kategorie verwendet wird.",
            "title_income_ratio": "Einkommensverhältnis",
            "category_ratio_description": "Dieser Prozentsatz zeigt, wie viel der Wert dieser Position im Vergleich zu den anderen Positionen in dieser Kategorie beträgt.",
            "cancel": "Abbrechen",
            "assets": "Vermögenswerte",
            "provision": "Rückstellung",
            "upcoming-bills": "Bevorstehende Rechnungen",
            "top_expenses": "Top 3 Ausgaben",
            "no_data_available": "Keine Daten verfügbar",
            "welcome_info_title": "Willkommen",
            "net_wealth_title": "Nettovermögen*",
            "net_wealth_description": "*All Ihre Ersparnisse, Vermögenswerte usw. abzüglich Ihrer Schulden.",
            "unallocated_income": "Nicht zugewiesenes Einkommen",
            "unallocated_income_description": "*Was nach Abzug aller Positionen von Ihrem Einkommen im Budget übrig bleibt.",
            "widget_monthly_inflow_account_allocation": "Monatlicher Kontozufluss",
            "widget_monthly_outflow_account_allocation": "Monatlicher Kontoabfluss",
            "budget-check-final-view-monthly-spending": "Monatliche Ausgaben",
            "budget-check-page-title": "Budgetprüfung für ",
            "budget_check_category_income": "Einkommen",
            "budget_check_category_fixed_costs": "Fixkosten",
            "budget_check_category_variable_costs": "Variable Kosten",
            "budget_check_category_savings": "Ersparnisse",
            "budget-check-status-closed": "Geschlossen",
            "budget-check-status-open": "Bereit",
            "budget-check-table-header-position": "Position",
            "budget-check-table-header-account": "Konto",
            "budget-check-table-header-budgeted": "Budgetiert",
            "budget-check-table-header-effective": "Effektiver Betrag",
            "budget-check-table-header-paid-received": "Erfasst",
            "budget-check-item-bill-due": "Die Rechnung für diese Position ist fällig",
            "budget-check-next-button": "Weiter",
            "budget-check-next-button-disabled": "Überprüfen Sie zuerst alle Positionen",
            "budget-check-previous-button": "Zurück",
            "budget-check-save-button": "Speichern",
            "budget-check-table-footer-total": "Gesamt",
            "budget-check-table-header-paid-from-account": "Bezahlt von Konto...",
            "transaction-section-all-checked": "Alle Transaktionen wurden überprüft",
            "transaction-section-all-checked-description": "Alle Positionen wurden überprüft. Die Gesamtsumme der {{type}} Transaktionen für diesen Monat beträgt:",
            "transaction-section-hide-checked": "Überprüfte Transaktionen ausblenden",
            "transaction-section-show-checked": "Überprüfte Transaktionen anzeigen",
            "transaction-search-checked-transactions": "Überprüfte Transaktionen",
            "transaction-search-checked-transactions-description": "Hier finden Sie alle Transaktionen aus der Kategorie. Wenn Sie eine anpassen müssen, können Sie sie hier abwählen.",
            "transaction-search-uncheck-transaction": "Transaktion abwählen",
            "transaction-search-no-transactions-found": "Keine Budgetposten gefunden",
            "budget-check-final-view-income-transactions": "Einkommen",
            "budget-check-final-view-regular-transactions": "Regelmäßige Transaktionen",
            "budget-check-final-view-irregular-transactions": "Unregelmäßige Transaktionen",
            "budget-check-final-view-savings-transactions": "Spartransaktionen",
            "budget-check-process-main-title": "Budgetüberprüfung",
            "pre-logged": "Vorab erfasst",
            "changed": "Geändert",
            "budget-check-process-next": "Weiter",
            "budget-check-final-view-your-results": "Ergebnisse der Budgetprüfung",
            "budget-check-final-view-abbreviation-from-budget": "Budgetabkürzung",
            "budget-check-final-view-months-income": "Einkommen dieses Monats",
            "budget-check-final-view-months-provisions": "Rückstellungen gemacht",
            "budget-check-final-view-bills-paid": "Rechnungen bezahlt!",
            "budget-check-final-view-unplanned-expenses": "Ungeplante Ausgaben",
            "budget-check-final-view-total-savings": "Gesamtersparnisse",
            "budget-check-final-view-budget-score": "Ihr Budget-Score",
            "budget-check-process-save": "Speichern",
            "budget-check-process-previous": "Zurück",
            "budget-check-final-view-monthly-bills-paid": "Monatliche Rechnungen bezahlt",
            'budget-check-add-unexpected-transaction': "Unerwartete Ausgabe hinzufügen",
            "navigation-menu-tooltip-dashboard": "Dashboard",
            "navigation-menu-tooltip-financial-positions": "Budgetpositionen",
            "navigation-menu-tooltip-accounts": "Konten",
            "navigation-menu-tooltip-budget-checks": "Budgetprüfungen",
            "navigation-menu-tooltip-user-profile": "Benutzerprofil",
            "navigation-menu-tooltip-save": "Speichern",
            "budget-check-table-header-date": "Datum",
            "budget-check-table-header-budget-score": "Budget-Score",
            "budget-check-table-header-deviation-budget": "Abweichung vom Budget",
            "budget-check-table-header-status": "Status",
            "budget-check-table-view-title": "Budgetprüfungen",
            "create-edit-form-required-fields": "Pflichtfelder",
            "create-edit-form-optional-fields": "Optionale Felder",
            "dashboard-widget-top-spending-categories": "Top-Ausgabenkategorien",
            "dashboard-widget-top-three-expenses": "Top 3 Ausgaben",
            "expense": "Ausgabe",
            "navigation-menu-tooltip-budget-checks-tip": "Budgetprüfung",
            "navigation-menu-tooltip-financial-positions-tip": "Budgetpositionen",
            "budget-check-provision-table-view-title": "Rückstellungen und monatliche Rechnungen",
            "budget-check-definitive-expenses-table-view-title": "Große Rechnungen und unerwartete Ausgaben",
            "budget-check-partial-bill": "Teilrechnung",
            "budget-check-total-budgeted-bill": "Gesamtbudget für diese Rechnung",
            "big-bills": "Große Rechnungen",
            "monthly-spending": "Monatliche Ausgaben",
            "unplanned-expense": "Ungeplante Ausgabe",
            "table-has-no-values": "Diese Tabelle hat keine Werte",
            "monthly_spending_check": "Monatliche Ausgabenkontrolle",
            "financial-positions": "Budgetpositionen",
            "budget-checks": "Budgetprüfungen",
            "no-changes": "Keine Änderungen",
            "unsaved-changes": "Ungespeicherte Änderungen",
            "profile": "Profil",
            "dashboard": "Dashboard",
            "dashboard-widget-last-save": "Letzte Speicherung: ",
            "add-missing-values-to-template": "Fehlende Werte zur Vorlage hinzufügen",
            "template-question-type-monthly-amount": "Monatlichen Betrag angeben",
            "template-question-type-annual-amount": "Jährlichen Betrag angeben",
            "template-question-type-monthly-payable": "Wählen Sie den Monat, in dem Sie den Betrag zahlen oder erhalten",
          "template-name-one-person-income": "Einkommen einer Person (Name)",
          "template-name-bonus-income": "Bonus Einkommen",
          "template-name-irregular-income": "Unregelmäßiges Einkommen",
          "template-name-rent": "Miete",
          "template-name-rental-extra-costs": "Zusätzliche Mietkosten",
          "template-name-streaming-subscription": "Streaming-Abonnement",
          "template-name-electricity": "Strom",
          "template-name-mobile-internet-tv": "Mobil, Internet, TV",
          "template-name-cantonal-tax": "Kantonssteuer",
          "template-name-governmental-tax": "Staatssteuer",
          "template-name-fire-department-tax": "Feuerwehrsteuer",
          "template-name-health-insurance": "Krankenversicherung",
          "template-name-additional-insurance": "Zusatzversicherung",
          "template-name-life-insurance": "Lebensversicherung",
          "template-name-blocked-bank-savings": "Gesperrte Bankersparnisse",
          "template-name-liability-insurance": "Haftpflichtversicherung",
          "template-name-legal-protection-insurance": "Rechtsschutzversicherung",
          "template-name-public-transport-subscription": "Öffentliches Verkehrsmittel Abonnement",
          "template-name-public-transport-tickets": "Öffentliche Verkehrsmittel Tickets",
          "template-name-bike": "Fahrrad",
          "template-name-e-bike": "E-Bike",
          "template-name-car": "Auto",
          "template-name-motorcycle": "Motorrad",
          "template-name-moto-tax": "Motorradsteuer",
          "template-name-Fuel": "Kraftstoff",
          "template-name-moto-insurance": "Motorradversicherung",
          "template-name-moto-maintenance": "Motorradwartung",
          "template-name-moto-fees": "Motorradgebühren",
          "template-name-moto-amortisation": "Motorradabschreibung",
          "template-name-moto-leasing": "Motorradleasing",
          "template-name-media-subscriptions": "Medienabonnements",
          "template-name-association-contributions": "Vereinsbeiträge",
          "template-name-membership-contributions": "Mitgliedsbeiträge",
          "template-name-school-money": "Schulgeld",
          "template-name-continuous-education-costs": "Kosten für Weiterbildung",
          "template-name-electronics": "Elektronik",
          "template-name-hobby": "Hobby",
          "template-name-child-care": "Kinderbetreuung",
          "template-name-dept-yield-costs": "Schuldenrendite Kosten",
          "template-name-dept-down-payments": "Schuldenanzahlungen",
          "template-name-alimony-payments": "Unterhaltszahlungen",
          "template-name-household-costs": "Haushaltskosten",
          "template-name-food-drinks": "Essen & Getränke",
          "template-name-guests": "Gäste",
          "template-name-alcoholic-drinks": "Alkoholische Getränke",
          "template-name-pet": "Haustier",
          "template-name-clothes": "Kleidung",
          "template-name-pocket-money": "Taschengeld",
          "template-name-hairdresser": "Friseur",
          "template-name-eating-out": "Auswärts essen",
          "template-name-going-out": "Ausgehen",
          "template-name-provisions": "Rückstellungen",
          "template-name-eye-check-up": "Augenuntersuchung",
          "template-name-teeth-check-up": "Zahnuntersuchung",
          "template-name-therapy": "Therapie",
          "template-name-veterinarian": "Tierarzt",
          "template-name-presents": "Geschenke",
          "template-name-donations": "Spenden",
          "template-name-school-camps": "Schullager",
          "template-name-school-costs": "Schulkosten",
          "template-name-vacation": "Urlaub",
          "template-name-etf-savings-plan": "ETF-Sparplan",
          "template-name-cash-savings": "Bargeldersparnisse",
          "template-name-asset-savings": "Vermögensersparnisse",
          "template-name-serafe-single-household": "Serafe Einpersonenhaushalt",
          "template-name-replacement-for-military-service": "Ersatz für Militärdienst",
          "template-description-replacement-for-military-service": "Ersatzsteuer für Militärdienst",
          "template-description-one-person-income": "Einkommen einer einzelnen Person",
          "template-description-bonus-income": "Zusätzliches Einkommen aus Boni oder 13. Gehalt",
          "template-description-irregular-income": "Einkommen aus unregelmäßigen Quellen (z.B. Selbstständigkeit, Nebenjob, etc.)",
          "template-description-rent": "Monatliche Mietzahlungen",
          "template-description-rental-extra-costs": "Zusätzliche Kosten für die Anmietung einer Wohnung, wie Heizung, Wasser, etc.",
          "template-description-serafe-single-household": "Serafe-Gebühr für Einpersonenhaushalte, ist eine obligatorische Gebühr für TV und Radio",
          "template-description-streaming-subscription": "Abonnement für jegliche Art von Streaming-Diensten wie Netflix, Disney+, etc.",
          "template-description-electricity": "Stromrechnung von Ihrem Stromanbieter",
          "template-description-mobile-internet-tv": "Rechnungen für Mobil, Internet und TV",
          "template-description-cantonal-tax": "Rückstellungen für Kantonssteuersätze und Endabrechnungen",
          "template-description-governmental-tax": "Rückstellungen für Staatssteuersätze und Endabrechnungen",
          "template-description-fire-department-tax": "Rückstellungen für Feuerwehrsteuerzahlungen",
          "template-description-health-insurance": "Grundprämien der Krankenversicherung (für alle obligatorisch)",
          "template-description-additional-insurance": "Zusätzliche Krankenversicherung",
          "template-description-life-insurance": "Lebensversicherungsprämien",
          "template-description-blocked-bank-savings": "Gesperrte Bankersparnisse für den Ruhestand",
          "template-description-liability-insurance": "Haftpflichtversicherungsprämien",
          "template-description-legal-protection-insurance": "Rechtsschutzversicherungsprämien",
          "template-description-public-transport-subscription": "Abonnement für öffentliche Verkehrsmittel",
          "template-description-public-transport-tickets": "Budgetposition für einzelne Tickets für öffentliche Verkehrsmittel.",
          "template-description-bike": "Ausgaben für Fahrradwartung und andere Eventualitäten",
          "template-description-e-bike": "Ausgaben für E-Bike-Wartung",
          "template-description-car": "Alles in einer Position für Autowartung, Kraftstoff, Versicherung, etc.",
          "template-description-motorcycle": "Alles in einer Position für Motorradwartung, Kraftstoff, Versicherung, etc.",
          "template-description-moto-tax": "Steuerzahlungen für Auto oder Motorrad",
          "template-description-Fuel": "Kraftstoffkosten",
          "template-description-moto-insurance": "Versicherungsprämien für Auto oder Motorrad",
          "template-description-moto-maintenance": "Wartungskosten für Auto oder Motorrad",
          "template-description-moto-fees": "Andere Gebühren und Kosten für Auto und Motorrad",
          "template-description-moto-amortisation": "Abschreibungskosten für Auto und Motorrad",
          "template-description-moto-leasing": "Leasingzahlungen für Auto und Motorrad",
          "template-description-media-subscriptions": "Abonnements für Mediendienste",
          "template-description-association-contributions": "Beiträge zu Vereinen",
          "template-description-membership-contributions": "Mitgliedsbeiträge (z.B. Sportvereine, Fitnessstudio, etc.)",
          "template-description-school-money": "Schulbezogene Ausgaben wie Mittagessen, Cafeteria, etc.",
          "template-description-continuous-education-costs": "Rückstellungen oder Kosten für Weiterbildung",
          "template-description-electronics": "Ausgaben für Elektronik",
          "template-description-hobby": "Ausgaben für Hobbys",
          "template-description-child-care": "Kinderbetreuungskosten",
          "template-description-dept-yield-costs": "Kosten für Schuldenrendite",
          "template-description-dept-down-payments": "Anzahlungen für Schulden",
          "template-description-alimony-payments": "Unterhaltszahlungen",
          "template-description-household-costs": "Haushaltsbezogene Ausgaben",
          "template-description-food-drinks": "Ausgaben für Essen und Getränke",
          "template-description-guests": "Ausgaben für Gäste",
          "template-description-alcoholic-drinks": "Ausgaben für alkoholische Getränke",
          "template-description-pet": "Ausgaben für Haustierpflege",
          "template-description-clothes": "Ausgaben für Kleidung",
          "template-description-pocket-money": "Taschengeld für persönliche Ausgaben im Laufe des Tages",
          "template-description-hairdresser": "Ausgaben für Friseur",
          "template-description-eating-out": "Ausgaben für das Essen gehen mit Freunden oder Familie",
          "template-description-going-out": "Ausgaben für das Ausgehen und Feiern mit Freunden oder Familie",
          "template-description-provisions": "Rückstellungen und Ersparnisse",
          "template-description-eye-check-up": "Ausgaben für regelmäßige Augenuntersuchungen",
          "template-description-teeth-check-up": "Ausgaben für regelmäßige Zahnuntersuchungen",
          "template-description-therapy": "Ausgaben für Therapie",
          "template-description-veterinarian": "Ausgaben für Tierarzt",
          "template-description-presents": "Ausgaben für Geschenke",
          "template-description-donations": "Ausgaben für Spenden",
          "template-description-school-camps": "Ausgaben für Schullager",
          "template-description-school-costs": "Schulbezogene Kosten wie Bücher, Materialien, etc.",
          "template-description-vacation": "Ersparnisse für Urlaub",
          "template-description-cash-savings": "Bargeldersparnisse",
          "template-description-etf-savings-plan": "ETF-Sparplan",
          "template-name-house-heating": "Hausheizung",
          "template-description-house-heating": "Hausheizung",
          "template-name-house-tax": "Haussteuer",
          "template-description-house-tax": "Steuer für das Haus",
          "template-name-house-mortgage": "Haus Hypothek",
          "template-description-house-mortgage": "Hypothekenratenzahlungen",
          "template-name-house-insurance": "Hausversicherung",
          "template-description-house-insurance": "Hausversicherung",
          "template-name-house-electricity": "Hausstrom",
          "template-description-house-electricity": "Hausstrom",
          "house-owner": "Hausbesitzer",
          "rent": "Miete",
          "fees-and-taxes": "Gebühren und Steuern",
          "subscriptions": "Abonnements",
          "electricity": "Strom",
          "communication": "Kommunikation",
          "insurance": "Versicherung",
          "retirement-provisions": "Ruhestandsrückstellungen",
          "transportation": "Transport",
          "personal-transport": "Persönlicher Transport",
          "various": "Verschiedenes",
          "personal-expenses": "Persönliche Ausgaben",
          "child-costs": "Kinderkosten",
          "household": "Haushalt",
          "pet": "Haustier",
          "provisions": "Rückstellungen",
          "health": "Gesundheit",
          "donations": "Spenden",
          "cash-savings": "Bargeldersparnisse",
          "salary": "Gehalt",
          "net": "Netto",
          "bonus": "Bonus",
          "irregular": "Unregelmäßig",
          "fixed-costs": "Fixkosten",
          "cold-rent": "Kaltmiete",
          "extra-costs": "Zusatzkosten",
          "variable-costs": "Variable Kosten",
          "water": "Wasser",
          "heating": "Heizung",
          "serafe": "Serafe",
          "state-fee": "Staatsgebühr",
          "media": "Medien",
          "streaming": "Streaming",
          "apps": "Apps",
          "subscription": "Abonnement",
          "power": "Strom",
          "mobile": "Mobil",
          "internet": "Internet",
          "tv": "TV",
          "connectivity": "Konnektivität",
          "cantonal": "Kantonal",
          "tax": "Steuer",
          "governmental": "Staatlich",
          "fire-department": "Feuerwehr",
          "military": "Militär",
          "replacement": "Ersatz",
          "life": "Leben",
          "retirement": "Ruhestand",
          "self-employment": "Selbstständigkeit",
          "house-ownership": "Hausbesitz",
          "bank-savings": "Bankersparnisse",
          "investment": "Investition",
          "liability": "Haftung",
          "legal": "Rechtlich",
          "protection": "Schutz",
          "public-transport": "Öffentlicher Verkehr",
          "tickets": "Tickets",
          "bike": "Fahrrad",
          "e-bike": "E-Bike",
          "leasing": "Leasing",
          "fuel": "Kraftstoff",
          "maintenance": "Wartung",
          "fees": "Gebühren",
          "amortisation": "Abschreibung",
          "media-subscriptions": "Medienabonnements",
          "association": "Verein",
          "contributions": "Beiträge",
          "membership": "Mitgliedschaft",
          "school": "Schule",
          "money": "Geld",
          "continuous-education": "Weiterbildung",
          "education": "Bildung",
          "hobby": "Hobby",
          "child-care": "Kinderbetreuung",
          "yield": "Rendite",
          "costs": "Kosten",
          "down-payments": "Anzahlungen",
          "amortization": "Abschreibung",
          "alimony": "Unterhalt",
          "payments": "Zahlungen",
          "daily-expenses": "Tägliche Ausgaben",
          "food": "Essen",
          "groceries": "Lebensmittel",
          "drinks": "Getränke",
          "guests": "Gäste",
          "alcoholic-drinks": "Alkoholische Getränke",
          "going-out": "Ausgehen",
          "party": "Party",
          "bars": "Bars",
          "eyes": "Augen",
          "check-up": "Untersuchung",
          "doctor": "Arzt",
          "teeth": "Zähne",
          "therapy": "Therapie",
          "veterinarian": "Tierarzt",
          "pet-food": "Tierfutter",
          "pet-care": "Tierpflege",
          "presents": "Geschenke",
          "camps": "Lager",
          "vacation": "Urlaub",
          "mortgage": "Hypothek",
          'irregular-transactions': "Unregelmässige Transaktionen",
          'irregular-transactions-title': "Unregelmäßige Transaktionen",
          'no-data-irregular-transactions-title': "Keine unregelmäßigen Transaktionen für das ausgewählte Jahr.",
          'no-data-irregular-transactions-description': "Es gibt keine unregelmäßigen Transaktionen für das ausgewählte Jahr. Unregelmäßige budgetierte Positionen werden nur für das aktuelle oder zukünftige Jahr angezeigt. Bitte versuchen Sie es erneut mit einem anderen Jahr oder erstellen Sie Ihre ersten Budgetpositionen.",
          'overdue': "Überfällig",
          'needs-attention': "Benötigt Aufmerksamkeit",
          'bill-paid': "Rechnung bezahlt",
          'planned-month': "Geplanter Monat",
          'paid': "Bezahlt",
        }
      },
      it: {
        translation: {
          "login-message-continue-with-existing-data": "Continua con i dati esistenti",
          "login-button-open-other-budget": "Apri un altro budget",
          "login-button-continue-existing-data": "Continua con i dati esistenti",
          "login-button-login": "Accedi",
          "login-button-start-new": "Inizia un nuovo budget",
          "login-message-existing-data": "Abbiamo trovato un budget esistente nella memoria locale della tua app!",
          "login-message-overwritten-unsaved-lost": "In caso di un altro caricamento di file, le tue modifiche non salvate verranno sovrascritte e perse.",
          "user_settings": "Impostazioni utente",
          "login-label-password": "Password",
          "login-label-username": "Nome utente",
          "login-label-upload-file": "Carica file",
          "username": "Nome utente",
          "payday": "Giorno di paga",
          "location": "Posizione",
          "language": "Lingua",
          "default_currency": "Valuta predefinita",
          "password": "Password",
          "confirm_password": "Conferma password",
          "update_settings": "Aggiorna impostazioni",
          "monthly-remaining-income": "Reddito mensile rimanente",
          "annual-remaining-income": "Reddito annuale rimanente",
          "financial-positions-placeholder-category": "Digita una nuova categoria o scegli una esistente",
          "no-data-available": "Nessun dato disponibile",
          "no-data-message": "Sembra che tu non abbia ancora creato alcun {{pageContent}}.",
          "add-button-add": "Aggiungi {{title}}",
          "budget-check-no-due-bills": "Nessuna fattura in scadenza",
          "registration-process-onboarding-welcome": "Benvenuto in ecBudget!",
          "registration-error": "Errore nella registrazione dell'utente",
          "registration-process-onboarding-description": "Innanzitutto, grazie per aver scelto ecBudget! Poiché i budget di solito coinvolgono dati personali e sensibili, è importante spiegare come questa app tratta i tuoi dati:",
          "registration-process-onboarding-local-storage": "ecBudget memorizza i tuoi dati solo localmente nel tuo browser, non esiste un database centrale che memorizza i tuoi dati personali. ecBudget ti darà la possibilità di crittografare i tuoi dati locali per renderli più sicuri. ecBudget non può ripristinare i tuoi dati una volta persi. ecBudget indicherà le modifiche non salvate per consentirti di effettuare backup frequenti. Il file verrà scaricato nella cartella di download del tuo dispositivo per impostazione predefinita. Dove memorizzi il file non importa, puoi riutilizzarlo su qualsiasi altro dispositivo. Pertanto, non è consigliabile condividere copie di questo file con terze parti sconosciute. La conservazione sicura dei dati e il trattamento responsabile degli stessi sono di tua piena responsabilità come utente.",
          "registration-process-onboarding-terms": "Cliccando sul pulsante qui sotto, accetti di aver compreso questi termini attuali.",
          "registration-process-onboarding-okay": "Ok, ho capito",
          "onboarding": "Introduzione",
          "german": "Tedesco",
          "italian": "Italiano",
          "french": "Francese",
          "english": "Inglese",
          "albanian": "Albanese",
          "chf": "CHF",
          "eur": "EUR",
          "usd": "USD",
          "budget-check-date": "Data di controllo del budget",
          "budget-check-help": "Il giorno del mese in cui vuoi controllare il tuo budget. (Usato per i promemoria)",
          "encryption": "Crittografia",
          "encryption-description": "Puoi crittografare il contenuto dei tuoi dati memorizzati con una password. Questa password non può essere ripristinata dall'app ed è l'unica chiave per accedere al tuo budget.",
          "encryption-mode": "Voglio crittografare i miei dati",
          "confirm-password": "Conferma password",
          "password-criteria": "La password deve contenere:",
          "password-length": "Almeno 8 caratteri",
          "password-uppercase": "Almeno una lettera maiuscola",
          "password-lowercase": "Almeno una lettera minuscola",
          "password-number": "Almeno un numero",
          "password-special-char": "Almeno un carattere speciale",
          "register": "Registrati",
          "budget-check-no-due-bills-description": "Non ci sono fatture in scadenza per questo mese.",
          "password-match-error": "Le password non corrispondono.",
          "password_criteria": {
            "length": "Almeno 10 caratteri",
            "uppercase": "Almeno una lettera maiuscola",
            "lowercase": "Almeno una lettera minuscola",
            "number": "Almeno un numero",
            "special": "Almeno un carattere speciale"
          },
          "password_error": "La password non soddisfa i criteri.",
          "confirm_password_error": "Le password non corrispondono.",
          "settings_updated": "Impostazioni aggiornate con successo",
          "settings_update_error": "Errore nell'aggiornamento delle impostazioni",
          "financial_positions": "Posizioni di bilancio",
          "accounts": "Conti",
          "budget_checks": "Controlli di bilancio",
          "add_new": "Aggiungi nuovo",
          "sum": "Somma",
          "monthly": "Mensile",
          "january": "Gennaio",
          "february": "Febbraio",
          "march": "Marzo",
          "april": "Aprile",
          "may": "Maggio",
          "june": "Giugno",
          "july": "Luglio",
          "august": "Agosto",
          "september": "Settembre",
          "october": "Ottobre",
          "november": "Novembre",
          "december": "Dicembre",
          "rest": "Resto",
          "data_updated_successfully": "Dati aggiornati con successo",
          "edit": "Modifica",
          "delete": "Elimina",
          "insights": "Approfondimenti",
          "select_type": "Seleziona tipo",
          "list_separated_by_space": "(elenco separato da spazio vuoto)",
          "submit": "Invia",
          "income": "Reddito",
          "fixed_costs": "Costi fissi",
          "variable_costs": "Costi variabili",
          "savings": "Risparmi",
          "wealth": "Ricchezza",
          "dept": "Debito",
          "no_results": "Nessun risultato trovato :-(",
          "no_results_description": "Non ci sono risultati per il filtro selezionato. Si prega di riprovare con impostazioni di filtro diverse.",
          "due": "Scadenza",
          "income_ratio_description": "Questa percentuale mostra quanto del tuo reddito annuale viene utilizzato per la categoria selezionata.",
          "title_income_ratio": "Rapporto di reddito",
          "category_ratio_description": "Questa percentuale mostra quanto vale questa posizione rispetto alle altre posizioni in questa categoria.",
          "cancel": "Annulla",
          "assets": "Attività",
          "provision": "Provvigione",
          "upcoming-bills": "Fatture in arrivo",
          "top_expenses": "Le 3 principali spese",
          "no_data_available": "Nessun dato disponibile",
          "welcome_info_title": "Benvenuto",
          "net_wealth_title": "Patrimonio netto*",
          "net_wealth_description": "*Tutti i tuoi risparmi, attività, ecc. meno i tuoi debiti.",
          "unallocated_income": "Reddito non allocato",
          "unallocated_income_description": "*Ciò che rimane dopo aver dedotto tutte le posizioni dal tuo reddito nel budget.",
          "widget_monthly_inflow_account_allocation": "Afflusso mensile del conto",
          "widget_monthly_outflow_account_allocation": "Deflusso mensile del conto",
          "budget-check-final-view-monthly-spending": "Spese mensili",
          "budget-check-page-title": "Controllo del budget per ",
          "budget_check_category_income": "Reddito",
          "budget_check_category_fixed_costs": "Costi fissi",
          "budget_check_category_variable_costs": "Costi variabili",
          "budget_check_category_savings": "Risparmi",
          "budget-check-status-closed": "Chiuso",
          "budget-check-status-open": "Pronto",
          "budget-check-table-header-position": "Posizione",
          "budget-check-table-header-account": "Conto",
          "budget-check-table-header-budgeted": "Budget",
          "budget-check-table-header-effective": "Importo effettivo",
          "budget-check-table-header-paid-received": "Registrato",
          "budget-check-item-bill-due": "La fattura per questa posizione è in scadenza",
          "budget-check-next-button": "Avanti",
          "budget-check-next-button-disabled": "Controlla prima tutte le posizioni",
          "budget-check-previous-button": "Indietro",
          "budget-check-save-button": "Salva",
          "budget-check-table-footer-total": "Totale",
          "budget-check-table-header-paid-from-account": "Pagato dal conto...",
          'budget-check-add-unexpected-transaction': "Aggiungi transazione inaspettata",
          "budget-check-final-view-unexpected-transactions": "Transazioni inaspettate",
          "pre-logged": "Pre-registrato",
          "changed": "Modificato",
          "budget-check-process-next": "Avanti",
          "budget-check-final-view-your-results": "Risultati del controllo del budget",
          "budget-check-final-view-abbreviation-from-budget": "Abbreviazione del budget",
          "budget-check-final-view-months-income": "Reddito di questo mese",
          "budget-check-final-view-months-provisions": "Provvigioni fatte",
          "budget-check-final-view-bills-paid": "Fatture pagate!",
          "budget-check-final-view-unplanned-expenses": "Spese non pianificate",
          "budget-check-final-view-total-savings": "Risparmi totali",
          "budget-check-final-view-budget-score": "Il tuo punteggio di budget",
          "budget-check-process-save": "Salva",
          "budget-check-process-previous": "Indietro",
          "budget-check-final-view-monthly-bills-paid": "Fatture mensili pagate",
          "navigation-menu-tooltip-dashboard": "Dashboard",
          "navigation-menu-tooltip-financial-positions": "Posizioni di bilancio",
          "navigation-menu-tooltip-accounts": "Conti",
          "navigation-menu-tooltip-budget-checks": "Controlli di bilancio",
          "navigation-menu-tooltip-user-profile": "Profilo utente",
          "navigation-menu-tooltip-save": "Salva",
          "budget-check-table-header-date": "Data",
          "budget-check-table-header-budget-score": "Punteggio di budget",
          "budget-check-table-header-deviation-budget": "Deviazione dal budget",
          "budget-check-table-header-status": "Stato",
          "budget-check-table-view-title": "Controlli di bilancio",
          "transaction-section-all-checked": "Tutte le transazioni sono state verificate",
          "transaction-section-all-checked-description": "Tutte le posizioni sono state verificate. L'importo totale delle transazioni di {{type}} per questo mese è:",
          "transaction-section-hide-checked": "Nascondi transazioni verificate",
          "transaction-section-show-checked": "Mostra transazioni verificate",
          "transaction-search-checked-transactions": "Transazioni verificate",
          "transaction-search-checked-transactions-description": "Qui trovi tutte le transazioni della categoria, se devi adattarne una, puoi deselezionarle qui.",
          "transaction-search-uncheck-transaction": "Deseleziona transazione",
          "transaction-search-no-transactions-found": "Nessuna transazione trovata",
          "budget-check-final-view-income-transactions": "Transazioni di reddito",
          "budget-check-final-view-regular-transactions": "Transazioni regolari",
          "budget-check-final-view-irregular-transactions": "Transazioni irregolari",
          "budget-check-final-view-savings-transactions": "Transazioni di risparmio",
          "budget-check-process-main-title": "Controllo del budget",
          "create-edit-form-required-fields": "Campi obbligatori",
          "create-edit-form-optional-fields": "Campi opzionali",
          "dashboard-widget-top-spending-categories": "Categorie di spesa principali",
          "dashboard-widget-top-three-expenses": "Le 3 principali spese",
          "expense": "Spesa",
          "navigation-menu-tooltip-budget-checks-tip": "Controllo del budget",
          "navigation-menu-tooltip-financial-positions-tip": "Posizioni di bilancio",
          "budget-check-provision-table-view-title": "Provvigioni e fatture mensili",
          "budget-check-definitive-expenses-table-view-title": "Grandi fatture e spese impreviste",
          "budget-check-partial-bill": "Fattura parziale",
          "budget-check-total-budgeted-bill": "Budget totale per questa fattura",
          "big-bills": "Grandi fatture",
          "monthly-spending": "Spese mensili",
          "unplanned-expense": "Spesa non pianificata",
          "table-has-no-values": "Questa tabella non ha valori",
          "monthly_spending_check": "Controllo delle spese mensili",
          "financial-positions": "Posizioni di bilancio",
          "budget-checks": "Controlli di bilancio",
          "no-changes": "Nessuna modifica",
          "unsaved-changes": "Modifiche non salvate",
          "profile": "Profilo",
          "dashboard": "Dashboard",
          "dashboard-widget-last-save": "Ultimo salvataggio: ",
          "add-missing-values-to-template": "Aggiungi i valori mancanti al modello",
          "template-question-type-monthly-amount": "Indica l'importo mensile",
          "template-question-type-annual-amount": "Indica l'importo annuale",
          "template-question-type-monthly-payable": "Scegli il mese in cui paghi o ricevi l'importo",
          "template-name-one-person-income": "Reddito di una persona (nome)",
          "template-name-bonus-income": "Reddito da bonus",
          "template-name-irregular-income": "Reddito irregolare",
          "template-name-rent": "Affitto",
          "template-name-rental-extra-costs": "Costi extra di affitto",
          "template-name-streaming-subscription": "Abbonamento streaming",
          "template-name-electricity": "Elettricità",
          "template-name-mobile-internet-tv": "Mobile, internet, TV",
          "template-name-cantonal-tax": "Tassa cantonale",
          "template-name-governmental-tax": "Tassa governativa",
          "template-name-fire-department-tax": "Tassa dei vigili del fuoco",
          "template-name-health-insurance": "Assicurazione sanitaria",
          "template-name-additional-insurance": "Assicurazione aggiuntiva",
          "template-name-life-insurance": "Assicurazione sulla vita",
          "template-name-blocked-bank-savings": "Risparmi bancari bloccati",
          "template-name-liability-insurance": "Assicurazione di responsabilità civile",
          "template-name-legal-protection-insurance": "Assicurazione di protezione legale",
          "template-name-public-transport-subscription": "Abbonamento trasporto pubblico",
          "template-name-public-transport-tickets": "Biglietti trasporto pubblico",
          "template-name-bike": "Bicicletta",
          "template-name-e-bike": "E-bike",
          "template-name-car": "Auto",
          "template-name-motorcycle": "Motocicletta",
          "template-name-moto-tax": "Tassa moto",
          "template-name-Fuel": "Carburante",
          "template-name-moto-insurance": "Assicurazione moto",
          "template-name-moto-maintenance": "Manutenzione moto",
          "template-name-moto-fees": "Tasse moto",
          "template-name-moto-amortisation": "Ammortamento moto",
          "template-name-moto-leasing": "Leasing moto",
          "template-name-media-subscriptions": "Abbonamenti media",
          "template-name-association-contributions": "Contributi associativi",
          "template-name-membership-contributions": "Contributi di adesione",
          "template-name-school-money": "Denaro scolastico",
          "template-name-continuous-education-costs": "Costi di formazione continua",
          "template-name-electronics": "Elettronica",
          "template-name-hobby": "Hobby",
          "template-name-child-care": "Cura dei bambini",
          "template-name-dept-yield-costs": "Costi di rendimento del debito",
          "template-name-dept-down-payments": "Acconti sul debito",
          "template-name-alimony-payments": "Pagamenti di alimenti",
          "template-name-household-costs": "Costi domestici",
          "template-name-food-drinks": "Cibo e bevande",
          "template-name-guests": "Ospiti",
          "template-name-alcoholic-drinks": "Bevande alcoliche",
          "template-name-pet": "Animale domestico",
          "template-name-clothes": "Vestiti",
          "template-name-pocket-money": "Paghetta",
          "template-name-hairdresser": "Parrucchiere",
          "template-name-eating-out": "Mangiare fuori",
          "template-name-going-out": "Uscire",
          "template-name-provisions": "Provvigioni",
          "template-name-eye-check-up": "Controllo oculistico",
          "template-name-teeth-check-up": "Controllo dentale",
          "template-name-therapy": "Terapia",
          "template-name-veterinarian": "Veterinario",
          "template-name-presents": "Regali",
          "template-name-donations": "Donazioni",
          "template-name-school-camps": "Campi scolastici",
          "template-name-school-costs": "Costi scolastici",
          "template-name-vacation": "Vacanza",
          "template-name-etf-savings-plan": "Piano di risparmio ETF",
          "template-name-cash-savings": "Risparmi in contanti",
          "template-name-asset-savings": "Risparmi patrimoniali",
          "template-name-serafe-single-household": "Serafe nucleo familiare singolo",
          "template-name-replacement-for-military-service": "Sostituzione per servizio militare",
          "template-description-replacement-for-military-service": "Tassa di sostituzione per servizio militare",
          "template-description-one-person-income": "Reddito di una singola persona",
          "template-description-bonus-income": "Reddito aggiuntivo da bonus o tredicesima",
          "template-description-irregular-income": "Reddito da fonti irregolari (es. lavoro autonomo, attività secondaria, ecc.)",
          "template-description-rent": "Pagamenti mensili di affitto",
          "template-description-rental-extra-costs": "Costi extra per l'affitto di un appartamento, come riscaldamento, acqua, ecc.",
          "template-description-serafe-single-household": "Tassa Serafe per nuclei familiari singoli, è una tassa obbligatoria per TV e radio",
          "template-description-streaming-subscription": "Abbonamento per qualsiasi tipo di servizio di streaming come Netflix, Disney+, ecc.",
          "template-description-electricity": "Bolletta dell'elettricità dal tuo fornitore di energia elettrica",
          "template-description-mobile-internet-tv": "Bolletta per mobile, internet e TV",
          "template-description-cantonal-tax": "Provvigioni per le aliquote fiscali cantonali e le bollette finali",
          "template-description-governmental-tax": "Provvigioni per le aliquote fiscali governative e le bollette finali",
          "template-description-fire-department-tax": "Provvigioni per i pagamenti della tassa dei vigili del fuoco",
          "template-description-health-insurance": "Premi base dell'assicurazione sanitaria (obbligatoria per tutti)",
          "template-description-additional-insurance": "Copertura assicurativa aggiuntiva",
          "template-description-life-insurance": "Premi dell'assicurazione sulla vita",
          "template-description-blocked-bank-savings": "Risparmi bancari bloccati per la pensione",
          "template-description-liability-insurance": "Premi dell'assicurazione di responsabilità civile",
          "template-description-legal-protection-insurance": "Premi dell'assicurazione di protezione legale",
          "template-description-public-transport-subscription": "Abbonamento per il trasporto pubblico",
          "template-description-public-transport-tickets": "Posizione di bilancio per i singoli biglietti del trasporto pubblico.",
          "template-description-bike": "Spese per la manutenzione della bicicletta e altre eventualità",
          "template-description-e-bike": "Spese per la manutenzione dell'e-bike",
          "template-description-car": "Tutto in una posizione per la manutenzione dell'auto, carburante, assicurazione, ecc.",
          "template-description-motorcycle": "Tutto in una posizione per la manutenzione della moto, carburante, assicurazione, ecc.",
          "template-description-moto-tax": "Pagamenti delle tasse per auto o moto",
          "template-description-Fuel": "Spese per il carburante",
          "template-description-moto-insurance": "Premi assicurativi per auto o moto",
          "template-description-moto-maintenance": "Costi di manutenzione per auto o moto",
          "template-description-moto-fees": "Altre tasse e costi per auto e moto",
          "template-description-moto-amortisation": "Costi di ammortamento per auto e moto",
          "template-description-moto-leasing": "Pagamenti di leasing per auto e moto",
          "template-description-media-subscriptions": "Abbonamenti ai servizi media",
          "template-description-association-contributions": "Contributi alle associazioni",
          "template-description-membership-contributions": "Contributi di adesione (es. club sportivi, palestra, ecc.)",
          "template-description-school-money": "Spese scolastiche come pranzo, mensa, ecc.",
          "template-description-continuous-education-costs": "Provvigioni o costi per la formazione continua",
          "template-description-electronics": "Spese per l'elettronica",
          "template-description-hobby": "Spese per hobby",
          "template-description-child-care": "Spese per la cura dei bambini",
          "template-description-dept-yield-costs": "Costi per il rendimento del debito",
          "template-description-dept-down-payments": "Acconti per il debito",
          "template-description-alimony-payments": "Pagamenti di alimenti",
          "template-description-household-costs": "Spese domestiche",
          "template-description-food-drinks": "Spese per cibo e bevande",
          "template-description-guests": "Spese per gli ospiti",
          "template-description-alcoholic-drinks": "Spese per bevande alcoliche",
          "template-description-pet": "Spese per la cura degli animali domestici",
          "template-description-clothes": "Spese per i vestiti",
          "template-description-pocket-money": "Paghetta per spese personali durante il giorno",
          "template-description-hairdresser": "Spese per il parrucchiere",
          "template-description-eating-out": "Spese per mangiare fuori con amici o famiglia",
          "template-description-going-out": "Spese per uscire e festeggiare con amici o famiglia",
          "template-description-provisions": "Provvigioni e risparmi",
          "template-description-eye-check-up": "Spese per controlli oculistici regolari",
          "template-description-teeth-check-up": "Spese per controlli dentali regolari",
          "template-description-therapy": "Spese per la terapia",
          "template-description-veterinarian": "Spese per il veterinario",
          "template-description-presents": "Spese per i regali",
          "template-description-donations": "Spese per le donazioni",
          "template-description-school-camps": "Spese per i campi scolastici",
          "template-description-school-costs": "Costi scolastici come libri, materiali, ecc.",
          "template-description-vacation": "Risparmi per le vacanze",
          "template-description-cash-savings": "Risparmi in contanti",
          "template-description-etf-savings-plan": "Piano di risparmio ETF",
          "template-name-house-heating": "Riscaldamento della casa",
          "template-description-house-heating": "Riscaldamento della casa",
          "template-name-house-tax": "Tassa sulla casa",
          "template-description-house-tax": "Tassa sulla casa",
          "template-name-house-mortgage": "Mutuo casa",
          "template-description-house-mortgage": "Pagamenti delle rate del mutuo",
          "template-name-house-insurance": "Assicurazione casa",
          "template-description-house-insurance": "Assicurazione casa",
          "template-name-house-electricity": "Elettricità della casa",
          "template-description-house-electricity": "Elettricità della casa",
          "house-owner": "Proprietario di casa",
          "rent": "Affitto",
          "fees-and-taxes": "Tasse e imposte",
          "subscriptions": "Abbonamenti",
          "electricity": "Elettricità",
          "communication": "Comunicazione",
          "insurance": "Assicurazione",
          "retirement-provisions": "Provvigioni per la pensione",
          "transportation": "Trasporto",
          "personal-transport": "Trasporto personale",
          "various": "Varie",
          "personal-expenses": "Spese personali",
          "child-costs": "Costi per i bambini",
          "household": "Casa",
          "pet": "Animale domestico",
          "provisions": "Provvigioni",
          "health": "Salute",
          "donations": "Donazioni",
          "cash-savings": "Risparmi in contanti",
          "salary": "Stipendio",
          "net": "Netto",
          "bonus": "Bonus",
          "irregular": "Irregolare",
          "fixed-costs": "Costi fissi",
          "cold-rent": "Affitto freddo",
          "extra-costs": "Costi extra",
          "variable-costs": "Costi variabili",
          "water": "Acqua",
          "heating": "Riscaldamento",
          "serafe": "Serafe",
          "state-fee": "Tassa statale",
          "media": "Media",
          "streaming": "Streaming",
          "apps": "App",
          "subscription": "Abbonamento",
          "power": "Energia",
          "mobile": "Mobile",
          "internet": "Internet",
          "tv": "TV",
          "connectivity": "Connettività",
          "cantonal": "Cantonale",
          "tax": "Tassa",
          "governmental": "Governativo",
          "fire-department": "Vigili del fuoco",
          "military": "Militare",
          "replacement": "Sostituzione",
          "life": "Vita",
          "retirement": "Pensione",
          "self-employment": "Lavoro autonomo",
          "house-ownership": "Proprietà della casa",
          "bank-savings": "Risparmi bancari",
          "investment": "Investimento",
          "liability": "Responsabilità",
          "legal": "Legale",
          "protection": "Protezione",
          "public-transport": "Trasporto pubblico",
          "tickets": "Biglietti",
          "bike": "Bicicletta",
          "e-bike": "E-bike",
          "leasing": "Leasing",
          "fuel": "Carburante",
          "maintenance": "Manutenzione",
          "fees": "Tasse",
          "amortisation": "Ammortamento",
          "media-subscriptions": "Abbonamenti media",
          "association": "Associazione",
          "contributions": "Contributi",
          "membership": "Adesione",
          "school": "Scuola",
          "money": "Denaro",
          "continuous-education": "Formazione continua",
          "education": "Istruzione",
          "hobby": "Hobby",
          "child-care": "Cura dei bambini",
          "yield": "Rendimento",
          "costs": "Costi",
          "down-payments": "Acconti",
          "amortization": "Ammortamento",
          "alimony": "Alimenti",
          "payments": "Pagamenti",
          "daily-expenses": "Spese giornaliere",
          "food": "Cibo",
          "groceries": "Generi alimentari",
          "drinks": "Bevande",
          "guests": "Ospiti",
          "alcoholic-drinks": "Bevande alcoliche",
          "going-out": "Uscire",
          "party": "Festa",
          "bars": "Bar",
          "eyes": "Occhi",
          "check-up": "Controllo",
          "doctor": "Medico",
          "teeth": "Denti",
          "therapy": "Terapia",
          "veterinarian": "Veterinario",
          "pet-food": "Cibo per animali",
          "pet-care": "Cura degli animali",
          "presents": "Regali",
          "camps": "Campi",
          "vacation": "Vacanza",
          "mortgage": "Mutuo",
          'irregular-transactions': "Transazioni Irregolari",
          'irregular-transactions-title': "Transazioni Irregolari",
          'no-data-irregular-transactions-title': "Nessuna transazione irregolare per l'anno selezionato.",
          'no-data-irregular-transactions-description': "Non ci sono transazioni irregolari per l'anno selezionato. Le posizioni di bilancio irregolari saranno mostrate solo per l'anno corrente o futuro. Si prega di riprovare con un anno diverso o creare le prime posizioni di bilancio.",
          'overdue': "Scaduto",
          'needs-attention': "Richiede Attenzione",
          'bill-paid': "Fattura Pagata",
          'planned-month': "Mese Pianificato",
          'paid': "Pagato",
        }
      },
      fr: {
        translation: {
        "login-message-continue-with-existing-data": "Continuer avec les données existantes",
        "login-button-open-other-budget": "Ouvrir un autre budget",
        "login-button-continue-existing-data": "Continuer avec les données existantes",
        "login-button-login": "Se connecter",
        "login-button-start-new": "Commencer un nouveau budget",
        "login-message-existing-data": "Nous avons trouvé un budget existant dans la mémoire locale de votre application!",
        "login-message-overwritten-unsaved-lost": "En cas de nouveau téléchargement de fichier, vos modifications non enregistrées seront écrasées et perdues.",
        "user_settings": "Paramètres utilisateur",
        "login-label-password": "Mot de passe",
        "login-label-username": "Nom d'utilisateur",
        "login-label-upload-file": "Télécharger le fichier",
        "username": "Nom d'utilisateur",
        "payday": "Jour de paie",
        "location": "Emplacement",
        "language": "Langue",
        "default_currency": "Devise par défaut",
        "password": "Mot de passe",
        "confirm_password": "Confirmer le mot de passe",
        "update_settings": "Mettre à jour les paramètres",
        "monthly-remaining-income": "Revenu mensuel restant",
        "annual-remaining-income": "Revenu annuel restant",
        "financial-positions-placeholder-category": "Tapez une nouvelle catégorie ou choisissez-en une existante",
        "no-data-available": "Aucune donnée disponible",
        "no-data-message": "Il semble que vous n'ayez pas encore créé de {{pageContent}}.",
        "add-button-add": "Ajouter {{title}}",
        "budget-check-no-due-bills": "Aucune facture due",
        "registration-error": "Erreur lors de l'enregistrement de l'utilisateur",
          "registration-process-onboarding-welcome": "Bienvenue à ecBudget!",
          'registration-process-onboarding-warning': "Veuillez noter que cette application est actuellement en phase de test et que des erreurs peuvent survenir. Veuillez sauvegarder vos données régulièrement et ne pas les utiliser pour des données sensibles.",
        "registration-process-onboarding-description": "Tout d'abord, merci d'avoir choisi ecBudget! Étant donné que les budgets impliquent généralement des données personnelles et sensibles, il est important d'expliquer comment cette application traite vos données:",
        "registration-process-onboarding-local-storage": "ecBudget stocke vos données uniquement localement dans votre navigateur, il n'y a pas de base de données centrale qui stocke vos données personnelles. ecBudget vous donnera la possibilité de chiffrer vos données locales pour les rendre plus sécurisées. ecBudget ne peut pas restaurer vos données une fois perdues. ecBudget indiquera les modifications non enregistrées pour vous permettre de faire des sauvegardes fréquentes. Le fichier sera téléchargé par défaut dans le dossier de téléchargement de votre appareil. L'endroit où vous stockez le fichier n'a pas d'importance, vous pouvez le réutiliser sur n'importe quel autre appareil. Il n'est donc pas recommandé de partager des copies de ce fichier avec des tiers inconnus. La conservation sécurisée des données et leur traitement responsable sont de votre entière responsabilité en tant qu'utilisateur.",
        "registration-process-onboarding-terms": "En cliquant sur le bouton ci-dessous, vous acceptez d'avoir compris ces termes actuels.",
        "registration-process-onboarding-okay": "D'accord, j'ai compris",
        "onboarding": "Introduction",
        "german": "Allemand",
        "italian": "Italien",
        "french": "Français",
        "english": "Anglais",
        "albanian": "Albanais",
        "chf": "CHF",
        "eur": "EUR",
        "usd": "USD",
        "budget-check-date": "Date de vérification du budget",
        "budget-check-help": "Le jour du mois où vous souhaitez vérifier votre budget. (Utilisé pour les rappels)",
        "encryption": "Chiffrement",
        "encryption-description": "Vous pouvez chiffrer le contenu de vos données stockées avec un mot de passe. Ce mot de passe ne peut pas être réinitialisé par l'application et est la seule clé pour accéder à votre budget.",
        "encryption-mode": "Je veux chiffrer mes données",
        "confirm-password": "Confirmer le mot de passe",
        "password-criteria": "Le mot de passe doit contenir:",
        "password-length": "Au moins 8 caractères",
        "password-uppercase": "Au moins une lettre majuscule",
        "password-lowercase": "Au moins une lettre minuscule",
        "password-number": "Au moins un chiffre",
        "password-special-char": "Au moins un caractère spécial",
        "register": "S'inscrire",
        "budget-check-no-due-bills-description": "Aucune facture due pour ce mois.",
          "budget-check-final-view-unexpected-transactions": "Transactions inattendues",
        "password-match-error": "Les mots de passe ne correspondent pas.",
        "password_criteria": {
          "length": "Au moins 10 caractères",
          "uppercase": "Au moins une lettre majuscule",
          "lowercase": "Au moins une lettre minuscule",
          "number": "Au moins un chiffre",
          "special": "Au moins un caractère spécial"
        },
        "password_error": "Le mot de passe ne répond pas aux critères.",
        "confirm_password_error": "Les mots de passe ne correspondent pas.",
        "settings_updated": "Paramètres mis à jour avec succès",
        "settings_update_error": "Erreur lors de la mise à jour des paramètres",
        "financial_positions": "Positions financières",
        "accounts": "Comptes",
        "budget_checks": "Vérifications du budget",
        "add_new": "Ajouter nouveau",
        "sum": "Somme",
        "monthly": "Mensuel",
        "january": "Janvier",
        "february": "Février",
        "march": "Mars",
        "april": "Avril",
        "may": "Mai",
        "june": "Juin",
        "july": "Juillet",
        "august": "Août",
        "september": "Septembre",
        "october": "Octobre",
        "november": "Novembre",
        "december": "Décembre",
        "rest": "Reste",
        "data_updated_successfully": "Données mises à jour avec succès",
        "edit": "Modifier",
        "delete": "Supprimer",
        "insights": "Aperçus",
        "select_type": "Sélectionner le type",
        "list_separated_by_space": "(liste séparée par un espace)",
        "submit": "Soumettre",
        "income": "Revenu",
        "fixed_costs": "Coûts fixes",
        "variable_costs": "Coûts variables",
        "savings": "Économies",
        "wealth": "Richesse",
        "dept": "Dette",
        "no_results": "Aucun résultat trouvé :-(",
        "no_results_description": "Aucun résultat trouvé pour le filtre sélectionné. Veuillez réessayer avec des paramètres de filtre différents.",
        "due": "Dû",
        "income_ratio_description": "Ce pourcentage montre combien de votre revenu annuel est utilisé pour la catégorie sélectionnée.",
        "title_income_ratio": "Ratio de revenu",
        "category_ratio_description": "Ce pourcentage montre combien vaut cette position par rapport aux autres positions de cette catégorie.",
        "cancel": "Annuler",
        "assets": "Actifs",
        "provision": "Provision",
        "upcoming-bills": "Factures à venir",
        "top_expenses": "Top 3 des dépenses",
        "no_data_available": "Aucune donnée disponible",
        "welcome_info_title": "Bienvenue",
        "net_wealth_title": "Patrimoine net*",
        "net_wealth_description": "*Tous vos économies, actifs, etc. moins vos dettes.",
        "unallocated_income": "Revenu non alloué",
        "unallocated_income_description": "*Ce qui reste après avoir déduit toutes les positions de votre revenu dans le budget.",
        "widget_monthly_inflow_account_allocation": "Afflux mensuel du compte",
        "widget_monthly_outflow_account_allocation": "Débit mensuel du compte",
        "budget-check-final-view-monthly-spending": "Dépenses mensuelles",
        "budget-check-page-title": "Vérification du budget pour ",
        "budget_check_category_income": "Revenu",
        "budget_check_category_fixed_costs": "Coûts fixes",
        "budget_check_category_variable_costs": "Coûts variables",
        "budget_check_category_savings": "Économies",
        "budget-check-status-closed": "Fermé",
        "budget-check-status-open": "Prêt",
        "budget-check-table-header-position": "Position",
        "budget-check-table-header-account": "Compte",
        "budget-check-table-header-budgeted": "Budgétisé",
        "budget-check-table-header-effective": "Montant effectif",
        "budget-check-table-header-paid-received": "Enregistré",
        "budget-check-item-bill-due": "La facture pour cette position est due",
        "budget-check-next-button": "Suivant",
        "budget-check-next-button-disabled": "Vérifiez d'abord toutes les positions",
        "budget-check-previous-button": "Précédent",
        "budget-check-save-button": "Enregistrer",
        "budget-check-table-footer-total": "Total",
        "budget-check-table-header-paid-from-account": "Payé depuis le compte...",
          'budget-check-add-unexpected-transaction': "Ajouter une transaction imprévue",
          "transaction-section-all-checked": "Toutes les transactions ont été vérifiées",
          "transaction-section-all-checked-description": "Toutes les positions ont été vérifiées. Le montant total des transactions de {{type}} pour ce mois est :",
          "transaction-section-hide-checked": "Masquer les transactions vérifiées",
          "transaction-section-show-checked": "Afficher les transactions vérifiées",
          "transaction-search-checked-transactions": "Transactions vérifiées",
          "transaction-search-checked-transactions-description": "Vous trouverez ici toutes les transactions de la catégorie. Si vous devez en adapter une, vous pouvez les décocher ici.",
          "transaction-search-uncheck-transaction": "Décocher la transaction",
          "transaction-search-no-transactions-found": "Aucune transaction trouvée",
          "budget-check-final-view-income-transactions": "Transactions de revenus",
          "budget-check-final-view-regular-transactions": "Transactions régulières",
          "budget-check-final-view-irregular-transactions": "Transactions irrégulières",
          "budget-check-final-view-savings-transactions": "Transactions d'épargne",
          "budget-check-process-main-title": "Vérification du budget",
        "pre-logged": "Pré-enregistré",
        "changed": "Modifié",
        "budget-check-process-next": "Suivant",
        "budget-check-final-view-your-results": "Résultats de la vérification du budget",
        "budget-check-final-view-abbreviation-from-budget": "Abréviation du budget",
        "budget-check-final-view-months-income": "Revenu de ce mois",
        "budget-check-final-view-months-provisions": "Provisions faites",
        "budget-check-final-view-bills-paid": "Factures payées!",
        "budget-check-final-view-unplanned-expenses": "Dépenses imprévues",
        "budget-check-final-view-total-savings": "Économies totales",
        "budget-check-final-view-budget-score": "Votre score de budget",
        "budget-check-process-save": "Enregistrer",
        "budget-check-process-previous": "Précédent",
        "budget-check-final-view-monthly-bills-paid": "Factures mensuelles payées",
        "navigation-menu-tooltip-dashboard": "Tableau de bord",
        "navigation-menu-tooltip-financial-positions": "Positions financières",
        "navigation-menu-tooltip-accounts": "Comptes",
        "navigation-menu-tooltip-budget-checks": "Vérifications du budget",
        "navigation-menu-tooltip-user-profile": "Profil utilisateur",
        "navigation-menu-tooltip-save": "Enregistrer",
        "budget-check-table-header-date": "Date",
        "budget-check-table-header-budget-score": "Score de budget",
        "budget-check-table-header-deviation-budget": "Écart par rapport au budget",
        "budget-check-table-header-status": "Statut",
        "budget-check-table-view-title": "Vérifications du budget",
        "create-edit-form-required-fields": "Champs obligatoires",
        "create-edit-form-optional-fields": "Champs optionnels",
        "dashboard-widget-top-spending-categories": "Catégories de dépenses principales",
        "dashboard-widget-top-three-expenses": "Top 3 des dépenses",
        "expense": "Dépense",
        "navigation-menu-tooltip-budget-checks-tip": "Vérification du budget",
        "navigation-menu-tooltip-financial-positions-tip": "Positions financières",
        "budget-check-provision-table-view-title": "Provisions et factures mensuelles",
        "budget-check-definitive-expenses-table-view-title": "Grandes factures et dépenses imprévues",
        "budget-check-partial-bill": "Facture partielle",
        "budget-check-total-budgeted-bill": "Budget total pour cette facture",
        "big-bills": "Grandes factures",
        "monthly-spending": "Dépenses mensuelles",
        "unplanned-expense": "Dépense imprévue",
        "table-has-no-values": "Cette table n'a pas de valeurs",
        "monthly_spending_check": "Contrôle des dépenses mensuelles",
        "financial-positions": "Positions financières",
        "budget-checks": "Vérifications du budget",
        "no-changes": "Aucun changement",
        "unsaved-changes": "Modifications non enregistrées",
        "profile": "Profil",
        "dashboard": "Tableau de bord",
        "dashboard-widget-last-save": "Dernier enregistrement: ",
        "add-missing-values-to-template": "Ajouter les valeurs manquantes au modèle",
        "template-question-type-monthly-amount": "Indiquez le montant mensuel",
        "template-question-type-annual-amount": "Indiquez le montant annuel",
        "template-question-type-monthly-payable": "Choisissez le mois où vous payez ou recevez le montant",
          "template-name-one-person-income": "Revenu d'une personne (nom)",
          "template-name-bonus-income": "Revenu de bonus",
          "template-name-irregular-income": "Revenu irrégulier",
          "template-name-rent": "Loyer",
          "template-name-rental-extra-costs": "Coûts supplémentaires de location",
          "template-name-streaming-subscription": "Abonnement streaming",
          "template-name-electricity": "Électricité",
          "template-name-mobile-internet-tv": "Mobile, internet, TV",
          "template-name-cantonal-tax": "Taxe cantonale",
          "template-name-governmental-tax": "Taxe gouvernementale",
          "template-name-fire-department-tax": "Taxe des pompiers",
          "template-name-health-insurance": "Assurance santé",
          "template-name-additional-insurance": "Assurance supplémentaire",
          "template-name-life-insurance": "Assurance vie",
          "template-name-blocked-bank-savings": "Économies bancaires bloquées",
          "template-name-liability-insurance": "Assurance responsabilité civile",
          "template-name-legal-protection-insurance": "Assurance protection juridique",
          "template-name-public-transport-subscription": "Abonnement transport public",
          "template-name-public-transport-tickets": "Billets transport public",
          "template-name-bike": "Vélo",
          "template-name-e-bike": "Vélo électrique",
          "template-name-car": "Voiture",
          "template-name-motorcycle": "Moto",
          "template-name-moto-tax": "Taxe moto",
          "template-name-Fuel": "Carburant",
          "template-name-moto-insurance": "Assurance moto",
          "template-name-moto-maintenance": "Entretien moto",
          "template-name-moto-fees": "Frais moto",
          "template-name-moto-amortisation": "Amortissement moto",
          "template-name-moto-leasing": "Leasing moto",
          "template-name-media-subscriptions": "Abonnements médias",
          "template-name-association-contributions": "Contributions associatives",
          "template-name-membership-contributions": "Contributions d'adhésion",
          "template-name-school-money": "Argent scolaire",
          "template-name-continuous-education-costs": "Coûts de formation continue",
          "template-name-electronics": "Électronique",
          "template-name-hobby": "Loisir",
          "template-name-child-care": "Garde d'enfants",
          "template-name-dept-yield-costs": "Coûts de rendement de la dette",
          "template-name-dept-down-payments": "Acomptes sur la dette",
          "template-name-alimony-payments": "Paiements de pension alimentaire",
          "template-name-household-costs": "Coûts ménagers",
          "template-name-food-drinks": "Nourriture et boissons",
          "template-name-guests": "Invités",
          "template-name-alcoholic-drinks": "Boissons alcoolisées",
          "template-name-pet": "Animal de compagnie",
          "template-name-clothes": "Vêtements",
          "template-name-pocket-money": "Argent de poche",
          "template-name-hairdresser": "Coiffeur",
          "template-name-eating-out": "Manger dehors",
          "template-name-going-out": "Sortir",
          "template-name-provisions": "Provisions",
          "template-name-eye-check-up": "Contrôle des yeux",
          "template-name-teeth-check-up": "Contrôle des dents",
          "template-name-therapy": "Thérapie",
          "template-name-veterinarian": "Vétérinaire",
          "template-name-presents": "Cadeaux",
          "template-name-donations": "Dons",
          "template-name-school-camps": "Camps scolaires",
          "template-name-school-costs": "Coûts scolaires",
          "template-name-vacation": "Vacances",
          "template-name-etf-savings-plan": "Plan d'épargne ETF",
          "template-name-cash-savings": "Économies en espèces",
          "template-name-asset-savings": "Économies d'actifs",
          "template-name-serafe-single-household": "Serafe ménage individuel",
          "template-name-replacement-for-military-service": "Remplacement pour service militaire",
          "template-description-replacement-for-military-service": "Taxe de remplacement pour service militaire",
          "template-description-one-person-income": "Revenu d'une seule personne",
          "template-description-bonus-income": "Revenu supplémentaire de bonus ou treizième mois",
          "template-description-irregular-income": "Revenu de sources irrégulières (ex. travail indépendant, activité secondaire, etc.)",
          "template-description-rent": "Paiements mensuels de loyer",
          "template-description-rental-extra-costs": "Coûts supplémentaires pour la location d'un appartement, comme le chauffage, l'eau, etc.",
          "template-description-serafe-single-household": "Taxe Serafe pour ménages individuels, une taxe obligatoire pour la TV et la radio",
          "template-description-streaming-subscription": "Abonnement à tout type de service de streaming comme Netflix, Disney+, etc.",
          "template-description-electricity": "Facture d'électricité de votre fournisseur d'énergie",
          "template-description-mobile-internet-tv": "Factures pour mobile, internet et TV",
          "template-description-cantonal-tax": "Provisions pour les taux d'imposition cantonaux et les factures finales",
          "template-description-governmental-tax": "Provisions pour les taux d'imposition gouvernementaux et les factures finales",
          "template-description-fire-department-tax": "Provisions pour les paiements de la taxe des pompiers",
          "template-description-health-insurance": "Primes de base de l'assurance santé (obligatoire pour tous)",
          "template-description-additional-insurance": "Couverture d'assurance supplémentaire",
          "template-description-life-insurance": "Primes d'assurance vie",
          "template-description-blocked-bank-savings": "Économies bancaires bloquées pour la retraite",
          "template-description-liability-insurance": "Primes d'assurance responsabilité civile",
          "template-description-legal-protection-insurance": "Primes d'assurance protection juridique",
          "template-description-public-transport-subscription": "Abonnement pour le transport public",
          "template-description-public-transport-tickets": "Poste budgétaire pour les billets de transport public individuels.",
          "template-description-bike": "Dépenses pour l'entretien du vélo et autres éventualités",
          "template-description-e-bike": "Dépenses pour l'entretien de l'e-bike",
          "template-description-car": "Tout en une position pour l'entretien de la voiture, le carburant, l'assurance, etc.",
          "template-description-motorcycle": "Tout en une position pour l'entretien de la moto, le carburant, l'assurance, etc.",
          "template-description-moto-tax": "Paiements des taxes pour voiture ou moto",
          "template-description-Fuel": "Dépenses pour le carburant",
          "template-description-moto-insurance": "Primes d'assurance pour voiture ou moto",
          "template-description-moto-maintenance": "Coûts d'entretien pour voiture ou moto",
          "template-description-moto-fees": "Autres frais et coûts pour voiture et moto",
          "template-description-moto-amortisation": "Coûts d'amortissement pour voiture et moto",
          "template-description-moto-leasing": "Paiements de leasing pour voiture et moto",
          "template-description-media-subscriptions": "Abonnements aux services médias",
          "template-description-association-contributions": "Contributions aux associations",
          "template-description-membership-contributions": "Contributions d'adhésion (ex. clubs sportifs, salle de sport, etc.)",
          "template-description-school-money": "Dépenses scolaires comme le déjeuner, la cantine, etc.",
          "template-description-continuous-education-costs": "Provisions ou coûts pour la formation continue",
          "template-description-electronics": "Dépenses pour l'électronique",
          "template-description-hobby": "Dépenses pour les loisirs",
          "template-description-child-care": "Dépenses pour la garde d'enfants",
          "template-description-dept-yield-costs": "Coûts pour le rendement de la dette",
          "template-description-dept-down-payments": "Acomptes pour la dette",
          "template-description-alimony-payments": "Paiements de pension alimentaire",
          "template-description-household-costs": "Dépenses ménagères",
          "template-description-food-drinks": "Dépenses pour la nourriture et les boissons",
          "template-description-guests": "Dépenses pour les invités",
          "template-description-alcoholic-drinks": "Dépenses pour les boissons alcoolisées",
          "template-description-pet": "Dépenses pour les soins des animaux de compagnie",
          "template-description-clothes": "Dépenses pour les vêtements",
          "template-description-pocket-money": "Argent de poche pour les dépenses personnelles au cours de la journée",
          "template-description-hairdresser": "Dépenses pour le coiffeur",
          "template-description-eating-out": "Dépenses pour manger dehors avec des amis ou la famille",
          "template-description-going-out": "Dépenses pour sortir et faire la fête avec des amis ou la famille",
          "template-description-provisions": "Provisions et économies",
          "template-description-eye-check-up": "Dépenses pour les contrôles oculaires réguliers",
          "template-description-teeth-check-up": "Dépenses pour les contrôles dentaires réguliers",
          "template-description-therapy": "Dépenses pour la thérapie",
          "template-description-veterinarian": "Dépenses pour le vétérinaire",
          "template-description-presents": "Dépenses pour les cadeaux",
          "template-description-donations": "Dépenses pour les dons",
          "template-description-school-camps": "Dépenses pour les camps scolaires",
          "template-description-school-costs": "Coûts scolaires comme les livres, les matériaux, etc.",
          "template-description-vacation": "Économies pour les vacances",
          "template-description-cash-savings": "Économies en espèces",
          "template-description-etf-savings-plan": "Plan d'épargne ETF",
          "template-name-house-heating": "Chauffage de la maison",
          "template-description-house-heating": "Chauffage de la maison",
          "template-name-house-tax": "Taxe sur la maison",
          "template-description-house-tax": "Taxe sur la maison",
          "template-name-house-mortgage": "Hypothèque maison",
          "template-description-house-mortgage": "Paiements des mensualités de l'hypothèque",
          "template-name-house-insurance": "Assurance maison",
          "template-description-house-insurance": "Assurance maison",
          "template-name-house-electricity": "Électricité de la maison",
          "template-description-house-electricity": "Électricité de la maison",
          "house-owner": "Propriétaire de maison",
          "rent": "Loyer",
          "fees-and-taxes": "Frais et taxes",
          "subscriptions": "Abonnements",
          "electricity": "Électricité",
          "communication": "Communication",
          "insurance": "Assurance",
          "retirement-provisions": "Provisions pour la retraite",
          "transportation": "Transport",
          "personal-transport": "Transport personnel",
          "various": "Divers",
          "personal-expenses": "Dépenses personnelles",
          "child-costs": "Coûts pour les enfants",
          "household": "Ménage",
          "pet": "Animal de compagnie",
          "provisions": "Provisions",
          "health": "Santé",
          "donations": "Dons",
          "cash-savings": "Économies en espèces",
          "salary": "Salaire",
          "net": "Net",
          "bonus": "Bonus",
          "irregular": "Irrégulier",
          "fixed-costs": "Coûts fixes",
          "cold-rent": "Loyer hors charges",
          "extra-costs": "Coûts supplémentaires",
          "variable-costs": "Coûts variables",
          "water": "Eau",
          "heating": "Chauffage",
          "serafe": "Serafe",
          "state-fee": "Taxe d'État",
          "media": "Médias",
          "streaming": "Streaming",
          "apps": "Applications",
          "subscription": "Abonnement",
          "power": "Énergie",
          "mobile": "Mobile",
          "internet": "Internet",
          "tv": "TV",
          "connectivity": "Connectivité",
          "cantonal": "Cantonal",
          "tax": "Taxe",
          "governmental": "Gouvernemental",
          "fire-department": "Pompiers",
          "military": "Militaire",
          "replacement": "Remplacement",
          "life": "Vie",
          "retirement": "Retraite",
          "self-employment": "Travail indépendant",
          "house-ownership": "Propriété de la maison",
          "bank-savings": "Économies bancaires",
          "investment": "Investissement",
          "liability": "Responsabilité",
          "legal": "Légal",
          "protection": "Protection",
          "public-transport": "Transport public",
          "tickets": "Billets",
          "bike": "Vélo",
          "e-bike": "Vélo électrique",
          "leasing": "Leasing",
          "fuel": "Carburant",
          "maintenance": "Entretien",
          "fees": "Frais",
          "amortisation": "Amortissement",
          "media-subscriptions": "Abonnements médias",
          "association": "Association",
          "contributions": "Contributions",
          "membership": "Adhésion",
          "school": "École",
          "money": "Argent",
          "continuous-education": "Formation continue",
          "education": "Éducation",
          "hobby": "Loisir",
          "child-care": "Garde d'enfants",
          "yield": "Rendement",
          "costs": "Coûts",
          "down-payments": "Acomptes",
          "amortization": "Amortissement",
          "alimony": "Pension alimentaire",
          "payments": "Paiements",
          "daily-expenses": "Dépenses quotidiennes",
          "food": "Nourriture",
          "groceries": "Courses",
          "drinks": "Boissons",
          "guests": "Invités",
          "alcoholic-drinks": "Boissons alcoolisées",
          "going-out": "Sortir",
          "party": "Fête",
          "bars": "Bars",
          "eyes": "Yeux",
          "check-up": "Contrôle",
          "doctor": "Médecin",
          "teeth": "Dents",
          "therapy": "Thérapie",
          "veterinarian": "Vétérinaire",
          "pet-food": "Nourriture pour animaux",
          "pet-care": "Soins des animaux",
          "presents": "Cadeaux",
          "camps": "Camps",
          "vacation": "Vacances",
          "mortgage": "Hypothèque",
          'irregular-transactions': "Transactions Irrégulières",
          'irregular-transactions-title': "Transactions Irrégulières",
          'no-data-irregular-transactions-title': "Aucune transaction irrégulière pour l'année sélectionnée.",
          'no-data-irregular-transactions-description': "Il n'y a pas de transactions irrégulières pour l'année sélectionnée. Les positions budgétaires irrégulières ne seront affichées que pour l'année en cours ou future. Veuillez réessayer avec une autre année ou créer vos premières positions budgétaires.",
          'overdue': "En retard",
          'needs-attention': "Nécessite une attention",
          'bill-paid': "Facture payée",
          'planned-month': "Mois prévu",
          'paid': "Payé",
      }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
