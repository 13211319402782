import React, {useState, useEffect} from 'react';
import {getBudgetedSumsAndRemaining} from "../../../services/remainingIncomeCalculator";
import i18n from "../../../translations/i18n";
import translations from "./BudgetDistribution.json";
import FormattedAmount from "../../logical-ui-elements/FormattedAmount";

// Add translations to i18n
Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'translation', translations[lang], true, true);
});

//Presents the user with an overview of the budget distribution
function BudgetDistribution({financialPositions}) {
    const [totalPlanned, setTotalPlanned] = useState(0);
    const [totalRemaining, setTotalRemaining] = useState(0);
    const [deficit, setDeficit] = useState(false);
    const [deficitAmount, setDeficitAmount] = useState(0);
    const [deficitMonthly, setDeficitMonthly] = useState(0);

    useEffect(() => {
        if(financialPositions.length > 0) {
            const distribution = getBudgetedSumsAndRemaining(financialPositions);
            setTotalPlanned(distribution.expense[0].percentageOfIncome[0].annual + distribution.savings[0].percentageOfIncome[0].annual || 0);
            setTotalRemaining(distribution.remaining[0].percentageOfIncome[0].annual || 0);
            setDeficit(distribution.remaining[0].annualIsDeficit || false);
            setDeficitAmount(distribution.remaining[0].remainingAnnualIncome || 0);
            setDeficitMonthly(distribution.remaining[0].remainingMonthlyIncome || 0);
        }
    }, [financialPositions]);

    return (
        <div>
            {deficit ? (
                <>
                    <div className="progress" role="progressbar" aria-label="Danger striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{width: "100%"}}></div>
                    </div>
                    {deficitAmount && (
                        <div className="text-center fst-italic small mt-2 text-danger">{i18n.t('over-allocation')}: <FormattedAmount amount={deficitAmount}/></div>
                    )}
                </>
            ):(
                <>
                <div
                    className="progress"
                    role="progressbar"
                    aria-label="Animated striped example"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100">
                        <div className="progress-bar bg-primary" style={{width: totalPlanned + "%"}}>
                            {totalPlanned.toFixed(2)}%
                        </div>

                        <div className="progress-bar bg-info-subtle" style={{width: totalRemaining + "%"}}>
                            {totalRemaining.toFixed(2)}%
                        </div>
                </div>
                    <div className="d-flex justify-content-between">
                        <div className="text-center fst-italic small mt-2">{i18n.t('planned')}</div>
                        <div className="text-center fst-italic small mt-2">{i18n.t('annually')}: <FormattedAmount amount={deficitAmount}/> <br/>{i18n.t('monthly')}: <FormattedAmount amount={deficitMonthly} /></div>
                        <div className="text-center fst-italic small mt-2">{i18n.t('remaining')}</div>
                    </div>
                </>
            )}
        </div>
    );
}

export default BudgetDistribution;